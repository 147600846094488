import React, { useState } from "react";
import "./Passenger.css";
import { Select } from "antd";
import { InputNumber } from "antd";
import Buttons from "../Buttons/Buttons";
import { TravelClass } from "../../types/enum/travelClass";

interface PassengerProps {
  flightClass: TravelClass;
  setFlightClass: React.Dispatch<React.SetStateAction<TravelClass>>;
  adultNumber: number;
  setAdultNumber: React.Dispatch<React.SetStateAction<number>>;
  childernNumber: number;
  setChildernNumber: React.Dispatch<React.SetStateAction<number>>;
  disabled?: boolean;
}

const Passenger: React.FC<PassengerProps> = ({
  flightClass,
  setFlightClass,
  adultNumber,
  setAdultNumber,
  childernNumber,
  setChildernNumber,
  disabled,
}) => {
  const map = [
    { value: TravelClass.ECONOMY, label: "Economy" },
    { value: TravelClass.PREMIUM_ECONOMY, label: "Premium Economy" },
    { value: TravelClass.BUSINESS, label: "Business Class" },
    { value: TravelClass.FIRST, label: "First Class" },
  ];

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleChangeClass = (value: TravelClass) => {
    setFlightClass(value);
  };

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  function getValueLabel(inputValue: TravelClass) {
    for (const item of map) {
      if (item.value === inputValue) {
        return item.label;
      }
    }
  }

  return (
    <>
      <div
        className="FlightBox"
        onClick={() => {
          if (!disabled) {
            toggleExpansion();
          }
        }}
        style={{
          cursor: disabled ? "not-allowed" : "pointer",
          opacity: disabled ? 0.8 : 1,
        }}
      >
        {" "}
        <div className="placeHolderFlightText">
          {adultNumber + childernNumber} {adultNumber + childernNumber > 1 ? "Travelers" : "Traveler"},
          {" " + getValueLabel(flightClass)}
        </div>
      </div>

      {isExpanded && (
        <div className="FlightDetailBox">
          <div className="FlightClassText">Cabin class</div>
          <Select
            defaultValue={flightClass}
            style={{
              width: 250,
              textAlign: "start",
              marginBottom: 20,
            }}
            onChange={(e) => {
              handleChangeClass(e);
            }}
            options={[
              { value: TravelClass.ECONOMY, label: "Economy" },
              { value: TravelClass.PREMIUM_ECONOMY, label: "Premium economy" },
              { value: TravelClass.BUSINESS, label: "Business Class" },
              { value: TravelClass.FIRST, label: "First Class" },
            ]}
          />

          <div>
            <div className="passengerTypeBox">
              <div className="passengerTypeText">
                Adults <div className="subPassengerTypeText">(Aged 16+)</div>
              </div>
              <div className="inputNumber">
                <InputNumber
                  min={1}
                  max={8}
                  defaultValue={adultNumber}
                  style={{ height: 38, fontSize: 16 }}
                  onChange={(e) => {
                    if (e != null) setAdultNumber(e);
                  }}
                />
              </div>
            </div>
            <div className="passengerTypeBox" style={{ marginTop: 20 }}>
              <div className="passengerTypeText">
                Children
                <div className="subPassengerTypeText">(Aged 0 to 15)</div>
              </div>

              <div className="inputNumber">
                <InputNumber
                  min={0}
                  max={9 - adultNumber}
                  defaultValue={childernNumber}
                  style={{ height: 38, fontSize: 16 }}
                  onChange={(e) => {
                    if (e != null) setChildernNumber(e);
                  }}
                />
              </div>
            </div>

            <div className="restrictionText">
              Airlines have restrictions on people
              <br /> under the age of 18 traveling alone.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Passenger;
