import React from "react";
import "./HotelBookingDetail.css";
import { HotelBookingDetailResponse, HotelOffer } from "../../types/hotel";

interface HotelBookingDetailProps {
  info: HotelBookingDetailResponse;
}

const HotelBookingDetail: React.FC<HotelBookingDetailProps> = ({ info }) => {
  const convertToReadableString = (str: string): string => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  return (
    <div className="HotelBooking-container">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="blacken">Requester Name :</div>
        {info.requesterName}
      </div>

      <div className="Hotelbooking-walletPanel">
        <div style={{ marginRight: "5px" }}> Payment Method :</div>
        {info.paymentMethod}
      </div>
      <div className="blacken">Status : {info.status}</div>
      <div className="HotelBooking-type">
        {" "}
        <div className="blacken">Type : </div> Hotel
      </div>
      <div className="HotelBooking-hotelName">
        <div className="blacken">Hotel Name :</div> {info.hotelName}
      </div>
      <div>Room Quantity : {info.currentOffer.room_quantity}</div>
      <div className="Hotelbooking-amenities">
        Amenities :
        {info.amenities.map((amenity, index) => (
          <div key={index} style={{ marginLeft: "5px" }}>
            {index > 0 && ","}
            {convertToReadableString(amenity)}
          </div>
        ))}
      </div>
      <div className="Hotelbooking-gird">
        <div className="Hotelbooking-requestOffer">
          <div className="blacken" style={{ marginBottom: "5px" }}>
            Request Offer :
          </div>
          <div>
            Check In Date : {info.requestOffer.check_in_date}{" "}
            {info.requestOffer.check_in_at}
          </div>
          <div>
            Check Out Date : {info.requestOffer.check_out_date}
            {"  "}
            {info.requestOffer.check_out_at}
          </div>
          <div>Bed amount : {info.requestOffer.beds}</div>
          <div>Bed type : {info.requestOffer.bed_type.toLocaleLowerCase()}</div>
          <div>Guest per room : {info.requestOffer.guest_per_room}</div>
          <div>
            Board type : {info.requestOffer.board_type.toLocaleLowerCase()}
          </div>
          <div>Payment type : {info.requestOffer.payment_type}</div>
          <div>
            {" "}
            Base price : {info.requestOffer.base_price}{" "}
            {info.requestOffer.currency}
          </div>
          <div className="blacken">
            {" "}
            Total price : {info.requestOffer.total_price}{" "}
            {info.requestOffer.currency}
          </div>
        </div>
        <div className="Hotelbooking-currentOffer">
          <div className="blacken" style={{ marginBottom: "5px" }}>
            Current Offer :
          </div>
          <div>
            Check In Date : {info.currentOffer.check_in_date}{" "}
            {info.currentOffer.check_in_at}
          </div>
          <div>
            Check Out Date : {info.currentOffer.check_out_date}
            {"  "}
            {info.currentOffer.check_out_at}
          </div>
          <div>Bed amount : {info.currentOffer.room_quantity}</div>
          <div>Bed type : {info.currentOffer.bed_type.toLocaleLowerCase()}</div>
          <div>Guest per room : {info.currentOffer.guest_per_room}</div>
          <div>
            Board type : {info.currentOffer.board_type.toLocaleLowerCase()}
          </div>
          <div>Payment type : {info.currentOffer.payment_type}</div>
          <div>
            {" "}
            Base price : {info.currentOffer.base_price}{" "}
            {info.currentOffer.currency}
          </div>
          <div className="blacken">
            {" "}
            Total price : {info.currentOffer.total_price}{" "}
            {info.currentOffer.currency}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelBookingDetail;
