import Dayjs from "dayjs";
import { FC } from "react";
import { FlightItinerary, FlightSegment, SearchFlightOffer } from "../../types/flight";
import ANALogo from "../../assets/airline/logo/ana_airline_logo.png";
import "./FlightDetail.css";
import { BiTimeFive } from "react-icons/bi";

interface FlightDetailProp {
  data: SearchFlightOffer;
}

interface FlightSegmentDetailProp {
  segment: FlightSegment;
  travelType: string;
}

const FlightSegmentDetail: FC<FlightSegmentDetailProp> = ({ segment, travelType }) => {
  return (
    <div className="flight-detail-segment">
      <div className="flight-detail-segment-schedule">
        <div className="circle"></div>
        <div>
          <p>{Dayjs(segment.departureAt).format("ddd DD MMM HH:mm A")}</p>
          <h5>{segment.departureIataCode}</h5>
        </div>
        <div>
          <p>{Dayjs(segment.arrivalAt).format("ddd DD MMM HH:mm A")}</p>
          <h5>{segment.arrivalIataCode}</h5>
        </div>
      </div>
      <div className="flight-detail-segment-airline">
        <img height={30} src={ANALogo} />
        <div>
          <p>{segment.airlineName}</p>
          <p>
            {segment.carrierCode}
            {segment.flightNumber} {travelType}
          </p>
          <p>
            Flight time {segment.durationHour}h {segment.durationMinute}m
          </p>
        </div>
      </div>
    </div>
  );
};

const FlightDetail: FC<FlightDetailProp> = ({ data }) => {
  return (
    <div className="flight-detail-container">
      <h3>Flight Details</h3>
      {data.flightItineraries.map((itinerary: FlightItinerary) => (
        <div className="flight-detail-itinerary">
          <h4>
            Flight from {itinerary.departureIataCode} to {itinerary.arrivalIataCode}
          </h4>
          <div className="flight-detail-itinerary-header-description">
            {itinerary.flightSegments.length == 1 && <p>No stop</p>}
            {itinerary.flightSegments.length > 1 && (
              <p>{itinerary.flightSegments.length - 1} stop</p>
            )}
            <p>
              {" "}
              ,{itinerary.durationHour} hour {itinerary.durationMinute} minutes
            </p>
          </div>
          <div className="flight-detail-segments">
            {itinerary.flightSegments.length > 1 &&
              itinerary.flightSegments.map(
                (segment: FlightSegment, idx: number, segments: FlightSegment[]) => {
                  if (idx == 0) {
                    return (
                      <FlightSegmentDetail
                        segment={itinerary.flightSegments[0]}
                        travelType={data.travelType}
                      />
                    );
                  } else {
                    const previousArrival = new Date(segments[idx - 1].arrivalAt);
                    const nextDeparture = new Date(segments[idx].departureAt);
                    const layoverTime = nextDeparture.getTime() - previousArrival.getTime();
                    const layoverHour = Math.floor(layoverTime / 3600000);
                    const layoverMinute = (layoverTime % 3600000) / 60000;
                    return (
                      <>
                        <div className="flight-segment-layover">
                          <BiTimeFive />
                          <p>
                            Layover {layoverHour}h {layoverMinute}m
                          </p>
                        </div>
                        <FlightSegmentDetail segment={segment} travelType={data.travelType} />
                      </>
                    );
                  }
                }
              )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FlightDetail;
