import React, { createContext, useContext, useEffect, useState } from "react";
import { SearchFlightOffer, SearchFlightRequest } from "../types/flight";
import { HotelOffer, SearchHotelRequest } from "../types/hotel";
import { TripGenPhase } from "../types/enum/tripgenPhase";
import dayjs from "dayjs";
import { mockSearchHotelResponse } from "../page/SearchHotel/SearchHotel";
import { mockResult } from "../page/SearchPage/SearchPage";
import { sampleSearchFlightRequest, sampleSearchHotelRequest } from "../utils/trip";

interface TripgenContextValue {
  // flight
  flightSearchRequests: SearchFlightRequest[];
  setFlightSearchRequests: React.Dispatch<React.SetStateAction<SearchFlightRequest[]>>;
  flightSearchResults: SearchFlightOffer[];
  setFlightSearchResults: React.Dispatch<React.SetStateAction<SearchFlightOffer[]>>;
  filteredFlights: SearchFlightOffer[];
  setFilteredFlights: React.Dispatch<React.SetStateAction<SearchFlightOffer[]>>;
  selectedBookingFlight: SearchFlightOffer[];
  setSelectedBookingFlight: React.Dispatch<React.SetStateAction<SearchFlightOffer[]>>;
  selectFlightIndex: number;
  setSelectFlightIndex: React.Dispatch<React.SetStateAction<number>>;
  isFilterFlightShow: boolean;
  setIsFilterFlightShow: React.Dispatch<React.SetStateAction<boolean>>;

  // hotel
  hotelSearchRequests: SearchHotelRequest[];
  setHotelSearchRequests: React.Dispatch<React.SetStateAction<SearchHotelRequest[]>>;
  hotelSearchResult: HotelOffer[];
  setHotelSearchResult: React.Dispatch<React.SetStateAction<HotelOffer[]>>;
  filteredHotels: HotelOffer[];
  setFilteredHotels: React.Dispatch<React.SetStateAction<HotelOffer[]>>;
  selectedBookingHotel: HotelOffer[];
  setSelectedBookingHotel: React.Dispatch<React.SetStateAction<HotelOffer[]>>;
  selectHotelIndex: number;
  setSelectHotelIndex: React.Dispatch<React.SetStateAction<number>>;
  isFilterHotelShow: boolean;
  setIsFilterHotelShow: React.Dispatch<React.SetStateAction<boolean>>;

  tripgenPhase: TripGenPhase;
  setTripgenPhase: React.Dispatch<React.SetStateAction<TripGenPhase>>;
  tripgenFlightStep: number;
  setTripgenFlightStep: React.Dispatch<React.SetStateAction<number>>;
  tripgenHotelStep: number;
  setTripgenHotelStep: React.Dispatch<React.SetStateAction<number>>;
  totalFlightStep: number;
  totalHotelStep: number;
  isDifferentCity: boolean;
  setIsDifferentCity: React.Dispatch<React.SetStateAction<boolean>>;
  infoFlightStep: number;
  setInfoFlightStep: React.Dispatch<React.SetStateAction<number>>;
  infoHotelStep: number;
  setInfoHotelStep: React.Dispatch<React.SetStateAction<number>>;
  arrowUp: boolean;
  setArrowUp: React.Dispatch<React.SetStateAction<boolean>>;
  nameTrip: string;
  setNameTrip: React.Dispatch<React.SetStateAction<string>>;
  selectedBookingFlightOffer: SearchFlightOffer[];
  setSelectedBookingFlightOffer: React.Dispatch<React.SetStateAction<SearchFlightOffer[]>>;
  selectedBookingHotelOffer: HotelOffer[];
  setSelectedBookingHotelOffer: React.Dispatch<React.SetStateAction<HotelOffer[]>>;

  // utility
  addFlightSearchRequest: () => void;
  removeFlightSearchRequest: (target: number) => void;
  updateFlightSearchRequest: (targetId: number, flightValue: SearchFlightRequest) => void;
  selectFlightItemHandler: (targetIndex: number) => void;
  handleSelectedBookingFlight: (selectedFlight: SearchFlightOffer) => void;
  addHotelSearchRequest: () => void;
  removeHotelSearchRequest: (target: number) => void;
  updateHotelSearchRequest: (targetId: number, hotelValue: SearchHotelRequest) => void;
  selectHotelItemHandler: (targetIndex: number) => void;
  tripGenPhaseHandler: (isForward: boolean) => void;
  isDifferentCityHandler: () => void;
  forwardStepHandler: () => void;
  handleSelectedBookingHotel: (selectedHotel: HotelOffer) => void;
  nextInfoStepHandler: () => void;
  backInfoStepHandler: () => void;
  backPaymentStepHandler: () => void;
  nextPaymentStepHandler: () => void;
  resetButtonHandler: () => void;
  handleArrowUp: () => void;
}

const TripgenContext = createContext<TripgenContextValue>({} as TripgenContextValue);

export function useTripgenContext() {
  return useContext(TripgenContext);
}

const TripgenProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // flight section
  const [flightSearchRequests, setFlightSearchRequests] = useState<SearchFlightRequest[]>([sampleSearchFlightRequest]);
  const [flightSearchResults, setFlightSearchResults] = useState<SearchFlightOffer[]>([]);
  const [filteredFlights, setFilteredFlights] = useState<SearchFlightOffer[]>([]);
  const [selectedBookingFlight, setSelectedBookingFlight] = useState<SearchFlightOffer[]>([]);
  const [selectFlightIndex, setSelectFlightIndex] = useState<number>(-1); // index of filterFlights to display flight detail
  const [isFilterFlightShow, setIsFilterFlightShow] = useState(true);
  const [selectedBookingFlightOffer, setSelectedBookingFlightOffer] = useState<SearchFlightOffer[]>([]);

  // hotel section
  const [hotelSearchRequests, setHotelSearchRequests] = useState<SearchHotelRequest[]>([sampleSearchHotelRequest]);
  const [hotelSearchResult, setHotelSearchResult] = useState<HotelOffer[]>([]);
  const [filteredHotels, setFilteredHotels] = useState<HotelOffer[]>([]);
  const [selectedBookingHotel, setSelectedBookingHotel] = useState<HotelOffer[]>([]);
  const [selectHotelIndex, setSelectHotelIndex] = useState<number>(-1); // index of filterHotels
  const [isFilterHotelShow, setIsFilterHotelShow] = useState(true);
  const [selectedBookingHotelOffer, setSelectedBookingHotelOffer] = useState<HotelOffer[]>([]);

  // trip section
  const [tripgenPhase, setTripgenPhase] = useState<TripGenPhase>(TripGenPhase.FIRST);
  const [tripgenFlightStep, setTripgenFlightStep] = useState<number>(0); // use as index of flightSearchRequests
  const [tripgenHotelStep, setTripgenHotelStep] = useState<number>(-1);
  const totalFlightStep = flightSearchRequests.length;
  const totalHotelStep = hotelSearchRequests.length;
  const [isDifferentCity, setIsDifferentCity] = useState<boolean>(false);

  const [infoFlightStep, setInfoFlightStep] = useState<number>(0);
  const [infoHotelStep, setInfoHotelStep] = useState<number>(0);
  // utility
  const [nameTrip, setNameTrip] = useState<string>("Default Trip Name");

  const addFlightSearchRequest = () => {
    setFlightSearchRequests((prev) => [...prev, sampleSearchFlightRequest]);
  };

  const removeFlightSearchRequest = (target: number) => {
    setFlightSearchRequests((prev) => prev.filter((_, idx: number) => idx != target));
  };

  const updateFlightSearchRequest = (targetId: number, flightValue: SearchFlightRequest) => {
    setFlightSearchRequests((prev) =>
      prev.map((flight: SearchFlightRequest, id: number) => {
        return targetId !== id ? flight : flightValue;
      })
    );

    // set hotel search request if the flight is the first flight and the city is the same
    if (tripgenPhase === TripGenPhase.FIRST && isDifferentCity === false && targetId === 0) {
      setHotelSearchRequests((prev) => [
        {
          cityCode: flightValue.destinationLocationCode,
          adult: flightValue.travellers[0].travellerAmount + (flightValue.travellers[1]?.travellerAmount || 0),
          checkInDate: flightValue.departureDate,
          checkOutDate: flightValue.isOneway
            ? dayjs(flightValue.departureDate).add(2, "day").format("YYYY-MM-DD")
            : flightValue.returnDate,
          roomQuantity: 1,
        },
      ]);
    }
  };

  const selectFlightItemHandler = (targetIndex: number) => {
    // setSelectFlightIndex((prevIndex) => {
    //   return prevIndex !== targetIndex ? targetIndex : -1;
    // });
    setSelectFlightIndex(targetIndex);
  };

  const handleSelectedBookingFlight = (selectedFlight: SearchFlightOffer) => {
    setSelectedBookingFlight((prev) => {
      return prev ? [...prev, selectedFlight] : [selectedFlight];
    });
  };

  const addHotelSearchRequest = () => {
    const currentDate = dayjs().add(1, "day");
    const tomorrowDate = dayjs().add(2, "day");
    const sampleHotelRequest: SearchHotelRequest = {
      cityCode: flightSearchRequests ? flightSearchRequests[0].destinationLocationCode : "",
      adult: 1,
      checkInDate: currentDate.format("YYYY-MM-DD"),
      checkOutDate: tomorrowDate.format("YYYY-MM-DD"),
      roomQuantity: 1,
    };

    setHotelSearchRequests((prev) => [...prev, sampleHotelRequest]);
  };

  const removeHotelSearchRequest = (target: number) => {
    setHotelSearchRequests((prev) => prev.filter((_, idx: number) => idx !== target));
  };

  const updateHotelSearchRequest = (targetId: number, hotelValue: SearchHotelRequest) => {
    setHotelSearchRequests((prev) =>
      prev.map((hotel: SearchHotelRequest, id: number) => {
        return targetId !== id ? hotel : hotelValue;
      })
    );
  };

  const selectHotelItemHandler = (targetIndex: number) => {
    setSelectHotelIndex(targetIndex);
  };

  const handleSelectedBookingHotel = (selectedHotel: HotelOffer) => {
    setSelectedBookingHotel((prev) => {
      return prev ? [...prev, selectedHotel] : [selectedHotel];
    });
  };
  const tripGenPhaseHandler = (isForward: boolean) => {
    if (isForward) {
      if (tripgenPhase === TripGenPhase.FIRST) {
        setTripgenPhase(TripGenPhase.FLIGHT_PHASE);
      } else if (tripgenPhase === TripGenPhase.FLIGHT_PHASE) {
        setTripgenPhase(TripGenPhase.HOTEL_PHASE);
      } else if (tripgenPhase === TripGenPhase.HOTEL_PHASE) {
        setTripgenPhase(TripGenPhase.SUMMARY_PHASE);
      } else if (tripgenPhase === TripGenPhase.SUMMARY_PHASE) {
        setTripgenPhase(TripGenPhase.INFO_PHASE);
      }
    } else {
      if (tripgenPhase === TripGenPhase.SUMMARY_PHASE) {
        setTripgenPhase(TripGenPhase.HOTEL_PHASE);
      } else if (tripgenPhase === TripGenPhase.HOTEL_PHASE) {
        setTripgenPhase(TripGenPhase.FLIGHT_PHASE);
      } else if (tripgenPhase === TripGenPhase.FLIGHT_PHASE) {
        setTripgenPhase(TripGenPhase.FIRST);
      }
    }
  };

  const forwardStepHandler = () => {
    if (tripgenFlightStep === totalFlightStep - 1 && tripgenHotelStep === totalHotelStep - 1) {
      setArrowUp(false);
      setTripgenPhase(TripGenPhase.SUMMARY_PHASE);
    } else if (tripgenFlightStep < totalFlightStep - 1) {
      setTripgenPhase(TripGenPhase.FLIGHT_PHASE);
      setTripgenFlightStep((prev) => prev + 1);
    } else if (tripgenHotelStep < totalHotelStep - 1) {
      setTripgenPhase(TripGenPhase.HOTEL_PHASE);
      setTripgenHotelStep((prev) => prev + 1);
    }
  };

  const nextInfoStepHandler = () => {
    if (infoFlightStep === selectedBookingFlight.length && infoHotelStep === selectedBookingHotel.length - 1) {
      setTripgenPhase(TripGenPhase.PAYMENT_PHASE);
      setInfoHotelStep((prev) => prev + 1);
    } else if (infoFlightStep <= selectedBookingFlight.length - 1) {
      setInfoFlightStep((prev) => prev + 1);
    } else if (infoHotelStep <= selectedBookingHotel.length - 1) {
      setInfoHotelStep((prev) => prev + 1);
    }
  };

  const backInfoStepHandler = () => {
    if (infoFlightStep === 0 && infoHotelStep === 0) {
      setTripgenPhase(TripGenPhase.SUMMARY_PHASE);
    } else if (infoFlightStep > 0 && infoHotelStep === 0) {
      setInfoFlightStep((prev) => prev - 1);
    } else if (infoHotelStep > 0) {
      setInfoHotelStep((prev) => prev - 1);
    }
  };

  const backPaymentStepHandler = () => {
    if (tripgenPhase === TripGenPhase.PAYMENT_PHASE) {
      setTripgenPhase(TripGenPhase.INFO_PHASE);
      setInfoHotelStep(selectedBookingHotel.length - 1);
    }

    if (tripgenPhase === TripGenPhase.CONFIRM_PHASE) {
      setTripgenPhase(TripGenPhase.PAYMENT_PHASE);
    }
  };

  const nextPaymentStepHandler = () => {
    if (tripgenPhase === TripGenPhase.PAYMENT_PHASE) {
      setTripgenPhase(TripGenPhase.CONFIRM_PHASE);
    }
  };

  const isDifferentCityHandler = () => {
    const relatedFlightHotelRequest: SearchHotelRequest = {
      cityCode: flightSearchRequests[0].destinationLocationCode,
      adult:
        flightSearchRequests[0].travellers[0].travellerAmount +
        (flightSearchRequests[0].travellers[1]?.travellerAmount || 0),
      checkInDate: flightSearchRequests[0].departureDate,
      checkOutDate: flightSearchRequests[0].isOneway
        ? dayjs(flightSearchRequests[0].departureDate).add(2, "day").format("YYYY-MM-DD")
        : flightSearchRequests[0].returnDate,
      roomQuantity: 1,
    };

    // from checked to unchecked
    if (isDifferentCity) {
      setHotelSearchRequests((prev) => {
        return [relatedFlightHotelRequest];
      });
    }

    setIsDifferentCity((prev) => !prev);
  };

  const [arrowUp, setArrowUp] = useState(true);
  const handleArrowUp = () => {
    setArrowUp(!arrowUp);
  };

  const resetButtonHandler = () => {
    setFlightSearchRequests([sampleSearchFlightRequest]);
    setHotelSearchRequests([sampleSearchHotelRequest]);
    setFlightSearchResults([]);
    setHotelSearchResult([]);
    setTripgenPhase(TripGenPhase.FIRST);
    setTripgenFlightStep(0);
    setTripgenHotelStep(-1);
    setInfoFlightStep(0);
    setInfoHotelStep(0);
    setArrowUp(true);
    setNameTrip("Default Trip Name");

    setSelectedBookingFlight([]);
    setSelectedBookingHotel([]);
    setSelectFlightIndex(-1);
    setSelectHotelIndex(-1);
  };

  const value: TripgenContextValue = {
    // state
    flightSearchRequests,
    setFlightSearchRequests,
    flightSearchResults,
    setFlightSearchResults,
    filteredFlights,
    setFilteredFlights,
    selectedBookingFlight,
    setSelectedBookingFlight,
    selectFlightIndex,
    setSelectFlightIndex,
    isFilterFlightShow,
    setIsFilterFlightShow,
    hotelSearchRequests,
    setHotelSearchRequests,
    hotelSearchResult,
    setHotelSearchResult,
    filteredHotels,
    setFilteredHotels,
    selectedBookingHotel,
    setSelectedBookingHotel,
    selectHotelIndex,
    setSelectHotelIndex,
    isFilterHotelShow,
    setIsFilterHotelShow,
    tripgenPhase,
    setTripgenPhase,
    tripgenFlightStep,
    setTripgenFlightStep,
    tripgenHotelStep,
    setTripgenHotelStep,
    totalFlightStep,
    totalHotelStep,
    isDifferentCity,
    setIsDifferentCity,
    infoFlightStep,
    setInfoFlightStep,
    infoHotelStep,
    setInfoHotelStep,
    arrowUp,
    setArrowUp,
    nameTrip,
    setNameTrip,
    selectedBookingFlightOffer,
    setSelectedBookingFlightOffer,
    selectedBookingHotelOffer,
    setSelectedBookingHotelOffer,

    // utility
    addFlightSearchRequest,
    removeFlightSearchRequest,
    updateFlightSearchRequest,
    selectFlightItemHandler,
    handleSelectedBookingFlight,
    addHotelSearchRequest,
    removeHotelSearchRequest,
    updateHotelSearchRequest,
    selectHotelItemHandler,
    tripGenPhaseHandler,
    isDifferentCityHandler,
    handleSelectedBookingHotel,
    forwardStepHandler,
    nextInfoStepHandler,
    backInfoStepHandler,
    backPaymentStepHandler,
    nextPaymentStepHandler,
    resetButtonHandler,
    handleArrowUp,
  };

  return <TripgenContext.Provider value={value}>{children}</TripgenContext.Provider>;
};

export default TripgenProvider;
