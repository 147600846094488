import "./TravellerInfo.css";
import ContactInfo from "./ContactInfo";
import PassengerInfo from "./PassengerInfo";
import { ContactPassenger, Passenger } from "../../types/flight";
import { FC } from "react";
import { TravellerType } from "../../types/enum/travellerType";
import { GrAddCircle } from "react-icons/gr";
import { useTripgenContext } from "../../context/TripgenContext";

interface TravellerInfoProp {
  passengers: Passenger[];
  contactInfo: ContactPassenger;
  addPassengerHandler: () => void;
  removePassengerHandler: (target: number) => void;
  updatePassengerHandler: (target: number, passengerKey: keyof Passenger, passengerValue: any) => void;
  updateContactInfoHandler: (contactInfoKey: keyof ContactPassenger, contactInfoValue: string) => void;
}

const TravellerInfo: FC<TravellerInfoProp> = ({
  passengers,
  contactInfo,
  addPassengerHandler,
  removePassengerHandler,
  updatePassengerHandler,
  updateContactInfoHandler,
}) => {
  return (
    <div className="traveller-info-container">
      <div className="traveller-info-passengers-section">
        {passengers.map((passenger: Passenger, id: number) => (
          <PassengerInfo
            passenger={passenger}
            id={id}
            removePassenger={removePassengerHandler}
            updatePassenger={updatePassengerHandler}
          />
        ))}

        <button className="traveller-info-add-passenger-btn" onClick={addPassengerHandler} type="button">
          Add more passengers
          <GrAddCircle style={{ color: "#2780a6", alignSelf: "center" }} />
        </button>
      </div>
      <div className="traveller-info-contact-section">
        <ContactInfo contactInfo={contactInfo} updateContact={updateContactInfoHandler} />
      </div>
    </div>
  );
};

export default TravellerInfo;
