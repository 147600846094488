import React from "react";
import { Slider } from "antd";
import "./TimeSlider.css";

interface TimeSliderProps {
  onStartChange: (startTime: string) => void;
  onEndChange: (endTime: string) => void;
}

interface TimeSliderState {
  sliderValue: [number, number];
}

class TimeSlider extends React.Component<TimeSliderProps, TimeSliderState> {
  state: TimeSliderState = {
    sliderValue: [
      this.timeToSliderValue("00:00"),
      this.timeToSliderValue("23:59"),
    ],
  };

  componentDidUpdate(prevProps: TimeSliderProps, prevState: TimeSliderState) {
    const { sliderValue } = this.state;
    const startTime = this.sliderValueToTime(sliderValue[0]);
    const endTime = this.sliderValueToTime(sliderValue[1]);

    if (startTime !== this.sliderValueToTime(prevState.sliderValue[0])) {
      this.props.onStartChange(startTime);
    }

    if (endTime !== this.sliderValueToTime(prevState.sliderValue[1])) {
      this.props.onEndChange(endTime);
    }
  }

  onSliderChange = (sliderValue: [number, number]) => {
    this.setState({ sliderValue });
  };

  sliderValueToTime(value: number): string {
    const minutesPerStep = 1439 / 100; // 1439 minutes from 00:00 to 23:59, 100 steps
    const minutes = Math.round(value * minutesPerStep);
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    // Format the time as HH:mm
    return `${hours.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}`;
  }

  timeToSliderValue(time: string): number {
    const [hour, minute] = time.split(":").map(Number);
    const totalMinutes = hour * 60 + minute;
    const minutesPerStep = 1439 / 100; // 1439 minutes from 00:00 to 23:59, 100 steps
    return totalMinutes / minutesPerStep;
  }

  render() {
    const { sliderValue } = this.state;

    return (
      <div>
        <Slider
          range
          min={0}
          max={100}
          step={1}
          value={sliderValue}
          onChange={this.onSliderChange}
          tooltip={{ formatter: null }}
          className="antSlider"
        />
      </div>
    );
  }
}

export default TimeSlider;
