import { TravelClass } from "../types/enum/travelClass";
import { TravellerType } from "../types/enum/travellerType";
import { SearchFlightRequest } from "../types/flight";
import dayjs from "dayjs";
import { SearchHotelRequest } from "../types/hotel";

export const sampleSearchFlightRequest: SearchFlightRequest = {
  originLocationCode: "",
  destinationLocationCode: "",
  departureDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
  returnDate: dayjs().add(2, "day").format("YYYY-MM-DD"),
  travellers: [
    {
      travellerType: TravellerType.ADULT,
      travellerAmount: 1,
    },
  ],
  cabinType: TravelClass.ECONOMY,
  isOneway: false,
  isFlexible: false,
};

export const sampleSearchHotelRequest: SearchHotelRequest = {
  cityCode: "",
  adult: 1,
  checkInDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
  checkOutDate: dayjs().add(2, "day").format("YYYY-MM-DD"),
  roomQuantity: 1,
};

export interface AddOfferToTripRequest {
  tripId: string;
  hotels: {
    offerID: string;
    guests: {
      title: string;
      firstName: string;
      lastName: string;
      phone: string;
      email: string;
    }[];
  }[];
}
