import "./FlightBookingPage.css";
import { useEffect, useState } from "react";
import TravellerInfo from "../../components/TravellerInfo/TravellerInfo";
import { TravellerType } from "../../types/enum/travellerType";
import { ContactPassenger, Passenger, SearchFlightOffer } from "../../types/flight";
import FlightInformationComponent from "../../components/FlightInformationComponent/FlightInformationComponent";
import { ConfirmInfoStep, PaymentInfoStep, TravellerInfoStep } from "../../utils/stepstatus";
import CurrentState from "../../components/CurrentState/CurrentState";
import ConfirmInfo from "../../components/ConfirmInfo/ConfirmInfo";
import PaymentForm, { CardInformation } from "../../components/PaymentForm/PaymentForm";
import { Button, Form } from "antd";
import { RequestableWallet } from "../../types/enum/wallet";
import { apiClient } from "../../utils/client";
import { message } from "antd";
import { useFlightContext } from "../../context/FlightContext";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import { TravelClass } from "../../types/enum/travelClass";
import { mockResult } from "../SearchPage/SearchPage";
import dayjs from "dayjs";
import ModalLoadingDone from "../../components/ModalLoading/ModalLoadingDone";
import { useNavigate } from "react-router-dom";
import { PaymentMethod, VendorCode } from "../../types/tripgen";

export const defaultPassenger: Passenger = {
  travellerType: TravellerType.ADULT,
  firstName: "",
  lastName: "",
  middleName: "",
  gender: "",
  birthDate: undefined,
  nationality: "",
  passportCountry: "",
  passportID: "",
  expiryDate: undefined,
};

export const defaultContactInfo: ContactPassenger = {
  firstName: "",
  surName: "",
  middleName: "",
  phoneNumber: {
    phoneNumber: "",
  },
  email: "",
};

export const defaultCardInfo: CardInformation = {
  cardNumber: "",
  cardHolderName: "",
  expiryDate: dayjs(),
  securityCode: "",
  vendorCode: "",
};

export const mockFlightInfo: SearchFlightOffer = {
  id: "1",
  totalPrice: 560.75,
  currency: "USD",
  travelType: "Economy",
  flightItineraries: [
    {
      durationHour: 10,
      durationMinute: 30,
      airlineName: "ANA",
      flightSegments: [
        {
          id: "1",
          carrierCode: "ANA",
          airlineName: "Nippon Airline",
          flightNumber: "800",
          aircraftCode: "122",
          departureIataCode: "BKK",
          departureAt: "2023-12-10T06:00:00",
          arrivalIataCode: "MNL",
          arrivalAt: "2023-12-10T16:30:00",
          durationHour: 10,
          durationMinute: 30,
          numberOfStops: 0,
          blacklistedInEU: false,
        },
        {
          id: "2",
          carrierCode: "ANA",
          airlineName: "Nippon Airline",
          flightNumber: "800",
          aircraftCode: "122",
          departureIataCode: "MNL",
          departureAt: "2023-12-10T20:00:00",
          arrivalIataCode: "SFO",
          arrivalAt: "2023-12-11T06:30:00",
          durationHour: 10,
          durationMinute: 30,
          numberOfStops: 0,
          blacklistedInEU: false,
        },
      ],
      departureIataCode: "BKK",
      departureDate: "2023-12-10",
      departureTime: "06:00",
      arrivalIataCode: "SFO",
      arrivalDate: "2023-12-10",
      arrivalTime: "16:30",
    },
    {
      durationHour: 10,
      durationMinute: 30,
      airlineName: "ANA",
      flightSegments: [
        {
          id: "1",
          carrierCode: "ANA",
          airlineName: "Nippon Airline",
          flightNumber: "800",
          aircraftCode: "122",
          departureIataCode: "BKK",
          departureAt: "2023-12-10T06:00:00",
          arrivalIataCode: "MNL",
          arrivalAt: "2023-12-10T16:30:00",
          durationHour: 10,
          durationMinute: 30,
          numberOfStops: 0,
          blacklistedInEU: false,
        },
        {
          id: "2",
          carrierCode: "ANA",
          airlineName: "Nippon Airline",
          flightNumber: "800",
          aircraftCode: "122",
          departureIataCode: "MNL",
          departureAt: "2023-12-10T20:00:00",
          arrivalIataCode: "SFO",
          arrivalAt: "2023-12-11T06:30:00",
          durationHour: 10,
          durationMinute: 30,
          numberOfStops: 0,
          blacklistedInEU: false,
        },
      ],
      departureIataCode: "BKK",
      departureDate: "2023-12-10",
      departureTime: "06:00",
      arrivalIataCode: "SFO",
      arrivalDate: "2023-12-10",
      arrivalTime: "16:30",
    },
  ],
  pricingOptions: {
    includedCheckedBagsOnly: true,
    refundableFare: false,
    noRestrictionFare: false,
    noPenaltyFare: false,
  },
  airlines: ["Nippon Airline"],
  layoverAirports: ["MNL"],
  departureDate: "2023-12-10",
  departureTime: "06:00",
  returnDate: "2023-12-12",
  returnTime: "09:00",
  validatingAirlineCodes: ["ANA"],
  travelerPricings: [],
};

const FlightBookingPage = () => {
  const { selectedFlightForBooking, numberOfPassenger } = useFlightContext();

  const [passengers, setPassengers] = useState<Passenger[]>([defaultPassenger]);
  const [contactInfo, setContactInfo] = useState<ContactPassenger>(defaultContactInfo);
  const [cardInformation, setCardInformation] = useState<CardInformation>(defaultCardInfo);
  const [step, setStep] = useState<number>(0);
  const [stepStatus, setStepStatus] = useState(TravellerInfoStep);
  const [availableWallet, setAvailableWallet] = useState<RequestableWallet[]>([]);
  const [selectedWallet, setSelectedWallet] = useState<string>("credit");
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isDoneLoading, setIsDoneLoading] = useState(false);
  const [flightOfferSearch, setFlightOfferSearch] = useState<SearchFlightOffer>();

  const navigate = useNavigate();

  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };

  const addPassengerHandler = () => {
    if (selectedFlightForBooking) {
      if (passengers.length < numberOfPassenger) {
        setPassengers((prev) => [...prev, defaultPassenger]);
      } else {
        message.error("Cannot add more passengers");
      }
    }
  };

  const removePassengerHandler = (target: number) => {
    setPassengers((prev) => prev.filter((_, idx: number) => idx != target));
  };

  const updatePassengerHandler = (targetId: number, passengerKey: keyof Passenger, passengerValue: any) => {
    setPassengers((prev) =>
      prev.map((passenger: Passenger, id: number) => {
        if (targetId != id) return passenger;
        else {
          return { ...passenger, [passengerKey]: passengerValue };
        }
      })
    );
  };

  const updateCardInfoHandler = (cardInfoKey: keyof CardInformation, cardInfoValue: any) => {
    setCardInformation((prev) => {
      return { ...prev, [cardInfoKey]: cardInfoValue };
    });
  };

  const updateContactInfoHandler = (contactInfoKey: keyof ContactPassenger, contactInfoValue: any) => {
    setContactInfo((prev) => {
      return { ...prev, [contactInfoKey]: contactInfoValue };
    });
  };

  const nextStepHandler = (value: any) => {
    setStepStatus((prev) => {
      switch (prev) {
        case TravellerInfoStep:
          return PaymentInfoStep;
        default:
          return ConfirmInfoStep;
      }
    });
    setStep((prev) => prev + 1);
  };

  const backStepHandler = () => {
    setStepStatus((prev) => {
      switch (prev) {
        case ConfirmInfoStep:
          return PaymentInfoStep;
        default:
          return TravellerInfoStep;
      }
    });
    setStep((prev) => prev - 1);
  };

  const getRequstableWallet = async () => {
    const response = await apiClient.getRequestableWallet();
    setAvailableWallet(response.data.wallets);
  };

  const handleSelectWallet = (wallet: string) => {
    setSelectedWallet(wallet);
  };

  const getFlightOffer = async () => {
    try {
      openModal();
      if (selectedFlightForBooking) {
        const response = await apiClient.getFlightOffer({
          itineraries: selectedFlightForBooking.flightItineraries.map((itinerary) => ({
            airlineName: itinerary.airlineName,
            segments: itinerary.flightSegments.map((segment) => ({
              airlineName: segment.airlineName,
              arrival: {
                at: segment.arrivalAt,
                iataCode: segment.arrivalIataCode,
              },
              carrierCode: segment.carrierCode,
              departure: {
                at: segment.departureAt,
                iataCode: segment.departureIataCode,
              },
              id: segment.id,
              number: segment.flightNumber,
            })),
          })),
          travelerPricings: [
            {
              fareDetailsBySegment: selectedFlightForBooking.travelerPricings[0].fareDetailsBySegment,
              fareOption: selectedFlightForBooking.travelerPricings[0].fareOption,
              travelerId: selectedFlightForBooking.travelerPricings[0].travelerId,
              travelerType: selectedFlightForBooking.travelerPricings[0].travelerType,
            },
          ],
          validatingAirlineCodes: selectedFlightForBooking.validatingAirlineCodes,
        });
        setFlightOfferSearch(response.data.data[0]);
      }
    } catch (error) {
      message.error("Failed to get flight offer");
    } finally {
      closeModal();
    }
  };

  const handleBookFlightClick = async () => {
    try {
      openModal();
      if (flightOfferSearch) {
        const response = await apiClient.bookFlight({
          itineraries: flightOfferSearch.flightItineraries.map((itinerary) => ({
            airlineName: itinerary.airlineName,
            segments: itinerary.flightSegments.map((segment) => ({
              airlineName: segment.airlineName,
              arrival: {
                at: segment.arrivalAt,
                iataCode: segment.arrivalIataCode,
              },
              carrierCode: segment.carrierCode,
              departure: {
                at: segment.departureAt,
                iataCode: segment.departureIataCode,
              },
              id: segment.id,
              number: segment.flightNumber,
            })),
          })),
          travelerPricings: [
            {
              fareDetailsBySegment: flightOfferSearch.travelerPricings[0].fareDetailsBySegment,
              fareOption: flightOfferSearch.travelerPricings[0].fareOption,
              travelerId: flightOfferSearch.travelerPricings[0].travelerId,
              travelerType: flightOfferSearch.travelerPricings[0].travelerType,
            },
          ],
          validatingAirlineCodes: flightOfferSearch.validatingAirlineCodes,
          passengers: passengers,
          contactPerson: {
            firstName: contactInfo.firstName,
            lastname: contactInfo.surName,
            middleName: contactInfo.middleName,
            phoneNumber: contactInfo.phoneNumber?.phoneNumber
              ? String(
                  "+" +
                    (contactInfo.phoneNumber?.countryCode ?? "") +
                    (contactInfo.phoneNumber?.areaCode ?? "") +
                    (contactInfo.phoneNumber?.phoneNumber ?? "")
                )
              : "",
            email: contactInfo.email,
          },
          payment: {
            method: selectedWallet === "credit" ? PaymentMethod.CREDIT_CARD : PaymentMethod.WALLET,
            creditCard:
              selectedWallet === "credit"
                ? {
                    vendorCode: cardInformation.vendorCode == "VI" ? VendorCode.VISA : VendorCode.MASTERCARD,
                    cardNumber: cardInformation.cardNumber,
                    cardHolder: cardInformation.cardHolderName,
                    expiryDate: cardInformation.expiryDate?.format("YYYY-MM").toString(),
                  }
                : undefined,
            walletId: selectedWallet === "wallet" ? selectedWallet : undefined,
          },
        });
      }
    } catch (err) {
      message.error("Error during booking. Please try again.");
    } finally {
      closeModal();
      setIsDoneLoading(true);
      setTimeout(() => {
        setIsDoneLoading(false);
        navigate("/");
      }, 3000);
    }
  };

  useEffect(() => {
    getRequstableWallet();
    getFlightOffer();
  }, []);

  return (
    <div className="flight-booking-page">
      <ModalLoading isOpen={isOpenModal} />
      <CurrentState stepStatus={stepStatus} infoOwner={"Traveller"} />
      <Form className="flight-booking-page-content" onFinish={nextStepHandler} layout="inline">
        <div className="flight-booking-page-left">
          {step == 0 && (
            <TravellerInfo
              passengers={passengers}
              contactInfo={contactInfo}
              addPassengerHandler={addPassengerHandler}
              removePassengerHandler={removePassengerHandler}
              updatePassengerHandler={updatePassengerHandler}
              updateContactInfoHandler={updateContactInfoHandler}
            />
          )}
          {step == 1 && (
            <div>
              <PaymentForm
                cardInfo={cardInformation}
                updateCardInfoHandler={updateCardInfoHandler}
                availableWallet={availableWallet}
                updatePaymentMethod={handleSelectWallet}
                PaymentMethod={selectedWallet}
              />
            </div>
          )}
          {step == 2 && (
            <div>
              <ConfirmInfo
                passengers={passengers}
                contactInfo={contactInfo}
                cardInfo={cardInformation}
                paymentMethod={selectedWallet}
                walletInfo={availableWallet.filter((wallet) => wallet.id == selectedWallet)[0]}
              />
            </div>
          )}
        </div>
        <div className="flight-booking-page-right">
          {flightOfferSearch ? (
            <FlightInformationComponent info={flightOfferSearch} />
          ) : (
            <ModalLoading isOpen={true}></ModalLoading>
          )}

          <div className="flight-booking-page-step-handler">
            {step > 0 && (
              <button className="flight-booking-page-btn back-btn" onClick={backStepHandler} type="button">
                Back
              </button>
            )}
            {step < 2 && (
              <Form.Item>
                <Button className="flight-booking-page-btn next-btn" htmlType="submit">
                  Next
                </Button>
              </Form.Item>
            )}
            {step == 2 && (
              <Form.Item>
                <ModalLoading isOpen={isOpenModal}></ModalLoading>
                <ModalLoadingDone
                  isOpen={isDoneLoading}
                  text={"Booking Flight Complete Successfully"}
                ></ModalLoadingDone>
                <Button className="flight-booking-page-btn next-btn" onClick={handleBookFlightClick}>
                  Next
                </Button>
              </Form.Item>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FlightBookingPage;
