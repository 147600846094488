import React, { useState, useEffect } from "react";
import "./TabBar.css";
import { FaPlane, FaUser, FaCalendarAlt, FaCaretDown } from "react-icons/fa";
import { BiSolidHotel, BiSolidCar, BiSolidBackpack } from "react-icons/bi";
import { useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { FaPersonCircleCheck } from "react-icons/fa6";
import { jwtDecode } from "jwt-decode";
import { message } from "antd";
import { checkTokenValid } from "../../utils/auth";
import { useUserContext } from "../../context/UserContext";
import { RxBackpack } from "react-icons/rx";
interface tabBarProps {}

const TabBar: React.FC<tabBarProps> = ({}) => {
  const { user, isLogin, setIsLogin } = useUserContext();
  const [cookie, setCookie, removeCookie] = useCookies(["accessToken"]);
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState("Flight");

  const [showSubmenu, setShowSubmenu] = useState(false);
  let submenuTimeout: NodeJS.Timeout;

  const handleTabClick = (tabName: string) => {
    const token = cookie.accessToken;
    if (token) {
      const isTokenValid = checkTokenValid(token);
      if (!isTokenValid) {
        setIsLogin(false);
        removeCookie("accessToken");
        message.error("Your token is expired. Please log in again.");
        if (tabName === "Admin") {
          setActiveTab("Flight");
          navigate("/");
          return;
        }
      }
    }
    if (tabName === "TripgenCalendar") {
      setActiveTab("Tripgen");
    } else {
      setActiveTab(tabName);
    }

    switch (tabName) {
      case "Flight":
        navigate("/");
        break;
      case "Hotel":
        navigate("/hotel");
        break;
      case "Car":
        navigate("/car");
        break;
      case "Admin":
        navigate("/admin");
        break;
      case "Calendar":
        navigate("/calendar");
        break;
      case "Tripgen":
        navigate("/tripgen");
        break;
      case "TripgenCalendar":
        navigate("/tripgen-calendar");
        break;
      default:
        break;
    }
  };

  const handleLoginClick = async () => {
    window.location.href = `${process.env.REACT_APP_API_URL}oauth/google_login`;
  };

  const handleLogoutClick = () => {
    removeCookie("accessToken");
    setIsLogin(false);
  };

  const handleMouseEnter = () => {
    if (submenuTimeout) {
      clearTimeout(submenuTimeout);
    }
    setShowSubmenu(true);
  };

  const handleMouseLeave = () => {
    submenuTimeout = setTimeout(() => {
      setShowSubmenu(false);
    }, 200);
  };

  useEffect(() => {
    if (location.pathname.includes("/flight")) {
      setActiveTab("Flight");
    } else if (location.pathname.includes("/hotel")) {
      setActiveTab("Hotel");
    } else if (location.pathname.includes("/car")) {
      setActiveTab("Car");
    } else if (location.pathname.includes("/admin")) {
      setActiveTab("Admin");
    } else if (location.pathname.includes("/calendar")) {
      setActiveTab("Calendar");
    } else if (location.pathname.includes("/tripgen")) {
      setActiveTab("Tripgen");
    } else if (location.pathname.includes("/tripgen-calendar")) {
      setActiveTab("Tripgen");
    } else {
      setActiveTab("Flight");
    }
  }, [location.pathname]);

  return (
    <>
      <div className="TabBar-all">
        <div className="LogoFastPlanner">Fast Planner</div>
        <div className="flight-hotel-car-tabbar">
          <div
            className={`TabBar-flight ${
              activeTab === "Flight" ? "active" : ""
            }`}
            onClick={() => {
              handleTabClick("Flight");
            }}
          >
            <FaPlane className="TabBar-icon" />
            Flight
          </div>
          <div
            className={`TabBar-hotel ${activeTab === "Hotel" ? "active" : ""}`}
            onClick={() => {
              handleTabClick("Hotel");
            }}
          >
            <BiSolidHotel className="TabBar-icon" />
            Hotel
          </div>
          <div
            className={`TabBar-car ${activeTab === "Car" ? "active" : ""}`}
            onClick={() => {
              handleTabClick("Car");
            }}
          >
            <BiSolidCar className="TabBar-icon" />
            Car
          </div>

          {isLogin && (
            <>
              {user.permissions && user.permissions.includes("admin") && (
                <div
                  className={`TabBar-admin ${
                    activeTab === "Admin" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleTabClick("Admin");
                  }}
                >
                  <FaPersonCircleCheck className="TabBar-icon" />
                  Admin
                </div>
              )}

              <div
                className={`TabBar-calendar ${
                  activeTab === "Calendar" ? "active" : ""
                }`}
                onClick={() => {
                  handleTabClick("Calendar");
                }}
              >
                <FaCalendarAlt className="TabBar-icon" />
                Calendar
              </div>

              <div
                className={`TabBar-tripgen ${
                  activeTab === "Tripgen" ? "active" : ""
                }`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div
                  className="TabBar-tripgen-main"
                  onClick={() => handleTabClick("Tripgen")}
                >
                  <BiSolidBackpack className="TabBar-icon" />
                  Trip
                </div>
                {showSubmenu && (
                  <div
                    className={`TabBar-tripgen-submenu ${
                      activeTab === "Tripgen" ? "active" : ""
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div
                      className="submenu-item"
                      onClick={() => handleTabClick("Tripgen")}
                    >
                      Create Trip
                    </div>
                    <div
                      className="submenu-item"
                      onClick={() => handleTabClick("TripgenCalendar")}
                    >
                      View Trip
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div
          className="TabBar-login"
          onClick={isLogin ? handleLogoutClick : handleLoginClick}
        >
          <FaUser className="TabBar-icon" />
          {isLogin ? "Logout" : "Login"}
        </div>
      </div>
    </>
  );
};

export default TabBar;
