import { FC } from "react";
import "./FlightItem.css";
import Dayjs from "dayjs";
import { MdFlight } from "react-icons/md";
import Button from "../Buttons/Buttons";
import ANALogo from "../../assets/airline/logo/ana_airline_logo.png";
import { BiSolidCircle } from "react-icons/bi";
import { FlightItinerary, FlightSegment, SearchFlightOffer } from "../../types/flight";

interface FlightItemProp {
  data: SearchFlightOffer;
  isSelect: boolean;
  onSelectHandler: () => void;
}

const FlightItem: FC<FlightItemProp> = ({ data, isSelect, onSelectHandler }) => {
  return (
    <div className={`flight-item-container ${isSelect ? "flight-item-selected" : ""}`}>
      {data && (
        <div className="flight-item-info-section">
          {data.flightItineraries &&
            data.flightItineraries.length != 0 &&
            data.flightItineraries.map((info: FlightItinerary) => (
              <div className="flight-item-info-item">
                <div className="flight-item-info-airline">
                  <img width={60} src={ANALogo} />
                  <p>{info.airlineName}</p>
                </div>
                <div className="flight-display-interface">
                  <div className="flight-item-time-airport" style={{ alignItems: "flex-end" }}>
                    <p className="flight-item-time">{info.departureTime}</p>
                    <p className="flight-item-airport">
                      {info.flightSegments[0].departureIataCode}, {Dayjs(info.departureDate).format("DD MMM")}
                    </p>
                  </div>
                  <div className="flight-item-duration-stop">
                    <p>
                      {info.durationHour} hour {info.durationMinute} minutes
                    </p>
                    <div className="flight-item-stop-line">
                      <hr
                        style={{
                          width: "100%",
                          margin: "0px",
                          border: "solid 0.5px black",
                        }}
                      />
                      {info.flightSegments &&
                        info.flightSegments.length > 1 &&
                        info.flightSegments.map((_: FlightSegment, idx: number) => (
                          <>
                            {idx < info.flightSegments.length - 1 && (
                              <>
                                <BiSolidCircle
                                  style={{
                                    color: "#e9a2a2",
                                    width: "16px",
                                    height: "16px",
                                  }}
                                />
                                <hr
                                  style={{
                                    width: "100%",
                                    margin: "0px",
                                    border: "solid 0.5px black",
                                  }}
                                />
                              </>
                            )}
                          </>
                        ))}
                    </div>
                    {info.flightSegments && info.flightSegments.length == 1 && <p style={{ color: "red" }}>no stop</p>}
                    {info.flightSegments && info.flightSegments.length > 1 && (
                      <div className="flight-item-stop-text">
                        <p style={{ color: "red" }}>{info.flightSegments.length - 1} stop</p>
                        {info.flightSegments &&
                          info.flightSegments.length > 0 &&
                          info.flightSegments.map((item: FlightSegment, index: number) => (
                            <>{index != info.flightSegments.length - 1 && <p>{item.arrivalIataCode}</p>}</>
                          ))}
                      </div>
                    )}
                  </div>
                  <MdFlight style={{ rotate: "90deg", width: "20px", height: "20px" }} />
                  <div className="flight-item-time-airport" style={{ alignItems: "flex-start" }}>
                    <p className="flight-item-time">{info.arrivalTime}</p>
                    <p className="flight-item-airport">
                      {info.flightSegments[info.flightSegments.length - 1].arrivalIataCode},{" "}
                      {Dayjs(info.arrivalDate).format("DD MMM")}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
      <div className="flight-item-price-section">
        <p style={{ fontSize: "16px", fontWeight: "bold" }}>
          $ {data.totalPrice} {data.currency}
        </p>
        <Button
          message="Select"
          fontSize="12px"
          bwidth="100px"
          bheight="30px"
          borderRadius="10px"
          bgColor="#222831"
          onClick={onSelectHandler}
        />
      </div>
    </div>
  );
};

export default FlightItem;
