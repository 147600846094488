import { useTripgenContext } from "../../context/TripgenContext";
import { TripGenPhase } from "../../types/enum/tripgenPhase";
import { SearchFlightOffer } from "../../types/flight";
import { HotelOffer } from "../../types/hotel";
import Button from "../Buttons/Buttons";
import HotelOfferItem from "../HotelOfferItem/HotelOfferItem";
import "./ConfirmModalComponent.css";

interface ConfirmModalComponentProp {
  flightOffer?: SearchFlightOffer;
  hotelOffer?: HotelOffer;
  onConfirm: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ConfirmModalComponent: React.FC<ConfirmModalComponentProp> = ({
  hotelOffer,
  flightOffer,
  onCancel,
  onConfirm,
}) => {
  const { tripgenPhase } = useTripgenContext();

  return (
    <div className="PopupConfirm-container">
      <div className="ConfirmModalComponent-subDisplay">
        {tripgenPhase === TripGenPhase.FLIGHT_PHASE && flightOffer ? (
          <>
            <div className="ConfirmModalComponent-title">Confirm Booking Details</div>
            <div className="FlightOffer-display">
              <div style={{ fontWeight: "bold", marginBottom: "10px" }}>Flight Detail</div>
              <div>
                {" "}
                From - to : {flightOffer.flightItineraries[0].flightSegments[0].departureIataCode} -{" "}
                {
                  flightOffer.flightItineraries[0].flightSegments[
                    flightOffer.flightItineraries[0].flightSegments.length - 1
                  ].arrivalIataCode
                }
              </div>
              <div>Flight type : {flightOffer.flightItineraries.length == 1 ? "One-way" : "Return"}</div>
              <div>
                Depature Date : {flightOffer.departureDate} @{flightOffer.departureTime}
              </div>
              {flightOffer.returnDate ? (
                <div>
                  Return Date : {flightOffer.returnDate} @{flightOffer.returnTime}
                </div>
              ) : null}
              <div>Airlines : {flightOffer.airlines.join(", ")}</div>
              <div>
                Price : {flightOffer.totalPrice} {flightOffer.currency}
              </div>
            </div>
          </>
        ) : null}

        {tripgenPhase === TripGenPhase.HOTEL_PHASE && hotelOffer ? (
          <>
            <div className="ConfirmModalComponent-title">Confirm Booking Details</div>
            <div className="FlightOffer-display">
              <div style={{ fontWeight: "bold", marginBottom: "10px" }}>Hotel Detail</div>
              <div>Hotel Name : {hotelOffer.hotelName}</div>
              <div>Check in Date : {hotelOffer.offers[0].checkInDate}</div>
              <div>Check out Date : {hotelOffer.offers[0].checkOutDate}</div>
              <div>
                Number of Room : {hotelOffer.offers[0].roomQuantity}{" "}
                {parseInt(hotelOffer.offers[0].roomQuantity) > 1 ? "rooms" : "room"}
              </div>

              <div>
                Price : {hotelOffer.offers[0].price.total} {hotelOffer.offers[0].price.currency}
              </div>
            </div>
          </>
        ) : null}

        <div className="ConfirmModalComponent-buttonPanel">
          <Button
            message={"Cancel"}
            onClick={(e) => {
              onCancel(e);
            }}
            bheight="50px"
            bwidth="150px"
            fontSize="22px"
            bgColor="#ff0000"
            borderRadius="10px"
          ></Button>
          <Button
            message={"Confirm"}
            onClick={(e) => {
              onConfirm(e);
            }}
            bheight="50px"
            bwidth="150px"
            fontSize="22px"
            borderRadius="10px"
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModalComponent;
