import { InputNumber, Radio, RadioChangeEvent, Slider } from "antd";
import "./FilterHotel.css";
import { useEffect, useState } from "react";
import { HotelOffer } from "../../types/hotel";
import { amentities } from "../../utils/amentities";
import { Checkbox } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";

interface FilterHotelProps {
  dataHotel: HotelOffer[] | undefined;
  setFilter: (filteredData: HotelOffer[]) => void;
}

const FilterHotel: React.FC<FilterHotelProps> = ({ dataHotel, setFilter }) => {
  //your budget
  const [yourBudget, setYourBudget] = useState<[number, number]>([0, 20000]);
  const onChangeYourBudget = (price: [number, number]) => {
    setYourBudget(price);
  };
  const handleMinYourBudgetInputChange = (value: number | null) => {
    if (value !== null) {
      const minValue = value;
      setYourBudget([minValue, yourBudget[1]]);
    }
  };

  const handleMaxYourBudgetInputChange = (value: number | null) => {
    if (value !== null) {
      const maxValue = value;
      setYourBudget([yourBudget[0], maxValue]);
    }
  };

  //other radio
  const [roomAmenities, setRoomAmenities] = useState();

  const [isPropertyTypeShowAll, setIsPropertyTypeShowAll] = useState(false);
  const [isPropertyFacilitiesShowAll, setIsPropertyFacilitiesShowAll] = useState(false);

  // -----------------propertyType-----------------
  const [propertyType, setPropertyType] = useState<CheckboxValueType[]>([
    "hotel",
    "resort",
    "apartment",
    "motel",
    "hostel",
    "homestay",
    "inn",
  ]);
  const onChangePropertyType = (checkedValues: CheckboxValueType[]) => {
    setPropertyType(checkedValues);
  };

  // -----------------distanceToCenter-----------------
  const [distanceToCenter, setDistanceToCenter] = useState<CheckboxValueType[]>([2, 5, 10, 999]);
  const onChangeDistanceToCenter = (checkedValues: CheckboxValueType[]) => {
    setDistanceToCenter(checkedValues);
  };

  // ----------------paymentOptions------------------
  const [paymentOptions, setPaymentOptions] = useState<CheckboxValueType[]>(["guarantee", "deposit", ""]);
  const onChangePaymentOptions = (checkedValues: CheckboxValueType[]) => {
    setPaymentOptions(checkedValues);
  };

  // ----------------bedType------------------
  const [bedType, setBedType] = useState<CheckboxValueType[]>(["SINGLE", "TWIN", "DOUBLE", "KING", "QUEEN"]);
  const onChangeBedType = (checkedValues: CheckboxValueType[]) => {
    setBedType(checkedValues);
  };

  const onChangeRoomAmenities = (e: RadioChangeEvent) => {
    setRoomAmenities(e.target.value);
  };

  const [isOpenModal, setIsOpenModal] = useState(false);
  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };

  const mapToUpperToLower = (inputString: string): string => {
    return inputString.replace(/_/g, " ").toLowerCase();
  };

  const mapToLowerToUpper = (inputString: string): string => {
    return inputString.replace(/ /g, "_").toUpperCase();
  };

  const [propertyFacilities, setPropertyFacilities] = useState<string[]>([]);
  const [seletedPropertyFacilities, setSeletedPropertyFacilities] = useState<string[]>([]);
  const visiblepropertyFacilities = isPropertyFacilitiesShowAll ? propertyFacilities : propertyFacilities.slice(0, 3);

  const getAllAmentitiesFromHotels = () => {
    if (dataHotel) {
      const uniqueAmentity = new Set(propertyFacilities);

      dataHotel.forEach((hotel) => {
        const amemtityList = hotel.amenities;

        if (Array.isArray(amemtityList)) {
          amemtityList.forEach((amenity) => {
            const amenityName = mapToUpperToLower(amenity);
            uniqueAmentity.add(amenityName);
          });
        }
      });
      const sortedAmentity = Array.from(uniqueAmentity).sort();

      setPropertyFacilities(sortedAmentity);
      setSeletedPropertyFacilities(sortedAmentity);
    }
  };
  const onChangePropertyFacilities = (amentity: string) => {
    if (seletedPropertyFacilities.includes(amentity)) {
      setSeletedPropertyFacilities(seletedPropertyFacilities.filter((a) => a !== amentity));
    } else {
      setSeletedPropertyFacilities([...seletedPropertyFacilities, amentity]);
    }
  };

  const filterHotel = () => {
    if (dataHotel) {
      openModal();
      let filtered = dataHotel;

      // filter by price
      if (yourBudget) {
        filtered = filtered.filter(
          (hotel) =>
            parseFloat(hotel.offers[0].price.total) >= yourBudget[0] &&
            parseFloat(hotel.offers[0].price.total) <= yourBudget[1]
        );
      }

      // filter by property type
      if (propertyType.length >= 0) {
        filtered = filtered.filter((hotel) => propertyType.some((type) => hotel.propertyType === String(type)));
      }

      // filter by distance
      if (distanceToCenter.length >= 0) {
        filtered = filtered.filter((hotel) =>
          distanceToCenter.some((distance) => hotel.distance.value < Number(distance))
        );
      }

      // filter by payment options
      if (paymentOptions.length >= 0) {
        filtered = filtered.filter((hotel) =>
          paymentOptions.some((payment) => hotel.offers[0].paymentPolicy.type === String(payment))
        );
      }

      // filter by bed type
      if (bedType.length >= 0) {
        filtered = filtered.filter((hotel) => bedType.some((bed) => hotel.offers[0].room.bedType === String(bed)));
      }

      // ---------------why I comment this code?---------------
      // filter by property facilities
      // if (seletedPropertyFacilities.length >= 0) {
      //   filtered = filtered.filter((hotel) =>
      //     seletedPropertyFacilities.some((amentities) =>
      //       hotel.amenities?.includes(mapToLowerToUpper(amentities))
      //     )
      //   );
      // }

      setFilter(filtered);
      closeModal();
    }
  };

  useEffect(() => {
    if (dataHotel) {
      getAllAmentitiesFromHotels();
    }
  }, [dataHotel]);

  useEffect(() => {
    filterHotel();
  }, [yourBudget, distanceToCenter, bedType, paymentOptions, seletedPropertyFacilities, propertyType]);

  return (
    <div className="filterHotel-container">
      <div className="FilterHotel-header">Filter</div>
      <div className="FilterHotel-yourBudget">
        <div className="FilterHotel-yourBudgetText">Your budget (per night)</div>
        <div className="FilterHotel-sliderYourBudget">
          <Slider
            range
            max={20000}
            min={0}
            step={10}
            defaultValue={[0, 20000]}
            onChange={onChangeYourBudget}
            value={yourBudget}
            tooltip={{ formatter: null }}
            className="FilterHotel-ant-slider"
          />
          <div className="FilterHotel-yourBudgetInputs">
            <InputNumber
              value={yourBudget[0]}
              onChange={handleMinYourBudgetInputChange}
              formatter={(value) => `฿ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            />
            <div className="FilterHotel-inputs-separator">to</div>
            <InputNumber
              value={yourBudget[1]}
              onChange={handleMaxYourBudgetInputChange}
              formatter={(value) => `฿ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            />
          </div>
        </div>
      </div>
      <div className="FilterHotel-propertyType">
        <div className="FilterHotel-propertyTypeText">Property type</div>
        <div className="FilterHotel-propertyTypeRadioButton">
          <Checkbox.Group
            onChange={onChangePropertyType}
            value={propertyType}
            style={{ display: "flex", flexDirection: "column" }}
            className="FilterHotel-ant-radio-group"
          >
            {(isPropertyTypeShowAll
              ? ["hotel", "resort", "apartment", "motel", "hostel", "homestay", "inn"]
              : ["hotel", "resort", "apartment"]
            ).map((value) => (
              <Checkbox key={value} value={value} className="FilterHotel-antdChoice">
                {value === "hotel" && "Hotel"}
                {value === "resort" && "Resort"}
                {value === "apartment" && "Apartment"}
                {value === "motel" && "Motel"}
                {value === "hostel" && "Hostel"}
                {value === "homestay" && "Homestay"}
                {value === "inn" && "Inn"}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </div>
        {isPropertyTypeShowAll ? (
          <button className="transparent-button2" onClick={() => setIsPropertyTypeShowAll(!isPropertyTypeShowAll)}>
            Shorten
          </button>
        ) : (
          <button className="transparent-button2" onClick={() => setIsPropertyTypeShowAll(!isPropertyTypeShowAll)}>
            Show All
          </button>
        )}
      </div>
      <div className="FilterHotel-distanceToCenter">
        <div className="FilterHotel-distanceToCenterText">Distance to center</div>
        <div className="FilterHotel-distanceToCenterRadioButton">
          <Checkbox.Group
            onChange={onChangeDistanceToCenter}
            value={distanceToCenter}
            style={{ display: "flex", flexDirection: "column" }}
            className="FilterHotel-ant-radio-group"
          >
            <Checkbox value={2} style={{ fontSize: "16px" }}>
              &lt;2 km to center
            </Checkbox>
            <Checkbox value={5} style={{ fontSize: "16px" }}>
              2-5 km to center
            </Checkbox>
            <Checkbox value={10} style={{ fontSize: "16px" }}>
              5-10 km to center
            </Checkbox>
            <Checkbox value={999} style={{ fontSize: "16px" }}>
              &gt;10 km to center
            </Checkbox>
          </Checkbox.Group>
        </div>
      </div>
      <div className="FilterHotel-paymentOptions">
        <div className="FilterHotel-paymentOptionsText">Payment options</div>
        <div className="FilterHotel-paymentOptionsRadioButton">
          <Checkbox.Group
            onChange={onChangePaymentOptions}
            value={paymentOptions}
            style={{ display: "flex", flexDirection: "column" }}
            className="FilterHotel-ant-radio-group"
          >
            <Checkbox value={"guarantee"} style={{ fontSize: "16px" }}>
              {" "}
              Pay at check out
            </Checkbox>
            <Checkbox value={"deposit"} style={{ fontSize: "16px" }}>
              {" "}
              Pay as deposit
            </Checkbox>
            <Checkbox value={""} style={{ fontSize: "16px" }}>
              Pay now
            </Checkbox>
          </Checkbox.Group>
        </div>
      </div>
      <div className="FilterHotel-bedType">
        <div className="FilterHotel-bedTypeText">Bed type</div>
        <div className="FilterHotel-bedTypeRadioButton">
          <Checkbox.Group
            onChange={onChangeBedType}
            value={bedType}
            style={{ display: "flex", flexDirection: "column" }}
            className="FilterHotel-ant-radio-group"
          >
            <Checkbox value={"SINGLE"} style={{ fontSize: "16px" }}>
              Single
            </Checkbox>
            <Checkbox value={"TWIN"} style={{ fontSize: "16px" }}>
              Twin
            </Checkbox>
            <Checkbox value={"DOUBLE"} style={{ fontSize: "16px" }}>
              Double
            </Checkbox>
            <Checkbox value={"KING"} style={{ fontSize: "16px" }}>
              King
            </Checkbox>
            <Checkbox value={"QUEEN"} style={{ fontSize: "16px" }}>
              Queen
            </Checkbox>
          </Checkbox.Group>
        </div>
      </div>
      {/* <div className="FilterHotel-roomAmenities">
        <div className="FilterHotel-roomAmenitiesText">Room amenities</div>
        <div className="FilterHotel-roomAmenitiesRadioButton">
          <Radio.Group
            onChange={onChangeRoomAmenities}
            value={roomAmenities}
            style={{ display: "flex", flexDirection: "column" }}
            className="FilterHotel-ant-radio-group"
          >
            {(isRoomAmenitiesShowAll
              ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
              : [1, 2, 3]
            ).map((value) => (
              <Radio key={value} value={value}>
                {value === 1 && "Air Conditioning"}
                {value === 2 && "TV"}
                {value === 3 && "Refrigerator"}
                {value === 4 && "Balcony/Terrace"}
                {value === 5 && "Coffee/Tea maker"}
                {value === 6 && "Internet Access"}
                {value === 7 && "Washing Machine"}
                {value === 8 && "Bathtub"}
                {value === 9 && "Ironing"}
                {value === 10 && "Private pool"}
                {value === 11 && "Heating"}
                {value === 12 && "Pet allowing in room"}
              </Radio>
            ))}
          </Radio.Group>
        </div>
        {isRoomAmenitiesShowAll ? (
          <button
            className="transparent-button2"
            onClick={() => setIsRoomAmenitiesShowAll(!isRoomAmenitiesShowAll)}
          >
            Shorten
          </button>
        ) : (
          <button
            className="transparent-button2"
            onClick={() => setIsRoomAmenitiesShowAll(!isRoomAmenitiesShowAll)}
          >
            Show All
          </button>
        )}
      </div> */}
      <div className="FilterHotel-propertyFacilities">
        <div className="FilterHotel-propertyFacilitiesText">Property facilities</div>
        <div className="FilterHotel-propertyFacilitiesRadioButton">
          {visiblepropertyFacilities.map((amentity) => (
            <label
              key={amentity}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <input
                type="checkbox"
                value={amentity}
                checked={seletedPropertyFacilities.includes(amentity)}
                onChange={() => {
                  onChangePropertyFacilities(amentity);
                }}
              />
              {amentity}
            </label>
          ))}
        </div>
        {isPropertyFacilitiesShowAll ? (
          <button
            className="transparent-button2"
            onClick={() => setIsPropertyFacilitiesShowAll(!isPropertyFacilitiesShowAll)}
          >
            Shorten
          </button>
        ) : (
          <button
            className="transparent-button2"
            onClick={() => setIsPropertyFacilitiesShowAll(!isPropertyFacilitiesShowAll)}
          >
            Show All
          </button>
        )}
      </div>
      <div className="last-gap"></div>
    </div>
  );
};

export default FilterHotel;
