import React from "react";
import { AutoComplete } from "antd";
import airportOptions from "../../utils/airportOptions";
import "./LocationInput.css";

interface LocationInputProps {
  originLocation: string;
  onInputChange: (value: string) => void;
  placeholder: string;
  disabled?: boolean;
}

const LocationInput: React.FC<LocationInputProps> = ({ originLocation, onInputChange, placeholder, disabled }) => {
  const handleInputChange = (value: string) => {
    onInputChange(value);
  };

  return (
    <div>
      <AutoComplete
        className="location-picker-component"
        placeholder={placeholder}
        value={originLocation}
        onChange={handleInputChange}
        options={airportOptions.map(([city, code]) => ({
          label: `${city} (${code})`,
          value: `${city} (${code})`,
        }))}
        filterOption={(inputValue, option) => option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
        disabled={disabled}
      />
    </div>
  );
};

export default LocationInput;
