import { FC } from "react";
import { Guest, HotelOffer } from "../../types/hotel";
import IndivGuestInfo from "./IndivGuestInfo";
import { GrAddCircle } from "react-icons/gr";
import "./GuestInfo.css";

interface GuestInfoProp {
  guests: Guest[];
  addGuestHandler: () => void;
  removeGuestHandler: (target: number) => void;
  updateGuestHandler: (
    target: number,
    guestKey: keyof Guest,
    guestValue: any
  ) => void;
}

const GuestInfo: FC<GuestInfoProp> = ({
  guests,
  addGuestHandler,
  removeGuestHandler,
  updateGuestHandler,
}) => {
  return (
    <div className="traveller-info-container">
      <div className="traveller-info-guests-section">
        {guests.map((guest: Guest, id: number) => (
          <IndivGuestInfo
            guest={guest}
            id={id}
            removeGuest={removeGuestHandler}
            updateGuest={updateGuestHandler}
          />
        ))}

        <button
          className="traveller-info-add-guest-btn"
          onClick={addGuestHandler}
          type="button"
        >
          Add more guests
          <GrAddCircle style={{ color: "#2780a6", alignSelf: "center" }} />
        </button>
      </div>
    </div>
  );
};

export default GuestInfo;
