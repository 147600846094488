import React from "react";
import { WalletOutlined, SmileOutlined, UserOutlined } from "@ant-design/icons";
import { Steps } from "antd";
import "./CurrentState.css";
import { StepStatus } from "../../utils/stepstatus";

interface CurrentStateProps {
  stepStatus: { [key: string]: StepStatus };
  infoOwner: string;
}

const CurrentState: React.FC<CurrentStateProps> = ({
  stepStatus,
  infoOwner,
}) => (
  <div className="steps-container">
    <Steps
      size="small"
      items={[
        {
          title: `${infoOwner} Information`,
          status: stepStatus.travellerInformation,
          icon: <UserOutlined />,
        },
        {
          title: "Payment Information",
          status: stepStatus.paymentInformation,
          icon: <WalletOutlined />,
        },
        {
          title: "Confirmation",
          status: stepStatus.confirmation,
          icon: <SmileOutlined />,
        },
      ]}
    />
  </div>
);

export default CurrentState;
