import { Form, Input } from "antd";
import PhoneInput from "antd-phone-input";
import { FC } from "react";
import { ContactPassenger } from "../../types/flight";
import "./ContactInfo.css";

interface ContactInfoProp {
  contactInfo: ContactPassenger;
  updateContact: (contactInfoKey: keyof ContactPassenger, contactInfoValue: any) => void;
}

const ContactInfo: FC<ContactInfoProp> = ({ contactInfo, updateContact }) => {
  return (
    <div className="contact-info-container">
      <h4 style={{ margin: "0px" }}>Contact Info</h4>
      <div className="contact-info-data">
        <Form.Item
          label="Firstname"
          name="contact-firstname"
          rules={[{ required: true, message: "Please input firstname" }]}
          initialValue={contactInfo.firstName}
        >
          <Input className="contact-info-data-item" onChange={(e) => updateContact("firstName", e.target.value)} />
        </Form.Item>
        <Form.Item
          label="Surname"
          name="contact-surname"
          rules={[{ required: true, message: "Please input surname" }]}
          initialValue={contactInfo.surName}
        >
          <Input className="contact-info-data-item" onChange={(e) => updateContact("surName", e.target.value)} />
        </Form.Item>
        <Form.Item label="Middlename" name="contact-middlename" initialValue={contactInfo.middleName}>
          <Input className="contact-info-data-item" onChange={(e) => updateContact("middleName", e.target.value)} />
        </Form.Item>
        <Form.Item
          label="PhoneNumber"
          name="contact-phone-number"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, { valid }) {
                if (valid()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The input is invalid phone number!"));
              },
            }),
          ]}
          initialValue={contactInfo.phoneNumber}
        >
          <PhoneInput enableSearch onChange={(e) => updateContact("phoneNumber", e)} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="contact-email"
          rules={[
            { required: true, message: "Please input email" },
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
          initialValue={contactInfo.email}
        >
          <Input className="contact-info-data-item" onChange={(e) => updateContact("email", e.target.value)} />
        </Form.Item>
      </div>
    </div>
  );
};

export default ContactInfo;
