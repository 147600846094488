import { useTripgenContext } from "../../../context/TripgenContext";
import { SearchFlightOffer } from "../../../types/flight";
import airportOptions from "../../../utils/airportOptions";
import "./TripgenFlightInfo.css";
import React from "react";

interface TripgenFlightInfoProps {
  flight: SearchFlightOffer;
  index: number;
}

const TripgenFlightInfo: React.FC<TripgenFlightInfoProps> = ({ flight, index }) => {
  const getAirportName = (code: string) => {
    for (const [name, airportCode] of airportOptions) {
      if (airportCode === code) {
        return name;
      }
    }
  };

  const { flightSearchRequests } = useTripgenContext();
  return (
    <div className="confirm-container" style={{ height: "fit-content", paddingTop: "10px" }}>
      <div className="flightInfo-container">
        <div className="flightInfo-first-grid">
          <div>
            {" "}
            <strong>From - To :</strong> {flight.flightItineraries[0].flightSegments[0].departureIataCode} -{" "}
            {
              flight.flightItineraries[0].flightSegments[flight.flightItineraries[0].flightSegments.length - 1]
                .arrivalIataCode
            }
          </div>

          <div>
            {" "}
            <strong>Airlines : </strong>
            {flight.airlines.join(", ")}
          </div>
        </div>
        <div className="flightInfo-second-grid">
          <div>
            {" "}
            <strong>Flight type : </strong>
            {flight.flightItineraries.length == 1 ? "One-way" : "Return"}
          </div>
          <div>
            <strong>Depature Date :</strong> {flight.departureDate}
          </div>
          {flight.returnDate ? (
            <div>
              <strong>Return Date : </strong>
              {flight.returnDate}
            </div>
          ) : null}
        </div>

        <div className="flightInfo-third-grid">
          <div>
            {" "}
            <strong>Passengers :</strong>{" "}
            {flightSearchRequests[index].travellers[0].travellerAmount +
              (flightSearchRequests[index].travellers[1]
                ? flightSearchRequests[index].travellers[1].travellerAmount
                : 0)}
          </div>
          <div>
            <strong>Price :</strong> {flight.totalPrice} {flight.currency}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripgenFlightInfo;
