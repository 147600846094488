import "./TripgenSearchBar.css";
import { useEffect, useState } from "react";
import ModalLoading from "../ModalLoading/ModalLoading";
import { TravelClass } from "../../types/enum/travelClass";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import Button from "../Buttons/Buttons";
import { CiCirclePlus } from "react-icons/ci";
import { SearchFlightOffer, SearchFlightRequest, SearchFlightResponse } from "../../types/flight";
import { TravellerType } from "../../types/enum/travellerType";
import TripgenFlightSearch from "./TripgenFlightSearch/TripgenFlightSearch";
import { HotelOffer, SearchHotelRequest, SearchHotelResponse } from "../../types/hotel";
import TripgenHotelSearch from "./TripgenHotelSearch/TripgenHotelSearch";
import { MdKeyboardDoubleArrowUp, MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import airportOptions from "../../utils/airportOptions";
import dayjs from "dayjs";
import { TripGenPhase } from "../../types/enum/tripgenPhase";
import { message } from "antd";
import { apiClient } from "../../utils/client";
import { useTripgenContext } from "../../context/TripgenContext";

interface TripgenSearchBarProp {
  tripgenPhase: TripGenPhase;
  flightSearchRequests: SearchFlightRequest[];
  hotelSearchRequests: SearchHotelRequest[];
  searchFlightForTripGen: (currentFlight: number) => void;
  searchHotelForTripGen: (currentHotel: number) => void;
}

const TripgenSearchBar: React.FC<TripgenSearchBarProp> = ({
  tripgenPhase,
  flightSearchRequests,
  hotelSearchRequests,
  searchFlightForTripGen,
  searchHotelForTripGen,
}) => {
  // ***************flight section****************
  const {
    setFlightSearchResults,
    setFilteredFlights,
    setHotelSearchResult,
    setFilteredHotels,
    addFlightSearchRequest,
    addHotelSearchRequest,
    isDifferentCity,
    tripGenPhaseHandler,
    selectedBookingFlight,
    totalFlightStep,
    selectedBookingHotel,
    arrowUp,
    setArrowUp,
    handleArrowUp,
  } = useTripgenContext();

  const airportMap = Object.fromEntries(airportOptions);
  const getAirportName = (code: string) => {
    for (const [name, airportCode] of airportOptions) {
      if (airportCode === code) {
        return name;
      }
    }
  };

  // input validator for flight and hotel search
  const isSearchDisabledHandler =
    flightSearchRequests.some((flight) => {
      return (
        flight.originLocationCode === "" ||
        flight.destinationLocationCode === "" ||
        flight.departureDate === "" ||
        flight.travellers.length === 0 ||
        (flight.isOneway === false && flight.returnDate === "")
      );
    }) ||
    hotelSearchRequests.some((hotel) => {
      return hotel.cityCode === "" || hotel.checkInDate === "" || hotel.checkOutDate === "";
    });

  useEffect(() => {}, [tripgenPhase]);

  return (
    <div className="TripgenSearchBar-container">
      <div className="TripgenSearchBar-title">
        {" "}
        <div style={{ position: "absolute" }}>Plan your trip</div>
      </div>
      {arrowUp ? (
        <>
          {/* ********************flight****************** */}
          {flightSearchRequests.map((flight: SearchFlightRequest, id_idx: number) => (
            <TripgenFlightSearch flightSearchRequest={flight} id_idx={id_idx}></TripgenFlightSearch>
          ))}

          {tripgenPhase !== TripGenPhase.FIRST &&
            (tripgenPhase === TripGenPhase.FLIGHT_PHASE || tripgenPhase === TripGenPhase.HOTEL_PHASE) && (
              <div className="TripgenSearchBar-addMoreButton">
                <div
                  className="TripgenSearchBar-addMoreButton"
                  onClick={() => {
                    addFlightSearchRequest();
                  }}
                  style={{ width: "fitContent", cursor: "pointer" }}
                >
                  Add more flight
                  <CiCirclePlus
                    style={{
                      color: "#ffffff",
                      alignSelf: "center",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </div>
              </div>
            )}

          {/* ********************hotel****************** */}
          {isDifferentCity || tripgenPhase !== TripGenPhase.FIRST ? (
            <div className="TripgenSearchBar-differentHotelContainer">
              {hotelSearchRequests.map((hotel: SearchHotelRequest, id_idx: number) => (
                <TripgenHotelSearch hotelSearchRequest={hotel} id_idx={id_idx}></TripgenHotelSearch>
              ))}

              {tripgenPhase !== TripGenPhase.FIRST &&
                (tripgenPhase === TripGenPhase.FLIGHT_PHASE || tripgenPhase === TripGenPhase.HOTEL_PHASE) && (
                  <div
                    className="TripgenSearchBar-addMoreButton"
                    onClick={() => {
                      addHotelSearchRequest();
                    }}
                    style={{
                      marginTop: "20px",
                      width: "fitContent",
                      cursor: "pointer",
                    }}
                  >
                    Add more hotel
                    <CiCirclePlus
                      style={{
                        color: "#ffffff",
                        alignSelf: "center",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </div>
                )}
            </div>
          ) : null}

          <div className="TripgenSearchBar-searchPanel">
            {tripgenPhase !== TripGenPhase.CONFIRM_PHASE &&
              tripgenPhase !== TripGenPhase.SUMMARY_PHASE &&
              tripgenPhase !== TripGenPhase.PAYMENT_PHASE && (
                <div className="TripgenSearchBar-searchButton">
                  <Button
                    message={"Search Trip"}
                    bwidth="195px"
                    bheight="50px"
                    borderRadius="10px"
                    fontSize="16px"
                    disabled={isSearchDisabledHandler}
                    onClick={() => {
                      setArrowUp(false);

                      if (tripgenPhase === TripGenPhase.FIRST) {
                        // search flight
                        searchFlightForTripGen(0);
                        // handle change to second phase
                        tripGenPhaseHandler(true);
                      }

                      if (tripgenPhase === TripGenPhase.FLIGHT_PHASE) {
                        // search flight from current flight search request from index 1 to last
                        searchFlightForTripGen(selectedBookingFlight.length);
                      }

                      if (tripgenPhase === TripGenPhase.HOTEL_PHASE) {
                        searchHotelForTripGen(selectedBookingHotel.length);
                      }
                    }}
                  ></Button>
                </div>
              )}

            {tripgenPhase !== TripGenPhase.FIRST && (
              <div>
                <div className="TripgenSearchBar-arrowUpButton">
                  <MdKeyboardDoubleArrowUp
                    style={{
                      color: "#ffffff",
                      width: "50px",
                      height: "50px",
                    }}
                    onClick={handleArrowUp}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="TripgenSearchBar-shortenTabbar">
          <div>
            {" "}
            From : {getAirportName(flightSearchRequests[0].originLocationCode)} (
            {flightSearchRequests[0].originLocationCode})
          </div>
          <div>
            {" "}
            To : {getAirportName(flightSearchRequests[0].destinationLocationCode)} (
            {flightSearchRequests[0].destinationLocationCode})
          </div>

          <div> Depart : {flightSearchRequests[0].departureDate}</div>
          {!flightSearchRequests[0].isOneway && <div> Return : {flightSearchRequests[0].returnDate}</div>}
          <div>
            {" "}
            Passengers : {flightSearchRequests[0].travellers[0].travellerAmount}{" "}
            {flightSearchRequests[0].travellers[0].travellerType}
            {flightSearchRequests[0].travellers[1] && (
              <>
                , {flightSearchRequests[0].travellers[1].travellerAmount}{" "}
                {flightSearchRequests[0].travellers[1].travellerType}
              </>
            )}
          </div>
          <div> Cabin Class : {flightSearchRequests[0].cabinType}</div>

          <div className="TripgenSearchBar-arrowDownButton">
            <MdOutlineKeyboardDoubleArrowDown
              style={{
                color: "#ffffff",
                width: "50px",
                height: "50px",
              }}
              onClick={handleArrowUp}
            />
          </div>
        </div>
      )}

      <div style={{ display: "flex" }}></div>
    </div>
  );
};

export default TripgenSearchBar;
