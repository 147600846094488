export enum StepStatus {
  Wait = "wait",
  Process = "process",
  Finish = "finish",
}

export const TravellerInfoStep = {
  travellerInformation: StepStatus.Process,
  paymentInformation: StepStatus.Wait,
  confirmation: StepStatus.Wait,
};

export const PaymentInfoStep = {
  travellerInformation: StepStatus.Finish,
  paymentInformation: StepStatus.Process,
  confirmation: StepStatus.Wait,
};

export const ConfirmInfoStep = {
  travellerInformation: StepStatus.Finish,
  paymentInformation: StepStatus.Finish,
  confirmation: StepStatus.Process,
};
