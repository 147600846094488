import axios from "axios";
import {
  FlightBookingRequest,
  FlightOfferRequest,
  SearchFlightRequest,
} from "../types/flight";
import {
  ApproveHotelBookingRequest,
  BookHotelRequest,
  FetchRequest,
  HotelBookingDetailRequest,
  HotelBookingRequestWithWallet,
  RejectHotelBookingRequest,
  SearchHotelRequest,
} from "../types/hotel";
import { useCookies } from "react-cookie";
import { GetBookingDetailRequest } from "../types/calendar";
import { BookTripgenRequest } from "../types/tripgen";
import { AddOfferToTripRequest } from "./trip";

const getAccessToken = () => {
  const cookies = document.cookie.split("; ");

  for (const cookie of cookies) {
    const [cookieName, ...cookieValues] = cookie.split("=");
    const cookieValue = cookieValues.join("="); // In case the value contains '='

    if (cookieName.trim() === "accessToken") {
      return cookieValue;
    }
  }

  return null; // Return null if the "accessToken" cookie is not found
};

const accessToken = getAccessToken();

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

const searchFlights = async (body: SearchFlightRequest) => {
  return await client.post("/flight/search", body);
};

const searchHotels = async (body: SearchHotelRequest) => {
  return await client.post("/hotel/search", body);
};

const getHotelOffer = async (offerID: string) => {
  return await client.get("/hotel/offer/" + offerID);
};

const getFlightOffer = async (body: FlightOfferRequest) => {
  return await client.post("/flight/offer", body);
};
const bookHotel = async (
  body: BookHotelRequest | HotelBookingRequestWithWallet
) => {
  return await client.post("/booking/hotel/", body);
};

const bookFlight = async (body: FlightBookingRequest) => {
  return await client.post("/booking/flight", body);
};

const getApprovableWallet = async () => {
  return await client.get("/wallet/approvable");
};

const getRequestByWallet = async (body: FetchRequest) => {
  return await client.post("/admin/bookings", body);
};
const getRequestableWallet = async () => {
  return await client.get("/wallet/requestable");
};

const getHotelBookingRequestDetail = async (
  body: HotelBookingDetailRequest
) => {
  return await client.post("/booking/hotel/detail", body);
};

const rejectHotelBookingRequest = async (body: RejectHotelBookingRequest) => {
  return await client.post("/booking/hotel/reject", body);
};

const approveHotelBookingRequest = async (body: ApproveHotelBookingRequest) => {
  return await client.post("/booking/hotel/approve", body);
};

const getCalendarBookingList = async (from_date: string, to_date: string) => {
  // format : yyyy-MM-dd
  return await client.get(
    "/calendar/bookings" + "?fromDate=" + from_date + "&toDate=" + to_date
  );
};

const getCalendarBookingDetail = async (body: GetBookingDetailRequest) => {
  return await client.post("/calendar/booking/detail", body);
};

const getAllTrips = async () => {
  return await client.get("/trip");
};

const getTripById = async (tripId: string) => {
  return await client.get("/trip/" + tripId);
};

const createTrip = async (body: BookTripgenRequest) => {
  return await client.post("/trip", body);
};

const addBookingToTrip = async (body: AddOfferToTripRequest) => {
  return await client.post("/trip/booking", body);
};

export const apiClient = {
  searchFlights,
  searchHotels,
  getHotelOffer,
  getFlightOffer,
  bookHotel,
  bookFlight,
  getApprovableWallet,
  getRequestByWallet,
  getRequestableWallet,
  getHotelBookingRequestDetail,
  rejectHotelBookingRequest,
  approveHotelBookingRequest,
  getCalendarBookingList,
  getCalendarBookingDetail,
  getAllTrips,
  getTripById,
  createTrip,
  addBookingToTrip,
};
