import { GetBookingDetailResponse } from "../../types/calendar";
import "./PopupCalendarComponent.css";
import React, { useEffect, useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
interface PopupCalendarProp {
  data: GetBookingDetailResponse;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopupCalendarComponent: React.FC<PopupCalendarProp> = ({
  data,
  setIsOpenPopup,
}) => {
  const closePopup = () => {
    setIsOpenPopup(false);
  };
  const [bookingStatus, setBookingStatus] = useState<string>();
  const setBookingStatusToDisplay = () => {
    if (data) {
      setBookingStatus(data.hotelBookingDetail.status);
    }
  };

  const convertToReadableString = (str: string): string => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  useEffect(() => {
    setBookingStatusToDisplay();
  }, []);

  return (
    <div className="PopupCalendar-container">
      <div className="PopupCalendar-subDisplay">
        <div className="PopupCalendar-title">
          {" "}
          Booking Detail{" "}
          <div onClick={closePopup} className="PopupCalendar-closeButton">
            <RxCrossCircled
              style={{ color: "#FF0000", width: "30px", height: "30px" }}
            />
          </div>
        </div>

        {bookingStatus == "Approve" ? (
          <div className="PopupCalendar-hotelFlex">
            <div>
              <b>Booking Type :</b>{" "}
              {data.bookingType.charAt(0).toUpperCase() +
                data.bookingType.slice(1)}
            </div>
            <div className="HotelBooking-hotelName">
              <div style={{ marginRight: "5px" }}>
                {" "}
                <b>Hotel Name :</b>
              </div>{" "}
              {data.hotelBookingDetail.hotelName}
            </div>
            <div>
              <b>Room Quantity :</b>{" "}
              {data.hotelBookingDetail.approve_offer.room_quantity}
            </div>
            <div className="Hotelbooking-amenities">
              <b>Amenities :</b>
              {data.hotelBookingDetail.amenities.map((amenity, index) => (
                <div key={index} style={{ marginLeft: "5px" }}>
                  {index > 0 && ","}
                  {convertToReadableString(amenity)}
                </div>
              ))}
            </div>
            <div>
              <b>Check In Date :</b>{" "}
              {data.hotelBookingDetail.approve_offer.check_in_date} @
              {data.hotelBookingDetail.approve_offer.check_in_at}
            </div>
            <div>
              <b>Check Out Date :</b>{" "}
              {data.hotelBookingDetail.approve_offer.check_out_date}
              {"  "}@{data.hotelBookingDetail.approve_offer.check_out_at}
            </div>
            <div>
              <b>Bed amount :</b> {data.hotelBookingDetail.approve_offer.beds}
            </div>
            <div>
              <b>Bed type :</b>{" "}
              {data.hotelBookingDetail.approve_offer.bed_type.toLocaleLowerCase()}
            </div>
            <div>
              <b>Guest per room :</b>{" "}
              {data.hotelBookingDetail.approve_offer.guest_per_room}
            </div>
            <div>
              <b>Board type :</b>{" "}
              {data.hotelBookingDetail.approve_offer.board_type.toLocaleLowerCase()}
            </div>
            <div>
              <b>Payment type :</b>{" "}
              {data.hotelBookingDetail.approve_offer.payment_type}
            </div>
            <div>
              {" "}
              <b>Base price :</b>{" "}
              {data.hotelBookingDetail.approve_offer.base_price}{" "}
              {data.hotelBookingDetail.approve_offer.currency}
            </div>
            <div>
              {" "}
              <b>Total price :</b>{" "}
              {data.hotelBookingDetail.approve_offer.total_price}{" "}
              {data.hotelBookingDetail.approve_offer.currency}
            </div>{" "}
            <div>
              {" "}
              <b> Requester Name :</b> {data.hotelBookingDetail.requesterName}
            </div>
            <div>
              {" "}
              <b>Status :</b> {data.hotelBookingDetail.status}d
            </div>
          </div>
        ) : (
          // status =  "Pending" or "Reject"
          <div className="PopupCalendar-hotelFlex">
            <div>
              <b> Type :</b>{" "}
              {data.bookingType.charAt(0).toUpperCase() +
                data.bookingType.slice(1)}
            </div>{" "}
            <div className="HotelBooking-hotelName">
              <div style={{ marginRight: "5px" }}>
                <b>Hotel Name :</b>
              </div>{" "}
              {data.hotelBookingDetail.hotelName}
            </div>
            <div>
              <b> Quantity :</b>{" "}
              {data.hotelBookingDetail.request_offer.room_quantity}
            </div>
            <div className="Hotelbooking-amenities">
              <b>Amenities :</b>
              {data.hotelBookingDetail.amenities.map((amenity, index) => (
                <div key={index} style={{ marginLeft: "5px" }}>
                  {index > 0 && ","}
                  {convertToReadableString(amenity)}
                </div>
              ))}
            </div>
            <div>
              <b>Check In Date :</b>{" "}
              {data.hotelBookingDetail.request_offer.check_in_date} @
              {data.hotelBookingDetail.request_offer.check_in_at}
            </div>
            <div>
              <b> Out Date :</b>{" "}
              {data.hotelBookingDetail.request_offer.check_out_date}
              {"  "}@{data.hotelBookingDetail.request_offer.check_out_at}
            </div>
            <div>
              {" "}
              <b>Bed amount :</b> {data.hotelBookingDetail.request_offer.beds}
            </div>
            <div>
              <b>Bed type :</b>{" "}
              {data.hotelBookingDetail.request_offer.bed_type.toLocaleLowerCase()}
            </div>
            <div>
              <b>Guest per room :</b>{" "}
              {data.hotelBookingDetail.request_offer.guest_per_room}
            </div>
            <div>
              <b>Board type :</b>{" "}
              {data.hotelBookingDetail.request_offer.board_type.toLocaleLowerCase()}
            </div>
            <div>
              <b>Payment type :</b>{" "}
              {data.hotelBookingDetail.request_offer.payment_type}
            </div>
            <div>
              {" "}
              <b>Base price :</b>{" "}
              {data.hotelBookingDetail.request_offer.base_price}{" "}
              {data.hotelBookingDetail.request_offer.currency}
            </div>
            <div>
              {" "}
              <b>Total price :</b>{" "}
              {data.hotelBookingDetail.request_offer.total_price}{" "}
              {data.hotelBookingDetail.request_offer.currency}
            </div>
            <div>
              {" "}
              <b>Requester Name :</b> {data.hotelBookingDetail.requesterName}
            </div>
            <div>
              <b>Status :</b> {data.hotelBookingDetail.status}
              {data.hotelBookingDetail.status === "Reject" ? "ed" : null}
            </div>
          </div>
        )}

        <div className="PopupCalendar-guestSection">
          <div style={{ fontWeight: "bold" }}>Guest :</div>
          {data.hotelBookingDetail.guests.map((guest, index) => (
            <div key={index} style={{ marginTop: "5px " }}>
              {index + 1}. {guest.title} {guest.firstName} {guest.lastName} (
              {guest.email})
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PopupCalendarComponent;
