import { useEffect, useState } from "react";
import FlightItem from "../../components/FlightItem/FlightItem";
import FlightSearchBar from "../../components/FlightSearchBar/FlightSearchBar";
import "./SearchPage.css";

import { SearchFlightOffer, SearchFlightResponse } from "../../types/flight";
import FlightDetail from "../../components/FlightDetail/FlightDetail";
import FilterFlight from "../../components/FilterFlight/FilterFlight";
import { useNavigate } from "react-router-dom";
import { FaGreaterThan, FaLessThan } from "react-icons/fa6";
import { BackTop } from "antd";
import { useFlightContext } from "../../context/FlightContext";

export const mockResult: SearchFlightResponse = {
  data: Array.from({ length: 20 }, (_, index) => ({
    id: (index + 1).toString(),
    totalPrice: 560.75,
    currency: "USD",
    travelType: "Economy",
    flightItineraries: [
      {
        durationHour: 10,
        durationMinute: 30,
        airlineName: "ANA",
        flightSegments: [
          {
            id: "1",
            carrierCode: "ANA",
            airlineName: "Nippon Airline",
            flightNumber: "800",
            aircraftCode: "122",
            departureIataCode: "BKK",
            departureAt: "2023-12-10T06:00:00",
            arrivalIataCode: "MNL",
            arrivalAt: "2023-12-10T16:30:00",
            durationHour: 10,
            durationMinute: 30,
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            id: "2",
            carrierCode: "ANA",
            airlineName: "Nippon Airline",
            flightNumber: "800",
            aircraftCode: "122",
            departureIataCode: "MNL",
            departureAt: "2023-12-10T20:00:00",
            arrivalIataCode: "SFO",
            arrivalAt: "2023-12-11T06:30:00",
            durationHour: 10,
            durationMinute: 30,
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
        departureIataCode: "BKK",
        departureDate: "2023-12-10",
        departureTime: "06:00",
        arrivalIataCode: "SFO",
        arrivalDate: "2023-12-10",
        arrivalTime: "16:30",
      },
    ],
    pricingOptions: {
      includedCheckedBagsOnly: true,
      refundableFare: false,
      noRestrictionFare: false,
      noPenaltyFare: false,
    },
    airlines: ["Nippon Airline"],
    layoverAirports: ["MNL"],
    departureDate: "2023-12-10",
    departureTime: "06:00",
    returnDate: "2023-12-12",
    returnTime: "09:00",
    validatingAirlineCodes: ["ANA"],
    travelerPricings: [],
  })),
};

const SearchPage = () => {
  const { setSelectedFlightForBooking } = useFlightContext();
  const navigate = useNavigate();

  const [flightResult, setFlightResult] = useState<SearchFlightOffer[]>(mockResult.data);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectIndex, setSelectIndex] = useState<number>(-1);
  const [filteredFlights, setFilteredFlights] = useState<SearchFlightOffer[] | null>(null);
  const [isFilterShow, setIsFilterShow] = useState(true);

  const flightResultHandler = (result: SearchFlightOffer[]) => {
    setFlightResult(result);
  };

  const handleSelectFlightItem = (targetIndex: number) => {
    setSelectIndex(targetIndex);
  };

  const loadingHandler = (loading: boolean) => {
    setLoading(loading);
  };

  const handleFilteredFlights = (filteredData: SearchFlightOffer[]) => {
    setFilteredFlights(filteredData);
  };

  const onSelectFlightItemHandler = () => {
    navigate("/flight/booking");
  };

  const handleFilterShow = () => {
    setIsFilterShow(!isFilterShow);
  };

  useEffect(() => {}, [filteredFlights]);

  return (
    <div className="search-page-container">
      <FlightSearchBar
        flightResultHandler={flightResultHandler}
        loadingHandler={loadingHandler}
        setFlightFilterHandler={handleFilteredFlights}
      ></FlightSearchBar>
      <div className="search-flight-container">
        {isFilterShow ? (
          <div className="search-page-filterFlight-section">
            <FilterFlight dataFlight={flightResult} setFilter={handleFilteredFlights} />
            <div className="search-page-button-to-showFilter" onClick={handleFilterShow}>
              <FaLessThan />
            </div>
          </div>
        ) : null}

        {!isFilterShow && (
          <div className="search-page-button-to-showFilter" onClick={handleFilterShow}>
            <FaGreaterThan />
          </div>
        )}
        <div className="search-page-flightItemDisplay">
          {!flightResult && <p>No Flight Items</p>}
          <div className="search-page-displayTotalText">
            result ({filteredFlights?.length ? filteredFlights?.length : 0} flights)
          </div>
          <div className="search-page-flightItemContainer">
            {filteredFlights &&
              filteredFlights.length != 0 &&
              filteredFlights.map((flight: SearchFlightOffer, idx: number) => (
                <div key={idx} onClick={() => handleSelectFlightItem(idx)}>
                  <FlightItem
                    data={flight}
                    isSelect={selectIndex == idx}
                    onSelectHandler={() => {
                      setSelectedFlightForBooking(flight);
                      onSelectFlightItemHandler();
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="search-page-flightDetailContainer">
          {selectIndex != -1 && flightResult && <FlightDetail data={flightResult[selectIndex]} />}
        </div>
      </div>
      <div className="custom-back-top">
        <BackTop />
      </div>
      ,
    </div>
  );
};

export default SearchPage;
