import React, { useEffect, useState } from "react";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import { Button, Select, message } from "antd";
import RequestTable from "../../components/RequestTable/RequestTable";
import "./FetchRequest.css";
import { apiClient } from "../../utils/client";
import { useNavigate } from "react-router-dom";
const FetchRequest = () => {
  const navigate = useNavigate();
  const columns = [
    { title: "ID", dataIndex: "id", key: "id", width: 150 },
    { title: "Name", dataIndex: "name", key: "name", width: 250 },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 150,
      filters: [
        { text: "Flight", value: "Flight" },
        { text: "Hotel", value: "Hotel" },
        { text: "Car", value: "Car" },
      ],
      onFilter: (value: any, record: { type: string }) => record.type === value,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 150,
      sorter: (a: { date: string }, b: { date: string }) =>
        new Date(a.date).getTime() - new Date(b.date).getTime(),
    },
    {
      title: "Price($)",
      dataIndex: "price",
      key: "price",
      width: 150,
      sorter: (a: { price: number }, b: { price: number }) => a.price - b.price,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      filters: [
        { text: "Pending", value: "Pending" },
        { text: "Approve", value: "Approve" },
        { text: "Reject", value: "Reject" },
      ],
      onFilter: (value: any, record: { status: string }) =>
        record.status === value,
    },
  ];

  const [requests, setRequests] = useState<Array<any>>([]);
  const [wallets, setWallets] = useState<Array<any>>([]);
  const [selectedWallet, setSelectedWallet] = useState<string | undefined>(
    undefined
  );

  const handleWalletChange = async (value: string) => {
    setSelectedWallet(value);

    try {
      const res = await apiClient.getRequestByWallet({ walletId: value });

      // Assuming the response structure has a 'bookings' property
      const bookings = res.data.bookings;

      // Map the data to match the structure of your 'columns'
      const mappedData = bookings.map(
        (booking: {
          id: any;
          requesterName: any;
          bookingType: any;
          requestDate: any;
          price: any;
          walletId: any;
          status: any;
        }) => ({
          id: booking.id,
          name: booking.requesterName,
          type: booking.bookingType,
          date: new Date(booking.requestDate).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
          price: booking.price,
          wallet: booking.walletId,
          status: booking.status,
        })
      );

      setRequests(mappedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRequestClick = (selectedRequestId: string) => {
    navigate(`/admin/${selectedRequestId}`);
  };

  useEffect(() => {
    async function fetchApprovableWallet() {
      try {
        let response = await apiClient.getApprovableWallet();

        setWallets(response.data.wallets);
      } catch (error: any) {
        message.error("You don't have permission.");
      }
    }

    fetchApprovableWallet();
  }, []);

  return (
    <div className="request-page-container">
      <div className="wallet-dropdown">
        <div className="wallet-header">
          <h3>Wallet :</h3>
        </div>
        <Select
          size="large"
          style={{ width: 200, margin: 16 }}
          placeholder="Select a wallet"
          onChange={handleWalletChange}
          defaultValue={selectedWallet}
          options={wallets.map((wallet) => ({
            value: wallet.id,
            label: wallet.name,
            disabled: false,
          }))}
        />
      </div>
      <div className="table-container"></div>
      <RequestTable
        data={requests}
        columns={columns}
        onRowClick={handleRequestClick}
      />
    </div>
  );
};

export default FetchRequest;
