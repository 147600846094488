import React, { useEffect, useState } from "react";
import "./FlightSearchBar.css";
import LocationInput from "../LocationInput/LocationInput";
import MyDatePicker from "../DatePickerComponent/MyDatePicker";
import Passenger from "../Passenger/Passenger";
import Button from "../Buttons/Buttons";
import MyCheckBox from "../MyCheckBox/MyCheckBox";
import { Dayjs } from "dayjs";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import axios from "axios";
import { TravelClass } from "../../types/enum/travelClass";
import { SearchFlightOffer, SearchFlightResponse } from "../../types/flight";
import { apiClient } from "../../utils/client";
import ModalLoading from "../ModalLoading/ModalLoading";
import { message } from "antd";
import { useFlightContext } from "../../context/FlightContext";

interface FlightSearchBarProp {
  flightResultHandler: (result: SearchFlightOffer[]) => void;
  loadingHandler: (loading: boolean) => void;
  setFlightFilterHandler: (result: any) => void;
}

const FlightSearchBar: React.FC<FlightSearchBarProp> = ({
  flightResultHandler,
  loadingHandler,
  setFlightFilterHandler,
}) => {
  const { setNumberOfPassenger } = useFlightContext();

  //Make a request for a user with a given ID
  const [originLocationCode, setOriginLocationCode] = useState<string>("");
  const [originLocation, setOriginLocation] = useState<string>("");
  const handleOriginLocation = (value: string) => {
    const code = value.match(/\(([^)]+)\)$/)?.[1] || "";
    setOriginLocationCode(code);
    setOriginLocation(value);
  };

  const [destinationLocationCode, setDestinationLocationCode] = useState<string>("");
  const [destinationLocation, setDestinationLocation] = useState<string>("");
  const handleDestinationLocation = (value: string) => {
    const code = value.match(/\(([^)]+)\)$/)?.[1] || "";
    setDestinationLocationCode(code);
    setDestinationLocation(value);
  };

  const [departDate, setDepartDate] = useState<Dayjs | null>(null);
  const [formattedDepartDate, setFormattedDepartDate] = useState<string>("");
  const handleDepartDate = (date: Dayjs | null) => {
    setDepartDate(date);

    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");
      setFormattedDepartDate(formattedDate);
    } else {
      setFormattedDepartDate("");
    }
  };

  const [returnDate, setReturnDate] = useState<Dayjs | null>(null);
  const [formattedReturnDate, setFormattedReturnDate] = useState<string>("");
  const handleReturnDate = (date: Dayjs | null) => {
    setReturnDate(date);

    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");
      setFormattedReturnDate(formattedDate);
    } else {
      setFormattedReturnDate("");
    }
  };

  const [flightClass, setFlightClass] = useState<TravelClass>(TravelClass.ECONOMY);
  const [adultNumber, setAdultNumber] = useState(1);
  const [childernNumber, setChildernNumber] = useState(0);

  const [isOnewayChecked, setIsOnewayChecked] = useState(true);
  const handleOnewayCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsOnewayChecked(e.target.checked);
  };

  const [isFlexibleDayCheck, setIsFlexibleDayCheck] = useState(false);
  const handleFlexibleDayCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsFlexibleDayCheck(e.target.checked);
  };

  const handleSearchClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      if (!originLocationCode || !destinationLocationCode || !formattedDepartDate) {
        message.error("Please provide all these information: Origin, Destination, and Departure date.");
        throw new Error("Please provide all these information: Origin, Destination, and Departure date.");
      }

      loadingHandler(true);
      openModal();

      const travellers = [
        {
          travellerType: "ADULT",
          travellerAmount: adultNumber,
        },
      ];

      if (childernNumber > 0) {
        travellers.push({
          travellerType: "CHILD",
          travellerAmount: childernNumber,
        });
      }

      const response = await apiClient.searchFlights({
        originLocationCode: originLocationCode,
        destinationLocationCode: destinationLocationCode,
        departureDate: formattedDepartDate,
        returnDate: formattedReturnDate,
        travellers: travellers,
        cabinType: flightClass,
        isOneway: isOnewayChecked,
        isFlexible: isFlexibleDayCheck,
      });

      const flightResult = response.data;
      flightResultHandler(flightResult.data);
      setFlightFilterHandler(flightResult.data);
      closeModal();
    } catch (err) {
      message.error("Error during flight search:");
      closeModal();
    }
  };

  const [isOpenModal, setIsOpenModal] = useState(false);
  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    setNumberOfPassenger(adultNumber + childernNumber);
  }, [adultNumber, childernNumber]);

  return (
    <div className="flight-search-bar-container">
      <ModalLoading isOpen={isOpenModal}></ModalLoading>
      <div className="searchBox">
        <div className="fromComponent">
          From
          <LocationInput
            placeholder="Input airport code or city name"
            originLocation={originLocation}
            onInputChange={handleOriginLocation}
          />
        </div>

        <div className="toComponent">
          To
          <LocationInput
            placeholder="Input airport code or city name"
            originLocation={destinationLocation}
            onInputChange={handleDestinationLocation}
          />
        </div>

        <div className="departComponent">
          Depart
          <MyDatePicker selectedDate={departDate} onDateChange={handleDepartDate} />
        </div>

        <div className="returnComponent">
          Return
          <MyDatePicker
            selectedDate={returnDate}
            onDateChange={handleReturnDate}
            disable={isOnewayChecked}
          ></MyDatePicker>
        </div>

        <div className="passengersComponent">
          Passengers
          <Passenger
            flightClass={flightClass}
            setFlightClass={setFlightClass}
            adultNumber={adultNumber}
            setAdultNumber={setAdultNumber}
            childernNumber={childernNumber}
            setChildernNumber={setChildernNumber}
          />
        </div>

        <div className="searchComponent">
          <Button
            message={"Search"}
            bwidth="149px"
            bheight="50px"
            borderRadius="20px"
            fontSize="20px"
            onClick={handleSearchClick}
          ></Button>
        </div>
      </div>

      <div className="Checkbox-panel-searchbar">
        <div className="myCheckboxSearchBox">
          <MyCheckBox isChecked={isOnewayChecked} onCheckboxChange={handleOnewayCheckboxChange} />
          one-way
        </div>

        <div className="FlexibleCheckbox">
          <MyCheckBox isChecked={isFlexibleDayCheck} onCheckboxChange={handleFlexibleDayCheckboxChange} />
          Flexible Day (+/- 3 days)
        </div>
      </div>
    </div>
  );
};

export default FlightSearchBar;
