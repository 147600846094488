import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaWallet } from "react-icons/fa";
import "./WalletOption.css";

interface WalletOptionProps {
  name: string;
  credit: number;
  currency: string;
  onSelect: () => void;
  isSelected: boolean;
}

const WalletOption: React.FC<WalletOptionProps> = ({ name, credit, currency, onSelect, isSelected }) => (
  <div onClick={onSelect} className="wallet-select-bookingdetail-container">
    <div className="wallet-select-bookingdetail">
      <div className="wallet-left-content">
        {isSelected && <BsCheckCircleFill size={20} color="rgba(0, 173, 181, 1)" style={{ marginRight: "10px" }} />}
        <FaWallet style={{ marginRight: "10px" }} />
        {name}
      </div>
      <div className="creditAndCurrency">
        {credit.toLocaleString()} {currency}
      </div>
    </div>
  </div>
);

export default WalletOption;
