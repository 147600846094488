import React, { createContext, useContext, useEffect, useState } from "react";
import { apiClient } from "../utils/client";
import { User } from "../types/user";
import { useCookies } from "react-cookie";
import { checkTokenValid, decodeJwtToken } from "../utils/auth";
interface UserContextValue {
  user: User;
  setUser: (user: User) => void;
  isLogin: boolean;
  setIsLogin: (bool: boolean) => void;
}

const UserContext = createContext<UserContextValue>({} as UserContextValue);

export function useUserContext() {
  return useContext(UserContext);
}

const defaultUser: User = {
  userId: "",
  permissions: [],
};

const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<User>(defaultUser);
  const [cookie, setCookie, removeCookie] = useCookies(["accessToken"]);
  const [isLogin, setIsLogin] = useState<boolean>(
    checkTokenValid(cookie.accessToken)
  );

  useEffect(() => {
    if (isLogin) {
      const decodeToken = decodeJwtToken(cookie.accessToken);
      setUser({
        userId: decodeToken.user_id,
        permissions: decodeToken.permissions,
      });
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, isLogin, setIsLogin }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
