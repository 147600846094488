import { FC, useState } from "react";
import "./TripGenSummary.css";
import { FaEdit, FaPlane, FaCar } from "react-icons/fa";
import { BiSolidHotel } from "react-icons/bi";
import { Button, Input } from "antd";
import { SearchFlightOffer } from "../../types/flight";
import { HotelOffer } from "../../types/hotel";
import airportOptions from "../../utils/airportOptions";
import { useTripgenContext } from "../../context/TripgenContext";
import ModalLoading from "../ModalLoading/ModalLoading";
import { generateCarrentalLink } from "../../utils/generateCarrental";

interface TripGenSummaryProps {
  flightSelected: SearchFlightOffer[];
  hotelSelected: HotelOffer[];
}

const TripGenSummary: FC<TripGenSummaryProps> = ({ flightSelected, hotelSelected }) => {
  const {
    hotelSearchRequests,
    flightSearchRequests,
    tripGenPhaseHandler,
    resetButtonHandler,
    flightSearchResults,
    nameTrip,
    setNameTrip,
  } = useTripgenContext();

  const planTripButtonHandler = () => {
    tripGenPhaseHandler(true);
  };

  const getAirportName = (code: string) => {
    for (const [name, airportCode] of airportOptions) {
      if (airportCode === code) {
        return name;
      }
    }
  };

  const carRentalLink = () => {
    const carrentalLink = generateCarrentalLink(
      flightSearchRequests[0].destinationLocationCode,
      flightSearchRequests[0].destinationLocationCode,
      flightSearchRequests[0].departureDate,
      flightSearchRequests[0].returnDate ? flightSearchRequests[0].returnDate : "No Return Date"
    );
    return carrentalLink;
  };

  return (
    <div className="tripgen-summary-page">
      <div>
        <div className="summary-header">
          <FaEdit style={{ paddingRight: "5px" }} />
          Name Your Trip
        </div>
        <div className="NameTrip-container">
          <Input
            type="text"
            placeholder="Name your trip"
            defaultValue={nameTrip}
            onChange={(e) => setNameTrip(e.target.value)}
            size="large"
          ></Input>
        </div>
        <div className="summary-header">
          <FaPlane style={{ paddingRight: "5px" }} />
          Flights
        </div>
        <div style={{ justifyContent: "center" }}>
          {flightSelected.map((flight, index) => (
            <div className="flight-container">
              <div className="flight-first-grid">
                <div>
                  {" "}
                  <strong>From - To :</strong>{" "}
                  {getAirportName(flight.flightItineraries[0].flightSegments[0].departureIataCode)} -{" "}
                  {getAirportName(
                    flight.flightItineraries[0].flightSegments[flight.flightItineraries[0].flightSegments.length - 1]
                      .arrivalIataCode
                  )}
                </div>

                <div>
                  {" "}
                  <strong>Airlines : </strong>
                  {flight.airlines.join(", ")}
                </div>
              </div>
              <div className="flight-second-grid">
                <div>
                  {" "}
                  <strong>Flight type : </strong>
                  {flight.flightItineraries.length == 1 ? "One-way" : "Return"}
                </div>
                <div>
                  <strong>Depature Date :</strong> {flight.departureDate} @{flight.departureTime}
                </div>
                {flight.returnDate ? (
                  <div>
                    <strong>Return Date : </strong>
                    {flight.returnDate} @{flight.returnTime}
                  </div>
                ) : null}
              </div>

              <div className="flight-third-grid">
                <div>
                  {" "}
                  <strong>Passengers :</strong>{" "}
                  {flightSearchRequests[index].travellers[0].travellerAmount +
                    (flightSearchRequests[index].travellers[1]
                      ? flightSearchRequests[index].travellers[1].travellerAmount
                      : 0)}
                </div>
                <div>
                  <strong>Price :</strong> {flight.totalPrice} {flight.currency}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="summary-header">
          <BiSolidHotel style={{ paddingRight: "5px" }} />
          Hotels
        </div>

        {hotelSelected.map((hotel, index) => (
          <div className="hotel-container">
            <div className="hotel-first-grid">
              <div>
                {" "}
                <strong>Hotel Name : </strong>
                {hotel.hotelName}
              </div>
              <div>
                {" "}
                <strong>Check in Date : </strong>
                {hotel.offers[0].checkInDate}
              </div>
              <div>
                {" "}
                <strong>Check out Date :</strong> {hotel.offers[0].checkOutDate}
              </div>
            </div>
            <div className="hotel-second-grid">
              <div>
                <strong>Number of Room :</strong> {hotel.offers[0].roomQuantity}{" "}
                {parseInt(hotel.offers[0].roomQuantity) > 1 ? "rooms" : "room"}
              </div>

              <div>
                {" "}
                <strong>Guests : </strong>
                {hotelSearchRequests[index].adult}
              </div>
              <div>
                <strong>Price :</strong> {hotel.offers[0].price.total} {hotel.offers[0].price.currency}
              </div>
            </div>
          </div>
        ))}

        <div className="summary-header">
          <FaCar style={{ paddingRight: "5px" }} />
          Car Rentals
        </div>
        <div className="NameTrip-container">
          Click here to rent a car for your trip
          <a href={carRentalLink()} target="_blank" rel="noreferrer" style={{ marginLeft: "20px" }}>
            Here
          </a>
        </div>

        <div className="tripgen-button-container">
          <button
            className="tripgen-summary-btn back-btn"
            onClick={() => {
              resetButtonHandler();
            }}
            type="button"
          >
            Reset Trip
          </button>
          <Button className="tripgen-summary-btn next-btn" onClick={planTripButtonHandler}>
            Plan Trip
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TripGenSummary;
