import { jwtDecode } from "jwt-decode";
import { JwtPayload } from "../types/authJwt";


export const checkTokenValid = (token: string) => {
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    const isTokenExpired =
        decodedToken.exp !== undefined && Date.now() > decodedToken.exp * 1000;

    return !isTokenExpired;
};

export const checkPermissionAdmin = (token: string) => {
    const decodedToken = jwtDecode(token) as JwtPayload;
    const permission: string[] = decodedToken.permissions;
    if (permission.includes("admin")) {
        return true;
    }
    return false;
}

export const decodeJwtToken = (token: string) => {
    return jwtDecode(token) as JwtPayload;
}