import React from "react";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

interface MyCheckBoxProps {
  isChecked: boolean;
  onCheckboxChange: (e: CheckboxChangeEvent) => void;
  description?: string;
}

const MyCheckBox: React.FC<MyCheckBoxProps> = ({
  isChecked,
  onCheckboxChange,
  description,
}) => (
  <Checkbox
    checked={isChecked}
    onChange={onCheckboxChange}
    style={{
      color: "white",
      fontSize: "18px",
      fontFamily: "Roboto",
    }}
  >
    {description}
  </Checkbox>
);

export default MyCheckBox;
