import { DatePicker } from "antd";
import React from "react";
import "./MyDatePicker.css";
import dayjs, { Dayjs } from "dayjs";

// Define the props interface
interface MyDatePickerProps {
  selectedDate: Dayjs | null;
  onDateChange: (date: Dayjs | null) => void;
  disable?: boolean;
  width?: string;
  height?: string;
  fontSize?: string;
}

const MyDatePicker: React.FC<MyDatePickerProps> = ({
  selectedDate,
  onDateChange,
  disable,
  width = "203px", // Default width
  height = "50px", // Default height
  fontSize = "16px", // Default font size
}) => {
  return (
    <div>
      <DatePicker
        value={selectedDate}
        onChange={onDateChange}
        disabled={disable}
        className="datepicker-component"
      />
    </div>
  );
};

export default MyDatePicker;
