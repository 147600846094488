import "./SearchHotel.css";
import HotelSearchBar from "../../components/HotelSearchBar/HotelSearchBar";
import HotelOfferItem from "../../components/HotelOfferItem/HotelOfferItem";
import { HotelOffer, Offer, SearchHotelResponse } from "../../types/hotel";
import FilterHotel from "../../components/FilterHotel/FilterHotel";
import { useState } from "react";
import { FaGreaterThan, FaLessThan } from "react-icons/fa6";
import { SearchFlightOffer } from "../../types/flight";
import { useNavigate, useParams } from "react-router-dom";
import HotelInformationComponent from "../../components/HotelInformationComponent/HotelInformationComponent";
import { BackTop } from "antd";

export const mockSearchHotelResponse: SearchHotelResponse = {
  data: [
    {
      hotelId: "RTBKKSUV",
      propertyType: "hotel",
      hotelName: "Novotel Bangkok Suvarnabhumi Airport",
      amenities: ["SWIMMING_POOL", "SPA", "FITNESS_CENTER", "PARKING", "HELLO"],
      chainCode: "RT",
      iataCode: "BKK",
      latitude: 13.69721,
      longitude: 100.75192,
      location:
        "999 หมู่ที่ 7, อาคาร ห้อง 112-113 เขตปลอดอากร ท่าอากาศยานสุวรรณภูมิ, ต.ราชาเทวะ อ.บางพลี สมุทรปราการ, 10540 Tambon Nong Prue, Amphoe Bang Phli, Chang Wat Samut Prakan 10540, Thailand",
      distance: {
        value: 0.65,
        unit: "KM",
      },
      offers: [
        {
          id: "ET8KE71YQX",
          checkInDate: "2023-12-26",
          checkOutDate: "2023-12-27",
          roomQuantity: "1",
          boardType: "ROOM_ONLY",
          room: {
            category: "EXECUTIVE_ROOM",
            beds: 1,
            bedType: "KING",
            guestPerRoom: 2,
          },
          price: {
            currency: "THB",
            base: "",
            total: "1005.48",
          },
          paymentPolicy: {
            type: "guarantee",
            amount: null,
            deadline: null,
          },
          cancellationPolicy: [
            {
              type: "",
              amount: "",
              numberOfNights: 0,
              percentage: "",
              deadline: "2023-12-25T00:00:00+07:00",
            },
          ],
          checkInAt: null,
          checkOutAt: null,
        },
      ],
    },
  ],
};

const SearchHotel = () => {
  const navigate = useNavigate();
  const { tripId } = useParams();

  const [isFilterShow, setIsFilterShow] = useState(true);
  const [hotelResult, setHotelResult] = useState<SearchHotelResponse | null>(
    mockSearchHotelResponse
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [filteredHotels, setFilteredHotels] = useState<HotelOffer[] | null>(
    null
  );
  const [selectIndex, setSelectIndex] = useState<number>(-1);

  const handleFilterShow = () => {
    setIsFilterShow(!isFilterShow);
  };

  const hotelResultHandler = (result: SearchHotelResponse) => {
    setHotelResult(result);
  };

  const loadingHandler = (loading: boolean) => {
    setLoading(loading);
  };

  const handleFilteredHotels = (filteredData: HotelOffer[]) => {
    setFilteredHotels(filteredData);
  };

  const handleSelectHotelItem = (targetIndex: number) => {
    setSelectIndex((prevIndex) => {
      if (prevIndex != targetIndex) {
        return targetIndex;
      }
      return -1;
    });
  };

  const handleItemClick = (offerId: string) => {
    console.log(tripId);
    if (tripId) {
      navigate(`/hotel/booking/${offerId}/${tripId}`);
    } else {
      navigate(`/hotel/booking/${offerId}`);
    }
  };

  return (
    <div className="searchHotelPage-container">
      <div className="SearchHotelPage-Hotelsearchbar">
        <HotelSearchBar
          hotelResultHandler={hotelResultHandler}
          loadingHandler={loadingHandler}
          setHotelFilterHandler={handleFilteredHotels}
        />
      </div>
      <div className="search-hotel-container">
        {isFilterShow ? (
          <div className="search-page-filterHotel-section">
            <FilterHotel
              dataHotel={hotelResult?.data}
              setFilter={handleFilteredHotels}
            />
            <div
              className="search-page-button-to-showFilter"
              onClick={handleFilterShow}
            >
              <FaLessThan />
            </div>
          </div>
        ) : null}

        {!isFilterShow && (
          <div
            className="search-page-button-to-showFilter"
            onClick={handleFilterShow}
          >
            <FaGreaterThan />
          </div>
        )}
        <div className="search-page-flightItemDisplay">
          <div className="search-page-displayTotalText">
            result ({filteredHotels?.length ? filteredHotels?.length : 0}{" "}
            Hotels)
            <div className="search-page-no-item-text">
              {!hotelResult && "No Hotel Items"}
            </div>
          </div>
          <div className="search-page-hoteltItemContainer ">
            {filteredHotels &&
              filteredHotels.length != 0 &&
              filteredHotels.map((hotel: HotelOffer, idx: number) => (
                <div key={idx} onClick={() => handleSelectHotelItem(idx)}>
                  <HotelOfferItem
                    hotelOffer={hotel}
                    onClick={() => handleItemClick(hotel.offers[0].id)}
                  />
                </div>
              ))}
          </div>

          {/* {mockSearchHotelResponse.data.map((hotelOffer: HotelOffer) => (
            <HotelOfferItem hotelOffer={hotelOffer} />
          ))} */}

          {/* <HotelInformationComponent
            info={mockSearchHotelResponse.data[0]}
          ></HotelInformationComponent> */}
        </div>
      </div>
      <div className="custom-back-top">
        <BackTop />
      </div>
    </div>
  );
};

export default SearchHotel;
