import { FC } from "react";
import { ContactPassenger } from "../../types/flight";
import { CardInformation } from "../PaymentForm/PaymentForm";
import "./HotelConfirmInfo.css";
import { Guest } from "../../types/hotel";
import { RequestableWallet } from "../../types/enum/wallet";

const mockData = [
  {
    title: "Mr",
    firstname: "John",
    middlename: "Doe",
    lastname: "Smith",
    gender: "Male",
    birthdate: "1990-01-01",
    region: "Buddhist",
    passportid: "AB123456",
    expirydate: "2025-01-01",
    phone: "555-555-5555",
    email: "jane.doe@example.com",
  },
  {
    firstname: "Jane",
    middlename: "Doe",
    lastname: "Smith",
    gender: "Female",
    birthdate: "1992-03-15",
    region: "Buddhist",
    passportid: "CD789012",
    expirydate: "2024-08-30",
  },
  {
    firstname: "Jane",
    middlename: "Doe",
    lastname: "Smith",
    gender: "Female",
    birthdate: "1992-03-15",
    region: "Buddhist",
    passportid: "CD789012",
    expirydate: "2024-08-30",
  },
  {
    firstname: "Jane",
    middlename: "Doe",
    lastname: "Smith",
    gender: "Female",
    birthdate: "1992-03-15",
    region: "Buddhist",
    passportid: "CD789012",
    expirydate: "2024-08-30",
  },
  // Add more passengers as needed
];

const mockContactInfo = {
  firstName: "Jane",
  middleName: "Doe",
  lastName: "Smith",
  phone: "555-555-5555",
  email: "jane.doe@example.com",
};

const mockPaymentInfo = {
  cardNumber: "1234-1234-1234-1234",
  cardHolderName: "John Doe",
  expiryDate: "12/2027",
};

interface HotelConfirmInfoProp {
  guests: Guest[];
  cardInfo: CardInformation;
  paymentMethod: string;
  walletInfo: RequestableWallet;
  fitContent?: boolean;
}

const HotelConfirmInfo: FC<HotelConfirmInfoProp> = ({ guests, cardInfo, paymentMethod, walletInfo, fitContent }) => {
  const last4Digits = cardInfo.cardNumber.slice(-4);
  const containerStyle = {
    height: fitContent ? "fit-content" : "521px",
  };
  return (
    <div className="confirm-container" style={containerStyle}>
      {guests.map((guest, guestIndex) => (
        <div key={guestIndex}>
          <div className="passenger-info-header">Guest {guestIndex + 1}</div>
          <div className="passenger-info2">
            <div className="confirm-item">Title: {guest.title}</div>
            <div className="confirm-item">Firstname: {guest.firstName}</div>

            <div className="confirm-item">Lastname: {guest.lastName}</div>
            <div className="confirm-item">Phone number: {guest.phone}</div>
            <div className="confirm-item">Email: {guest.email}</div>
          </div>
        </div>
      ))}

      {paymentMethod == "credit" ? (
        <div className="payment-method">
          <div className="passenger-info-header">Payment Method</div>
          <div className="passenger-info2">
            <div className="confirm-item">Card number: xxxx-xxxx-xxxx-{last4Digits}</div>
            <div className="confirm-item">Card holder name: {cardInfo.cardHolderName}</div>
            <div className="confirm-item">Expiry date: {cardInfo.expiryDate?.toString()}</div>
          </div>
        </div>
      ) : (
        <div className="payment-method">
          <div className="passenger-info-header">Payment Method</div>
          <div className="passenger-info2">
            <div className="confirm-item">Wallet: {walletInfo.name}</div>
            <div className="confirm-item">
              Credit Limit: {walletInfo.credit_limit} {walletInfo.currency}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HotelConfirmInfo;
