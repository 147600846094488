import { HotelOffer, Offer } from "../../types/hotel";
import "./HotelInformationComponent.css";
import React from "react";

interface HotelInformationProps {
  info: HotelOffer;
}
const HotelInformationComponent: React.FC<HotelInformationProps> = ({
  info,
}) => {
  return (
    <div className="HotelInformation-container">
      <div className="FlightInformation-header">Hotel Information</div>
      <div className="HotelInformation-hotelName">
        <div className="normal-space-flightComponent">Hotel Name : </div>
        {info.hotelName}
      </div>

      <div className="HotelInformation-hotelLocation">
        <div className="normal-space-flightComponent">Location : </div>
        <div style={{ textAlign: "start" }}>{info.location}</div>
      </div>

      <div className="HotelInformation-nightAndGuest">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="normal-space-flightComponent">Check in : </div>
          {info.offers[0].checkInDate}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="normal-space-flightComponent">Check out : </div>
          {info.offers[0].checkOutDate}
        </div>
      </div>

      <div className="HotelInformation-roomQuantity">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="normal-space-flightComponent">Room Quantity : </div>
          {info.offers[0].roomQuantity}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="normal-space-flightComponent">Number of guest : </div>
          {info.offers[0].room.guestPerRoom}
        </div>
      </div>

      <div className="HotelInformation-amentity">
        <div className="normal-space-flightComponent">Amentity :</div>
        <div className="hotel-offer-item-amenities">
          {info.amenities &&
            info.amenities.length > 0 &&
            info.amenities.map((amenity: string) => (
              <div className="hotel-offer-item-amenity">{amenity}</div>
            ))}
        </div>
      </div>

      <div className="FlightInformation-pricePanel">
        Total
        <div className="FlightInformation-price">
          {" "}
          ${" "}
          {parseFloat(info.offers[0].price.total) >= 1000
            ? parseFloat(info.offers[0].price.total).toLocaleString()
            : parseFloat(info.offers[0].price.total)}{" "}
          {info.offers[0].price.currency}
        </div>
      </div>
    </div>
  );
};

export default HotelInformationComponent;
