import Calendar from "../../components/Calendar/Calendar";
import { message } from "antd";
import { apiClient } from "../../utils/client";
import "./CalendarPage.css";
import React, { useEffect, useState } from "react";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import BookingListCalendarComponent from "../../components/ฺBookingListCalendarComponent/BookingListCalendarComponent";
import BookingListContainer from "../../components/ฺBookingListCalendarComponent/BookingListContainer";
import { BookingList, GetBookingListResponse } from "../../types/calendar";
import { Checkbox } from "antd";

const CalendarPage = () => {
  const currentDate = new Date();
  const [calendarView, setCalendarView] = useState("timeGridWeek"); // Initial calendar view

  const handleSetCalendarView = (view: string) => {
    setCalendarView(view);
  };

  currentDate.setDate(1); // Set the date component to 1 for the first day of the month

  // Format the date manually
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because month index starts from 0
  const day = "01"; // Since we're setting the date to 1, day will always be 01
  const firstDayOfMonth = `${year}-${month}-${day}`;

  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  );

  const [bookingList, setBookingList] = useState<BookingList[]>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isPageRefresh, setIsPageRefresh] = useState(true);

  const [events, setEvents] = useState([]);
  const [dateRange, setDateRange] = useState<{
    fromDate: string;
    toDate: string;
  }>({
    fromDate: firstDayOfMonth,
    toDate: lastDayOfMonth.toISOString().substring(0, 10),
  });
  const [filters, setFilters] = useState<{
    flight: boolean;
    hotel: boolean;
    creditCard: boolean;
    wallet: boolean;
  }>({
    flight: true,
    hotel: true,
    creditCard: true,
    wallet: true,
  });
  const [originalEvents, setOriginalEvents] = useState([]);

  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };
  const handleCheckboxChange = (name: string, checked: boolean) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: checked,
    }));
  };

  const filteredBookingList = bookingList?.filter((booking) => {
    return (
      (filters.flight || booking.paymentType !== "flight") &&
      (filters.hotel || booking.bookingType !== "hotel") &&
      (filters.creditCard || booking.paymentType !== "creditCard") &&
      (filters.wallet || booking.paymentType !== "wallet")
    );
  });

  const fetchCalendarBookings = async () => {
    try {
      console.log(firstDayOfMonth);
      setIsPageRefresh(false);
      console.log(isPageRefresh);
      openModal();
      const response = await apiClient.getCalendarBookingList(
        dateRange.fromDate,
        dateRange.toDate
      );
      const bookings = response.data.data || [];
      if (bookings.length > 0) {
        const calendarEvents = bookings.map((booking: any) => ({
          title: booking.hotelName,
          start: booking.startDate + "T" + booking.startAt,
          end: booking.endDate + "T" + booking.endAt,
          color:
            booking.bookingType === "hotel"
              ? "rgb(84, 88, 68)"
              : "rgb(70, 147, 222)",
          extendedProps: {
            bookingId: booking.bookingId,
            bookingType: booking.bookingType,
            status: booking.status,
            paymentType: booking.paymentType,
          },
        }));
        setEvents(calendarEvents);
        setOriginalEvents(calendarEvents); // Store original unfiltered events
        setBookingList(bookings);
      } else {
        console.log("No calendar bookings found.");
        setEvents([]);
        setOriginalEvents([]);
        setBookingList([]);
      }
    } catch (error) {
      console.error("Error fetching calendar bookings:", error);
    } finally {
      closeModal();
    }
  };

  const handleDateRangeChange = async (dates: {
    startStr: string;
    endStr: string;
  }) => {
    //console.log(calendarView);
    let fromDate = dates.startStr.substring(0, 10);
    let toDate = dates.endStr.substring(0, 10);

    // if (calendarView === "dayGridMonth") {
    //   // If the view is dayGridMonth, adjust fromDate to be the 1st day of the next month
    //   const currentMonth = new Date(fromDate).getMonth();
    //   const currentYear = new Date(fromDate).getFullYear();
    //   const nextMonthFirstDay = new Date(currentYear, currentMonth + 1, 2);
    //   const nextMonthLastDay = new Date(currentYear, currentMonth + 2, 1);
    //   fromDate = nextMonthFirstDay.toISOString().substring(0, 10);
    //   toDate = nextMonthLastDay.toISOString().substring(0, 10);
    // }

    setDateRange({
      fromDate: fromDate,
      toDate: toDate,
    });

    if (isPageRefresh == false)
      try {
        openModal();
        console.log(fromDate, toDate);
        const response = await apiClient.getCalendarBookingList(
          fromDate,
          toDate
        );
        const bookings = response.data.data || [];
        if (bookings.length > 0) {
          const calendarEvents = bookings.map((booking: any) => ({
            title: booking.hotelName,
            start: booking.startDate + "T" + booking.startAt,
            end: booking.endDate + "T" + booking.endAt,
            color:
              booking.bookingType === "hotel"
                ? "rgb(84, 88, 68)"
                : "rgb(70, 147, 222)",
            extendedProps: {
              bookingId: booking.bookingId,
              bookingType: booking.bookingType,
              status: booking.status,
              paymentType: booking.paymentType,
            },
          }));
          setEvents(calendarEvents);
          setOriginalEvents(calendarEvents); // Store original unfiltered events
          setBookingList(bookings);
        } else {
          console.log("No calendar bookings found.");
          setEvents([]);
          setOriginalEvents([]);
          setBookingList([]);
        }
      } catch (error) {
        console.error("Error fetching calendar bookings:", error);
      } finally {
        closeModal();
      }
  };

  useEffect(() => {
    fetchCalendarBookings();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Perform actions here that you want to trigger on page refresh
      setIsPageRefresh(true);
      // You can also perform any other actions or call any functions here
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  //filter
  useEffect(() => {
    const filteredEvents = originalEvents.filter((event: any) => {
      if (!filters.hotel && event.extendedProps.bookingType === "hotel") {
        return false;
      }

      if (!filters.flight && event.extendedProps.paymentType === "flight") {
        return false;
      }

      if (
        !filters.creditCard &&
        event.extendedProps.paymentType === "creditCard"
      ) {
        return false;
      }

      if (!filters.wallet && event.extendedProps.paymentType === "wallet") {
        return false;
      }
      return true; // Include the event if it passes all filter checks
    });
    setEvents(filteredEvents);
  }, [filters, originalEvents]);

  return (
    <div>
      <h1 style={{ paddingLeft: "20px" }}>Calendar</h1>

      <ModalLoading isOpen={isOpenModal} />
      <div className="BookingListPage-grid">
        <div className="BookingListPage-Calendar">
          <div className="filter-wrapper">
            <p className="filter-type">Booking Type</p>
            <Checkbox
              style={{ paddingRight: "20px" }}
              checked={filters.flight}
              onChange={(e) => handleCheckboxChange("flight", e.target.checked)}
            >
              Flight
            </Checkbox>
            <Checkbox
              style={{ paddingRight: "50px" }}
              checked={filters.hotel}
              onChange={(e) => handleCheckboxChange("hotel", e.target.checked)}
            >
              Hotel
            </Checkbox>
            <p className="filter-type">Payment Type</p>
            <Checkbox
              style={{ paddingRight: "20px" }}
              checked={filters.creditCard}
              onChange={(e) =>
                handleCheckboxChange("creditCard", e.target.checked)
              }
            >
              Credit Card
            </Checkbox>
            <Checkbox
              style={{ paddingRight: "20px" }}
              checked={filters.wallet}
              onChange={(e) => handleCheckboxChange("wallet", e.target.checked)}
            >
              Wallet
            </Checkbox>
          </div>
          <Calendar
            events={events}
            onDateRangeChange={handleDateRangeChange}
            setCalendarView={handleSetCalendarView}
          />
        </div>
        <div>
          <BookingListContainer bookingList={filteredBookingList} />
        </div>
      </div>
    </div>
  );
};

export default CalendarPage;
