import React, { FC, useEffect, useState } from "react";
import { Guest, HotelOffer, SearchHotelResponse } from "../../types/hotel";
import "./HotelBookingPage.css";
import { Button, Form } from "antd";
import GuestInfo from "../../components/GuestInfo/GuestInfo";
import CurrentState from "../../components/CurrentState/CurrentState";
import {
  ConfirmInfoStep,
  PaymentInfoStep,
  TravellerInfoStep,
} from "../../utils/stepstatus";
import PaymentForm, {
  CardInformation,
} from "../../components/PaymentForm/PaymentForm";
import HotelConfirmInfo from "../../components/HotelConfirmInfo/HotelConfirmInfo";
import HotelInformationComponent from "../../components/HotelInformationComponent/HotelInformationComponent";
import { useNavigate, useParams } from "react-router-dom";
import { apiClient } from "../../utils/client";
import { RequestableWallet } from "../../types/enum/wallet";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import { message } from "antd";
import ModalLoadingDone from "../../components/ModalLoading/ModalLoadingDone";
import dayjs from "dayjs";

export const sampleGuest: Guest = {
  title: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
};

const mockSearchHotelResponse: SearchHotelResponse = {
  data: [
    {
      hotelId: "RTBKKSUV",
      propertyType: "hotel",
      hotelName: "Novotel Bangkok Suvarnabhumi Airport",
      amenities: ["SWIMMING_POOL", "SPA", "FITNESS_CENTER", "PARKING", "HELLO"],
      chainCode: "RT",
      iataCode: "BKK",
      latitude: 13.69721,
      longitude: 100.75192,
      location:
        "999 หมู่ที่ 7, อาคาร ห้อง 112-113 เขตปลอดอากร ท่าอากาศยานสุวรรณภูมิ, ต.ราชาเทวะ อ.บางพลี สมุทรปราการ, 10540 Tambon Nong Prue, Amphoe Bang Phli, Chang Wat Samut Prakan 10540, Thailand",
      distance: {
        value: 0.65,
        unit: "KM",
      },
      offers: [
        {
          id: "ET8KE71YQX",
          checkInDate: "2023-12-26",
          checkOutDate: "2023-12-27",
          roomQuantity: "1",
          boardType: "ROOM_ONLY",
          room: {
            category: "EXECUTIVE_ROOM",
            beds: 1,
            bedType: "KING",
            guestPerRoom: 2,
          },
          price: {
            currency: "THB",
            base: "",
            total: "1005.48",
          },
          paymentPolicy: {
            type: "guarantee",
            amount: null,
            deadline: null,
          },
          cancellationPolicy: [
            {
              type: "",
              amount: "",
              numberOfNights: 0,
              percentage: "",
              deadline: "2023-12-25T00:00:00+07:00",
            },
          ],
          checkInAt: null,
          checkOutAt: null,
        },
      ],
    },
  ],
};

export const defaultCardInfo: CardInformation = {
  cardNumber: "",
  cardHolderName: "",
  expiryDate: dayjs(),
  securityCode: "",
  vendorCode: "",
};
const HotelBookingPage: FC = () => {
  const navigate = useNavigate();
  let { offerId, tripId } = useParams();

  const [guests, setGuests] = useState<Guest[]>([sampleGuest]);
  const [stepStatus, setStepStatus] = useState(TravellerInfoStep);
  const [step, setStep] = useState<number>(0);
  const [searchHotelData, setSearchHotelData] = useState<HotelOffer>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDoneLoading, setIsDoneLoading] = useState(false);
  const [cardInformation, setCardInformation] =
    useState<CardInformation>(defaultCardInfo);
  const [AvailableWallet, setAvailableWallet] = useState<RequestableWallet[]>(
    []
  );
  const [selectedWallet, setSelectedWallet] = useState<string>("credit");

  const addGuestHandler = (): void => {
    if (searchHotelData) {
      if (
        guests.length <
        searchHotelData?.offers[0].room.guestPerRoom *
          parseInt(searchHotelData?.offers[0].roomQuantity, 10)
      ) {
        setGuests((prev) => [...prev, sampleGuest]);
      } else {
        message.error("Cannot add more guests");
      }
    }
  };

  const handleRemoveGuest = (target: number) => {
    setGuests((prev) => prev.filter((_, idx: number) => idx != target));
  };

  const updateCardInfoHandler = (
    cardInfoKey: keyof CardInformation,
    cardInfoValue: any
  ) => {
    setCardInformation((prev) => {
      return { ...prev, [cardInfoKey]: cardInfoValue };
    });
  };

  const handleUpdateGuest = (
    targetId: number,
    guestKey: keyof Guest,
    guestValue: any
  ) => {
    setGuests((prev) =>
      prev.map((guest: Guest, id: number) => {
        if (targetId != id) return guest;
        else {
          return { ...guest, [guestKey]: guestValue };
        }
      })
    );
  };

  const nextStepHandler = (value: any) => {
    if (tripId) {
      setStepStatus(ConfirmInfoStep);
      setStep(2);
    } else {
      setStepStatus((prev) => {
        switch (prev) {
          case TravellerInfoStep:
            return PaymentInfoStep;
          default:
            return ConfirmInfoStep;
        }
      });
      setStep((prev) => prev + 1);
    }
  };

  const backStepHandler = () => {
    setStepStatus((prev) => {
      switch (prev) {
        case ConfirmInfoStep:
          return PaymentInfoStep;
        default:
          return TravellerInfoStep;
      }
    });
    setStep((prev) => prev - 1);
  };

  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };

  const handleBookNowClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (tripId && offerId) {
      try {
        openModal();
        const response = await apiClient.addBookingToTrip({
          tripId: tripId,
          hotels: [
            {
              offerID: offerId,
              guests: guests,
            },
          ],
        });
        closeModal();
        setIsDoneLoading(true);
        setTimeout(() => {
          setIsDoneLoading(false);
          navigate("/tripgen-calendar");
        }, 3000);
      } catch (error) {
        // Handle error cases
        console.error("Error during add booking to trip:", error);
        message.warning("Error during booking. Please try again.");
      } finally {
        closeModal();
      }
    } else if (offerId) {
      try {
        openModal();
        if (selectedWallet == "credit") {
          const response = await apiClient.bookHotel({
            guests: guests,
            offerId: offerId,
            payment: {
              method: "creditCard",
              creditCard: {
                cardHolder: cardInformation.cardHolderName,
                cardNumber: cardInformation.cardNumber,
                expiryDate: cardInformation.expiryDate?.toString(),
                vendorCode: cardInformation.vendorCode,
              },
            },
          });
        } else {
          const response = await apiClient.bookHotel({
            guests: guests,
            offerId: offerId,
            payment: {
              method: "wallet",
              walletId: selectedWallet,
            },
          });
        }
      } catch (error) {
        // Handle error cases
        message.error("Error during booking. Please try again.");
      } finally {
        closeModal();
        setIsDoneLoading(true);
        setTimeout(() => {
          setIsDoneLoading(false);
          navigate("/hotel");
        }, 3000);
      }
    }
  };

  const handleSelectWallet = (wallet: string) => {
    setSelectedWallet(wallet);
  };

  const getRequstableWallet = async () => {
    try {
      const response = await apiClient.getRequestableWallet();
      setAvailableWallet(response.data.wallets);
    } catch {
      message.error("Cannot find wallet");
    }
  };

  useEffect(() => {
    getRequstableWallet();
    async function fetchHotelOffer() {
      if (offerId) {
        try {
          openModal();
          let response = await apiClient.getHotelOffer(offerId);
          setSearchHotelData(response.data.data);
        } catch {
          message.error("Hotel not found");
        } finally {
          closeModal();
        }
      }
    }
    fetchHotelOffer();
  }, []);

  return (
    <div className="hotel-booking-page">
      <CurrentState stepStatus={stepStatus} infoOwner="Guest" />
      <Form
        className="hotel-booking-page-content"
        onFinish={nextStepHandler}
        layout="inline"
      >
        <div className="flight-booking-page-left">
          {step == 0 && (
            <GuestInfo
              guests={guests}
              addGuestHandler={addGuestHandler}
              removeGuestHandler={handleRemoveGuest}
              updateGuestHandler={handleUpdateGuest}
            />
          )}
          {step == 1 && (
            <div>
              <PaymentForm
                cardInfo={cardInformation}
                updateCardInfoHandler={updateCardInfoHandler}
                availableWallet={AvailableWallet}
                updatePaymentMethod={handleSelectWallet}
                PaymentMethod={selectedWallet}
              />
            </div>
          )}
          {step == 2 && (
            <div>
              <HotelConfirmInfo
                guests={guests}
                cardInfo={cardInformation}
                paymentMethod={selectedWallet}
                walletInfo={
                  AvailableWallet.filter(
                    (wallet) => wallet.id == selectedWallet
                  )[0]
                }
              />
            </div>
          )}
        </div>
        <div className="flight-booking-page-right">
          {searchHotelData ? (
            <HotelInformationComponent info={searchHotelData} />
          ) : (
            <ModalLoading isOpen={isOpenModal}></ModalLoading>
          )}

          <div className="flight-booking-page-step-handler">
            {step > 0 && (
              <button
                className="flight-booking-page-btn back-btn"
                onClick={backStepHandler}
                type="button"
              >
                Back
              </button>
            )}
            {step < 2 && (
              <Form.Item>
                <Button
                  className="flight-booking-page-btn next-btn"
                  htmlType="submit"
                >
                  Next
                </Button>
              </Form.Item>
            )}
            {step == 2 && (
              <div>
                <ModalLoading isOpen={isOpenModal}></ModalLoading>
                <ModalLoadingDone
                  isOpen={isDoneLoading}
                  text={"Booking Hotel Complete Successfully"}
                ></ModalLoadingDone>
                <Button
                  className="flight-booking-page-btn next-btn"
                  onClick={handleBookNowClick}
                >
                  Book now
                </Button>
              </div>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default HotelBookingPage;
