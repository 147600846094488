import TripGenSummary from "../../components/TripgenSummary/TripGenSummary";
import "./TripgenPage.css";
import TripgenSearchBar from "../../components/TripgenComponent/TripgenSearchBar";
import { ContactPassenger, Passenger, SearchFlightOffer, SearchFlightRequest } from "../../types/flight";
import "./TripgenPage.css";
import { useEffect, useState } from "react";
import { TripGenPhase } from "../../types/enum/tripgenPhase";
import FilterFlight from "../../components/FilterFlight/FilterFlight";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import FlightItem from "../../components/FlightItem/FlightItem";
import FlightDetail from "../../components/FlightDetail/FlightDetail";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import { Guest, HotelOffer, SearchHotelRequest, SearchHotelResponse } from "../../types/hotel";
import FilterHotel from "../../components/FilterHotel/FilterHotel";
import HotelOfferItem from "../../components/HotelOfferItem/HotelOfferItem";
import { useTripgenContext } from "../../context/TripgenContext";
import ConfirmModalComponent from "../../components/ConfirmModalComponent/ConfirmModalComponent";
import { Button, Form, message } from "antd";
import { apiClient } from "../../utils/client";
import TravellerInfo from "../../components/TravellerInfo/TravellerInfo";
import { defaultContactInfo, defaultPassenger } from "../FlightBookingPage/FlightBookingPage";
import FlightInformationComponent from "../../components/FlightInformationComponent/FlightInformationComponent";
import GuestInfo from "../../components/GuestInfo/GuestInfo";
import { defaultCardInfo, sampleGuest } from "../HotelBookingPage/HotelBookingPage";
import HotelInformationComponent from "../../components/HotelInformationComponent/HotelInformationComponent";
import { RequestableWallet } from "../../types/enum/wallet";
import PaymentForm, { CardInformation } from "../../components/PaymentForm/PaymentForm";
import ModalLoadingDone from "../../components/ModalLoading/ModalLoadingDone";
import ConfirmInfo from "../../components/ConfirmInfo/ConfirmInfo";
import HotelConfirmInfo from "../../components/HotelConfirmInfo/HotelConfirmInfo";
import TripgenFlightInfo from "../../components/TripgenComponent/TripgenFlightInfo/TripgenFlightInfo";
import TripgenHotelInfo from "../../components/TripgenComponent/TripgenHotelInfo/TripgenHotelInfo";
import { useNavigate } from "react-router-dom";
import TripStep from "../../components/TripStep/TripStep";
import { StepStatus } from "../../types/enum/stepStatus";
import { PaymentMethod, VendorCode } from "../../types/tripgen";

const TripgenPage = () => {
  const {
    flightSearchRequests,
    flightSearchResults,
    setFlightSearchResults,
    filteredFlights,
    setFilteredFlights,
    selectedBookingFlight,
    selectFlightIndex,
    isFilterFlightShow,
    setIsFilterFlightShow,
    hotelSearchRequests,
    hotelSearchResult,
    setHotelSearchResult,
    filteredHotels,
    setFilteredHotels,
    selectedBookingHotel,
    selectHotelIndex,
    isFilterHotelShow,
    setIsFilterHotelShow,
    tripgenPhase,
    tripgenFlightStep,
    tripgenHotelStep,
    selectFlightItemHandler,
    handleSelectedBookingFlight,
    selectHotelItemHandler,
    forwardStepHandler,
    handleSelectedBookingHotel,
    infoFlightStep,
    infoHotelStep,
    nextInfoStepHandler,
    backInfoStepHandler,
    backPaymentStepHandler,
    nextPaymentStepHandler,
    nameTrip,
    resetButtonHandler,
    selectedBookingFlightOffer,
    setSelectedBookingFlightOffer,
    selectedBookingHotelOffer,
    setSelectedBookingHotelOffer,
  } = useTripgenContext();

  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [PopupConfirm, setPopupConfirm] = useState<boolean>(false);
  const [isDoneLoading, setIsDoneLoading] = useState(false);
  // info flight section
  const [passengers, setPassengers] = useState<Passenger[][]>([[defaultPassenger]]);
  const [contactInfo, setContactInfo] = useState<ContactPassenger[]>([defaultContactInfo]);
  // info hotel section
  const [guests, setGuests] = useState<Guest[][]>([[sampleGuest]]);
  // wallet section
  const [availableWallet, setAvailableWallet] = useState<RequestableWallet[]>([]);
  const [selectedWallet, setSelectedWallet] = useState<string>("credit");
  const [cardInformation, setCardInformation] = useState<CardInformation>(defaultCardInfo);

  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };

  const searchFlightForTripGen = async (currentFlight: number) => {
    try {
      openModal();

      if (
        flightSearchRequests[currentFlight].originLocationCode === "" ||
        flightSearchRequests[currentFlight].destinationLocationCode === ""
      ) {
        message.error("Please provide all these information: Origin, Destination, and Departure date.");
        return;
      }

      const response = await apiClient.searchFlights(flightSearchRequests[currentFlight]);
      const flightResult = response.data;
      setFlightSearchResults(flightResult.data);
      setFilteredFlights(flightResult.data);
    } catch (error) {
      message.error("Error while searching flight");
    } finally {
      closeModal();
    }
  };

  const searchHotelForTripGen = async (currentHotel: number) => {
    try {
      openModal();

      if (hotelSearchRequests[currentHotel].cityCode === "") {
        message.error("Please provide all these information: Location, Check-in date, Check-out date.");
        return;
      }
      const response = await apiClient.searchHotels(hotelSearchRequests[currentHotel]);
      const hotelResult = response.data;
      setHotelSearchResult(hotelResult.data);
      setFilteredHotels(hotelResult.data);
    } catch (error) {
      message.error("Error while searching hotel");
    } finally {
      closeModal();
    }
  };

  const confirmFlightHandler = (flightRequest: SearchFlightOffer) => {
    // openModal();
    handleSelectedBookingFlight(flightRequest); // store selected booking
    // closeModal();
    setPopupConfirm(false);
    forwardStepHandler(); // change step of flight/hotel or go to next phase
  };

  const confirmHotelHandler = (hotelRequest: HotelOffer) => {
    openModal();
    // store selected booking
    handleSelectedBookingHotel(hotelRequest);

    // change step of flight/hotel or go to next phase
    forwardStepHandler();

    // search flight/hotel
    // -> useEffect will handle this
    closeModal();
    setPopupConfirm(false);
  };

  const addPassengerHandler = () => {
    if (
      flightSearchRequests[infoFlightStep].travellers[0].travellerAmount +
        (flightSearchRequests[infoFlightStep].travellers[1]
          ? flightSearchRequests[infoFlightStep].travellers[1].travellerAmount
          : 0) >
      passengers[infoFlightStep].length
    ) {
      setPassengers((prev) => {
        const newPassengerList = [...prev];
        newPassengerList[infoFlightStep] = [...(newPassengerList[infoFlightStep] || []), defaultPassenger];
        return newPassengerList;
      });
    } else {
      message.error("Cannot add more passenger for this flight");
    }
  };

  const removePassengerHandler = (target: number) => {
    setPassengers((prev) =>
      prev.map((stepPassengers: Passenger[], stepIndex: number) => {
        if (infoFlightStep !== stepIndex) return stepPassengers;
        return stepPassengers.filter((_, idx: number) => idx !== target);
      })
    );
  };

  const updatePassengerHandler = (targetId: number, passengerKey: keyof Passenger, passengerValue: any) => {
    setPassengers((prev) =>
      prev.map((stepPassengers: Passenger[], stepIndex: number) => {
        if (infoFlightStep !== stepIndex) return stepPassengers;
        return stepPassengers.map((passenger: Passenger, id: number) => {
          if (targetId !== id) return passenger;
          return { ...passenger, [passengerKey]: passengerValue };
        });
      })
    );
  };

  const updateContactInfoHandler = (contactInfoKey: keyof ContactPassenger, contactInfoValue: any) => {
    setContactInfo((prev) => {
      const updatedContactInfo = [...prev];
      if (infoFlightStep >= 0 && infoFlightStep < updatedContactInfo.length) {
        updatedContactInfo[infoFlightStep] = {
          ...updatedContactInfo[infoFlightStep],
          [contactInfoKey]: contactInfoValue,
        };
      }
      return updatedContactInfo;
    });
  };

  const infoInputValidator = (): boolean => {
    if (
      infoFlightStep <= selectedBookingFlight.length - 1 &&
      passengers[infoFlightStep].length <
        flightSearchRequests[infoFlightStep].travellers[0].travellerAmount +
          (flightSearchRequests[infoFlightStep].travellers[1]
            ? flightSearchRequests[infoFlightStep].travellers[1].travellerAmount
            : 0)
    ) {
      return true;
    } else if (
      infoHotelStep <= selectedBookingHotel.length - 1 &&
      infoFlightStep > selectedBookingFlight.length - 1 &&
      guests[infoHotelStep]
    ) {
      if (guests[infoHotelStep].length < hotelSearchRequests[infoHotelStep].adult) {
        return true;
      }
    }
    return false;
  };

  const handleUpdateGuest = (targetId: number, guestKey: keyof Guest, guestValue: any) => {
    setGuests((prev) =>
      prev.map((stepGuests: Guest[], stepIndex: number) => {
        if (infoHotelStep !== stepIndex) return stepGuests;
        return stepGuests.map((guest: Guest, id: number) => {
          if (targetId !== id) return guest;
          return { ...guest, [guestKey]: guestValue };
        });
      })
    );
  };

  const addGuestHandler = (): void => {
    if (guests[infoHotelStep].length < hotelSearchRequests[infoHotelStep].adult) {
      setGuests((prev) => {
        const newGuestList = [...prev];
        newGuestList[infoHotelStep] = [...(newGuestList[infoHotelStep] || []), sampleGuest];
        return newGuestList;
      });
    } else {
      message.error("Cannot add more guests");
    }
  };
  const handleRemoveGuest = (target: number) => {
    setGuests((prev) =>
      prev.map((stepGuests: Guest[], stepIndex: number) => {
        if (infoHotelStep !== stepIndex) return stepGuests;
        return stepGuests.filter((_, idx: number) => idx !== target);
      })
    );
  };

  const getRequstableWallet = async () => {
    try {
      const response = await apiClient.getRequestableWallet();
      setAvailableWallet(response.data.wallets);
    } catch {
      message.error("Cannot find wallet");
    }
  };
  const updateCardInfoHandler = (cardInfoKey: keyof CardInformation, cardInfoValue: any) => {
    setCardInformation((prev) => {
      return { ...prev, [cardInfoKey]: cardInfoValue };
    });
  };
  const handleSelectWallet = (wallet: string) => {
    setSelectedWallet(wallet);
  };

  const handleTripgenBookNowClick = async () => {
    try {
      openModal();
      if (!contactInfo || !passengers || !selectedBookingFlightOffer) return;
      const response = await apiClient.createTrip({
        name: nameTrip,
        flights: selectedBookingFlightOffer.map((flight, idx) => ({
          itineraries: flight.flightItineraries.map((itinerary) => ({
            airlineName: itinerary.airlineName,
            segments: itinerary.flightSegments.map((segment) => ({
              airlineName: segment.airlineName,
              arrival: {
                at: segment.arrivalAt,
                iataCode: segment.arrivalIataCode,
              },
              carrierCode: segment.carrierCode,
              departure: {
                at: segment.departureAt,
                iataCode: segment.departureIataCode,
              },
              id: segment.id,
              number: segment.flightNumber,
            })),
          })),
          travelerPricings: [
            {
              fareDetailsBySegment: flight.travelerPricings[0].fareDetailsBySegment,
              fareOption: flight.travelerPricings[0].fareOption,
              travelerId: flight.travelerPricings[0].travelerId,
              travelerType: flight.travelerPricings[0].travelerType,
            },
          ],
          validatingAirlineCodes: flight.validatingAirlineCodes,
          passengers: passengers[idx],
          contactPerson: {
            firstName: contactInfo[idx].firstName,
            lastname: contactInfo[idx].surName,
            middleName: contactInfo[idx].middleName,
            phoneNumber: contactInfo[idx].phoneNumber?.phoneNumber
              ? String(
                  "+" +
                    (contactInfo[idx].phoneNumber?.countryCode ?? "") +
                    (contactInfo[idx].phoneNumber?.areaCode ?? "") +
                    (contactInfo[idx].phoneNumber?.phoneNumber ?? "")
                )
              : "",
            email: contactInfo[idx].email,
          },
        })),
        hotels: selectedBookingHotelOffer.map((hotel, idx) => ({
          offerID: hotel.offers[0].id,
          guests: guests[idx],
        })),
        payment: {
          method: selectedWallet === "credit" ? PaymentMethod.CREDIT_CARD : PaymentMethod.WALLET,
          creditCard:
            selectedWallet === "credit"
              ? {
                  vendorCode: cardInformation.vendorCode == "VI" ? VendorCode.VISA : VendorCode.MASTERCARD,
                  cardNumber: cardInformation.cardNumber,
                  cardHolder: cardInformation.cardHolderName,
                  expiryDate: cardInformation.expiryDate?.format("YYYY-MM").toString(),
                }
              : undefined,
          walletId: selectedWallet === "wallet" ? selectedWallet : undefined,
        },
      });
    } catch {
      message.error("Error during booking. Please try again.");
    } finally {
      closeModal();
      setIsDoneLoading(true);
      setTimeout(() => {
        setIsDoneLoading(false);
        navigate("/tripgen-calendar");
        resetButtonHandler();
      }, 3000);
    }
  };

  const checkStatusFlight = (idx: number) => {
    if (idx < selectedBookingFlight.length) return StepStatus.COMPLETE;
    if (idx == selectedBookingFlight.length) return StepStatus.INPROGRESS;
    return StepStatus.PENDING;
  };

  const checkStatusHotel = (idx: number) => {
    if (idx < selectedBookingHotel.length) return StepStatus.COMPLETE;
    if (idx == selectedBookingHotel.length && selectedBookingFlight.length === flightSearchRequests.length)
      return StepStatus.INPROGRESS;
    return StepStatus.PENDING;
  };

  const displayStepComponent = () => {
    return (
      (tripgenPhase === TripGenPhase.FLIGHT_PHASE ||
        tripgenPhase === TripGenPhase.HOTEL_PHASE ||
        tripgenPhase === TripGenPhase.SUMMARY_PHASE) && (
        <div className="TripgenPage-stepcomponent">
          {flightSearchRequests.map((flight, index) => (
            <TripStep
              phase={"flight"}
              status={checkStatusFlight(index)}
              origin={flight.originLocationCode}
              destination={flight.destinationLocationCode}
            />
          ))}
          {hotelSearchRequests.map((hotel, index) => (
            <TripStep phase={"hotel"} status={checkStatusHotel(index)} location={hotel.cityCode} />
          ))}

          {/* confirm step */}
          <TripStep
            phase={""}
            status={tripgenPhase === TripGenPhase.SUMMARY_PHASE ? StepStatus.INPROGRESS : StepStatus.PENDING}
          />
        </div>
      )
    );
  };

  const getHotelOffers = async () => {
    try {
      openModal();
      const offers: HotelOffer[] = [];
      for (const hotel of selectedBookingHotel) {
        const response = await apiClient.getHotelOffer(hotel.offers[0].id);
        offers.push(response.data.data);
      }
      setSelectedBookingHotelOffer(offers);
    } catch (error) {
      message.error("Error while getting hotel offers");
    } finally {
      closeModal();
    }
  };

  const getFlightOffers = async () => {
    try {
      openModal();
      const offers: SearchFlightOffer[] = [];
      for (const flight of selectedBookingFlight) {
        const response = await apiClient.getFlightOffer({
          itineraries: flight.flightItineraries.map((itinerary) => ({
            airlineName: itinerary.airlineName,
            segments: itinerary.flightSegments.map((segment) => ({
              airlineName: segment.airlineName,
              arrival: {
                at: segment.arrivalAt,
                iataCode: segment.arrivalIataCode,
              },
              carrierCode: segment.carrierCode,
              departure: {
                at: segment.departureAt,
                iataCode: segment.departureIataCode,
              },
              id: segment.id,
              number: segment.flightNumber,
            })),
          })),
          travelerPricings: [
            {
              fareDetailsBySegment: flight.travelerPricings[0].fareDetailsBySegment,
              fareOption: flight.travelerPricings[0].fareOption,
              travelerId: flight.travelerPricings[0].travelerId,
              travelerType: flight.travelerPricings[0].travelerType,
            },
          ],
          validatingAirlineCodes: flight.validatingAirlineCodes,
        });
        offers.push(response.data.data[0]);
      }
      setSelectedBookingFlightOffer(offers);
    } catch (error) {
      message.error("Error while getting flight offers");
    } finally {
      closeModal();
    }
  };

  useEffect(() => {
    if (tripgenPhase === TripGenPhase.FLIGHT_PHASE) {
      searchFlightForTripGen(tripgenFlightStep);
    } else if (tripgenPhase === TripGenPhase.HOTEL_PHASE) {
      searchHotelForTripGen(tripgenHotelStep);
    }
  }, [tripgenFlightStep, tripgenHotelStep]);

  useEffect(() => {
    // get flight offers
    getFlightOffers();

    // set initial passengers and contact info
    const initialPassengers: Passenger[][] = Array.from({ length: selectedBookingFlight.length }, () => [
      defaultPassenger,
    ]);
    const initialContactInfo: ContactPassenger[] = Array.from(
      { length: selectedBookingFlight.length },
      () => defaultContactInfo
    );
    setPassengers(initialPassengers);
    setContactInfo(initialContactInfo);
  }, [selectedBookingFlight]);

  useEffect(() => {
    // get hotel offers
    getHotelOffers();
    // set initial guests
    const initialGuest: Guest[][] = Array.from({ length: selectedBookingHotel.length }, () => [sampleGuest]);
    setGuests(initialGuest);
  }, [selectedBookingHotel]);

  useEffect(() => {
    if (tripgenPhase === TripGenPhase.PAYMENT_PHASE) {
      getRequstableWallet();
    }
  }, [tripgenPhase]);

  return (
    <>
      <ModalLoading isOpen={isOpenModal} />
      <ModalLoadingDone isOpen={isDoneLoading} text={"Booking Complete Successfully"}></ModalLoadingDone>

      <div>
        {/* display search bar */}
        <TripgenSearchBar
          tripgenPhase={tripgenPhase}
          flightSearchRequests={flightSearchRequests}
          hotelSearchRequests={hotelSearchRequests}
          searchFlightForTripGen={searchFlightForTripGen}
          searchHotelForTripGen={searchHotelForTripGen}
        ></TripgenSearchBar>

        {tripgenPhase === TripGenPhase.FLIGHT_PHASE && (
          <div className="TripgenPage-resultAndFilter">
            {isFilterFlightShow ? (
              <div className="TripgenPage-filterFlightSection">
                <FilterFlight dataFlight={flightSearchResults} setFilter={setFilteredFlights} />
                <div
                  className="TripgenPage-buttonToShowFilter"
                  onClick={() => {
                    setIsFilterFlightShow((prev) => !prev);
                  }}
                >
                  <FaLessThan />
                </div>
              </div>
            ) : (
              <div
                className="TripgenPage-buttonToShowFilter"
                onClick={() => {
                  setIsFilterFlightShow((prev) => !prev);
                }}
              >
                <FaGreaterThan />
              </div>
            )}
            <div className="TripgenPage-resultAndDetail">
              {displayStepComponent()}
              <div style={{ display: "flex", flexDirection: "row", gap: "50px", marginLeft: "50px" }}>
                <div className="TripgenPage-flightItemContainer">
                  <div className="TripgenPage-displayTotalText">
                    result ({filteredFlights?.length ? filteredFlights?.length : 0} flights)
                  </div>
                  {filteredFlights &&
                    filteredFlights.length != 0 &&
                    filteredFlights.map((flight: SearchFlightOffer, idx: number) => (
                      <div key={idx} onClick={() => selectFlightItemHandler(idx)}>
                        <FlightItem
                          data={flight}
                          isSelect={selectFlightIndex == idx}
                          onSelectHandler={() => {
                            setPopupConfirm(true);
                          }}
                        />
                      </div>
                    ))}
                </div>

                <div className="TripgenPage-flightDetailContainer">
                  {selectFlightIndex != -1 && flightSearchResults && (
                    <FlightDetail data={flightSearchResults[selectFlightIndex]} />
                  )}
                </div>
              </div>
            </div>

            {PopupConfirm && (
              <ConfirmModalComponent
                flightOffer={filteredFlights[selectFlightIndex]}
                onConfirm={() => confirmFlightHandler(filteredFlights[selectFlightIndex])}
                onCancel={() => {
                  setPopupConfirm(false);
                }}
              ></ConfirmModalComponent>
            )}
          </div>
        )}

        {tripgenPhase === TripGenPhase.HOTEL_PHASE && (
          <>
            <div className="TripgenPage-searchHotelContainer">
              {isFilterHotelShow ? (
                <div className="TripgenPage-filterHotelSection">
                  <FilterHotel dataHotel={hotelSearchResult} setFilter={setFilteredHotels} />
                  <div
                    className="TripgenPage-buttonToShowFilter"
                    onClick={() => {
                      setIsFilterHotelShow((prev) => !prev);
                    }}
                  >
                    <FaLessThan />
                  </div>
                </div>
              ) : (
                <div
                  className="TripgenPage-buttonToShowFilter"
                  onClick={() => {
                    setIsFilterHotelShow((prev) => !prev);
                  }}
                >
                  <FaGreaterThan />
                </div>
              )}

              <div className="TripgenPage-resultAndDetail">
                {displayStepComponent()}
                <div className="TripgenPage-displayTotalText">
                  result ({filteredHotels?.length ? filteredHotels?.length : 0} Hotels)
                  <div className="TripgenPage-noTtemText">{!hotelSearchResult && "No Hotel Items"}</div>
                </div>
                <div className="TripgenPage-hoteltItemContainer">
                  {filteredHotels &&
                    filteredHotels.length != 0 &&
                    filteredHotels.map((hotel: HotelOffer, idx: number) => (
                      <div key={idx} onClick={() => selectHotelItemHandler(idx)}>
                        <HotelOfferItem
                          hotelOffer={hotel}
                          onClick={() => {
                            setPopupConfirm(true);
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>

            {PopupConfirm && (
              <ConfirmModalComponent
                hotelOffer={filteredHotels[selectHotelIndex]}
                onConfirm={() => confirmHotelHandler(filteredHotels[selectHotelIndex])}
                onCancel={() => {
                  setPopupConfirm(false);
                }}
              ></ConfirmModalComponent>
            )}
          </>
        )}

        {tripgenPhase === TripGenPhase.SUMMARY_PHASE && (
          <>
            {displayStepComponent()}
            <TripGenSummary flightSelected={selectedBookingFlight} hotelSelected={selectedBookingHotel} />
          </>
        )}

        {tripgenPhase === TripGenPhase.INFO_PHASE && (
          <div className="TripgenPage-info-content">
            <div className="flight-booking-page-left">
              {" "}
              {passengers.map(
                (passengerArray, index) =>
                  infoFlightStep <= selectedBookingFlight.length - 1 &&
                  infoFlightStep == index && (
                    <Form
                      className="TripgenFligt-form"
                      name={`${index}-Tripgen-flight-info`}
                      onFinish={nextInfoStepHandler}
                      layout="inline"
                    >
                      <TravellerInfo
                        key={index}
                        passengers={passengerArray}
                        contactInfo={contactInfo[index]}
                        addPassengerHandler={addPassengerHandler}
                        removePassengerHandler={removePassengerHandler}
                        updatePassengerHandler={updatePassengerHandler}
                        updateContactInfoHandler={updateContactInfoHandler}
                      />
                      <div>
                        <div className="TripgenPage-step-handler">
                          <button
                            className="flight-booking-page-btn back-btn"
                            onClick={backInfoStepHandler}
                            type="button"
                          >
                            Back
                          </button>

                          <Button
                            className="flight-booking-page-btn next-btn"
                            htmlType="submit"
                            disabled={infoInputValidator()}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )
              )}
              {guests.map(
                (guestArray, index) =>
                  infoHotelStep <= selectedBookingHotel.length - 1 &&
                  infoFlightStep > selectedBookingFlight.length - 1 &&
                  infoHotelStep === index && (
                    <Form name={`${index}-Tripgen-hotel-info`} onFinish={nextInfoStepHandler} layout="inline">
                      <GuestInfo
                        key={index}
                        guests={guestArray}
                        addGuestHandler={addGuestHandler}
                        removeGuestHandler={handleRemoveGuest}
                        updateGuestHandler={handleUpdateGuest}
                      />
                      <div>
                        <div className="TripgenPage-step-handler">
                          <button
                            className="flight-booking-page-btn back-btn"
                            onClick={backInfoStepHandler}
                            type="button"
                          >
                            Back
                          </button>

                          <Button
                            className="flight-booking-page-btn next-btn"
                            htmlType="submit"
                            disabled={infoInputValidator()}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )
              )}
            </div>

            <div className="flight-booking-page-right">
              {selectedBookingFlightOffer &&
                selectedBookingFlight.map(
                  (flight, index) =>
                    index === infoFlightStep &&
                    index <= selectedBookingFlight.length - 1 && (
                      <FlightInformationComponent key={index} info={selectedBookingFlightOffer[infoFlightStep]} />
                    )
                )}

              {selectedBookingHotelOffer &&
                selectedBookingHotel.map(
                  (hotel, index) =>
                    infoHotelStep <= selectedBookingHotel.length - 1 &&
                    infoFlightStep > selectedBookingFlight.length - 1 &&
                    index === infoHotelStep && (
                      <HotelInformationComponent key={index} info={selectedBookingHotelOffer[infoHotelStep]} />
                    )
                )}
            </div>
          </div>
        )}

        {tripgenPhase === TripGenPhase.PAYMENT_PHASE && (
          <Form className="TripgenPage-payment-content" onFinish={nextPaymentStepHandler}>
            <PaymentForm
              cardInfo={cardInformation}
              updateCardInfoHandler={updateCardInfoHandler}
              availableWallet={availableWallet}
              updatePaymentMethod={handleSelectWallet}
              PaymentMethod={selectedWallet}
            />

            <div className="TripgenPage-payment-buttonPanel">
              <button className="flight-booking-page-btn back-btn" onClick={backPaymentStepHandler} type="button">
                Back
              </button>

              <Button className="flight-booking-page-btn next-btn" htmlType="submit">
                Next
              </Button>
            </div>
          </Form>
        )}

        {tripgenPhase === TripGenPhase.CONFIRM_PHASE && (
          <div className="Tripgen-confirm-page">
            <div style={{ fontWeight: "bold", fontSize: "24px", marginTop: "10px" }}> Trip Confirmation </div>
            <div className="Tripgen-confirm-subpage">
              <div className="Tripgen-Confirm-Container">
                <div style={{ fontWeight: "bold", fontSize: "18px" }}>Flight Booking Detail </div>
                {passengers.map((passengerList, idx) => (
                  <div>
                    <div className="Tripgen-FlightHotel-title"> Flight : {idx + 1}</div>
                    <TripgenFlightInfo flight={selectedBookingFlightOffer[idx]} index={idx}></TripgenFlightInfo>
                    <ConfirmInfo
                      key={idx}
                      passengers={passengerList}
                      contactInfo={contactInfo[idx]}
                      cardInfo={cardInformation}
                      paymentMethod={selectedWallet}
                      walletInfo={availableWallet.filter((wallet) => wallet.id == selectedWallet)[0]}
                      fitContent={true}
                    />
                  </div>
                ))}
              </div>
              <div className="Tripgen-Confirm-Container">
                <div style={{ fontWeight: "bold", fontSize: "18px" }}>Hotel Booking Detail </div>
                {guests.map((guestList, idx) => (
                  <div>
                    <div className="Tripgen-FlightHotel-title"> Hotel : {idx + 1}</div>
                    <TripgenHotelInfo hotel={selectedBookingHotelOffer[idx]} index={idx}></TripgenHotelInfo>
                    <HotelConfirmInfo
                      guests={guestList}
                      cardInfo={cardInformation}
                      paymentMethod={selectedWallet}
                      walletInfo={availableWallet.filter((wallet) => wallet.id == selectedWallet)[0]}
                      fitContent={true}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="Tripgen-confirm-buttonPanel">
              <button className="flight-booking-page-btn back-btn" onClick={backPaymentStepHandler} type="button">
                Back
              </button>

              <ModalLoading isOpen={isOpenModal}></ModalLoading>
              <ModalLoadingDone isOpen={isDoneLoading} text={"Booking Complete Successfully"}></ModalLoadingDone>
              <Button className="flight-booking-page-btn next-btn" onClick={handleTripgenBookNowClick}>
                Book now
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TripgenPage;
