import { useNavigate, useParams } from "react-router-dom";
import HotelBookingDetail from "../../components/BookingDetailComponent/HotelBookingDetail";
import ParticipantDetail from "../../components/BookingDetailComponent/ParticipantDetail";
import Button from "../../components/Buttons/Buttons";
import PopupConfirmComponent from "../../components/PopupConfirmComponent/PopupConfirmComponent";
import {
  Guest,
  HotelBookingDetailRequest,
  HotelBookingDetailResponse,
  HotelOffer,
} from "../../types/hotel";
import { apiClient } from "../../utils/client";
import "./BookingManagementPage.css";
import React, { useEffect, useState } from "react";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import { message } from "antd";
import ModalLoadingDone from "../../components/ModalLoading/ModalLoadingDone";

const BookingManagementPage: React.FC = () => {
  // use param to get bookingId
  const { bookingId } = useParams<string>();
  const [hotelBookingRequestDetail, setHotelBookingRequestDetail] =
    useState<HotelBookingDetailResponse>();
  const [guestDetail, setGuestDetail] = useState<Guest[]>([]);
  const [displayPopup, setDisplayPopup] = useState(false);
  const [acceptOrReject, setAcceptOrReject] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [rejectReason, setRejectReason] = useState<string>("");
  const navigate = useNavigate();

  // get hotel booking detail from backend
  const getHotelBookingRequestDetail = async () => {
    try {
      if (bookingId) {
        openModal();
        const response = await apiClient.getHotelBookingRequestDetail({
          bookingId: bookingId,
        });
        setHotelBookingRequestDetail(response.data);
        setGuestDetail(response.data.guests);
      }
    } catch {
      message.error("Fail to get this booking request. Please try again.");
    } finally {
      closeModal();
    }
  };

  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };

  const sendEmailOnAccept = async () => {
    try {
      openModal();
      if (bookingId && hotelBookingRequestDetail) {
        const response = await apiClient.approveHotelBookingRequest({
          hotelBookingId: bookingId,
          offerId: hotelBookingRequestDetail?.currentOffer.offerId,
        });
      }
    } catch (error) {
      message.error("Fail to accept this request. Please try again.");
    } finally {
      closeModal();
      setDisplayPopup(false);
      navigate("/admin");
    }
  };

  const sendEmailOnReject = async () => {
    try {
      if (bookingId) {
        openModal();
        const response = await apiClient.rejectHotelBookingRequest({
          hotelBookingId: bookingId,
          note: rejectReason,
        });
      }
    } catch (error) {
      message.error("Fail to reject this request. Please try again.");
    } finally {
      closeModal();
      setDisplayPopup(false);
      navigate("/admin");
    }
  };

  const handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRejectReason(e.target.value);
  };

  // useEffect section
  useEffect(() => {
    getHotelBookingRequestDetail();
  }, []);

  return (
    <div className="BookingManagement-Page">
      <ModalLoading isOpen={isOpenModal} />
      {displayPopup ? (
        <div>
          <PopupConfirmComponent
            acceptOrReject={acceptOrReject}
            onCancel={() => {
              setDisplayPopup(false);
            }}
            onConfirm={() => {
              acceptOrReject ? sendEmailOnAccept() : sendEmailOnReject();
            }}
            handleReasonChange={handleReasonChange}
            rejectReason={rejectReason}
          />
        </div>
      ) : null}

      <div className="BookingManagementPage-header">Participants</div>
      <div className="BookingManagementPage-pariticipant">
        <ParticipantDetail participants={guestDetail} />
      </div>

      <div className="BookingManagementPage-header">Booking Detail</div>
      {hotelBookingRequestDetail && (
        <HotelBookingDetail
          info={hotelBookingRequestDetail}
        ></HotelBookingDetail>
      )}
      {hotelBookingRequestDetail?.status === "Pending" && (
        <div className="hotelBooking-buttonPanel">
          <Button
            message={"Reject"}
            bheight="35px"
            bwidth="170px"
            fontSize="20px"
            borderRadius="6px"
            onClick={() => {
              setAcceptOrReject(false);
              setDisplayPopup(true);
            }}
            bgColor="#D10000"
          />
          <Button
            message={"Accept"}
            bheight="35px"
            bwidth="170px"
            fontSize="20px"
            borderRadius="6px"
            onClick={() => {
              setAcceptOrReject(true);
              setDisplayPopup(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default BookingManagementPage;
