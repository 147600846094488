import React, { useEffect } from "react";
import "./FlightInformationComponent.css";
import { SearchFlightOffer } from "../../types/flight";

interface FlightInformationProps {
  info: SearchFlightOffer;
}
const FlightInformationComponent: React.FC<FlightInformationProps> = ({ info }) => {
  return (
    <div className="FlightInformation-container">
      {info && (
        <>
          <div className="FlightInformation-header">Flight Information</div>
          <div className="FlightInformation-typeOfFlight">
            {" "}
            Type of Flight :{" "}
            <div style={{ fontWeight: "bold", marginLeft: "10px" }}>
              {info.flightItineraries.length >= 2 ? "Round-Trip" : "One-Way"}
            </div>
          </div>
          <div className="FlightInformation-subHeader"> Depart</div>
          <div className="FlightInformation-3letter">
            <div className="normal-space-flightComponent">From-To:</div>{" "}
            {info.flightItineraries[0].flightSegments[0].departureIataCode} -- &gt;{" "}
            {info.flightItineraries[0].flightSegments[1].arrivalIataCode}
          </div>

          <div className="FlightInformation-stopAndDuration">
            <div className="normal-space-flightComponent">Number of stop:</div>{" "}
            {info.flightItineraries[0].flightSegments.length === 2
              ? "1 stop"
              : `${info.flightItineraries[0].flightSegments.length - 1} stops`}
            <div className="FlightInformation-durationHourAndMinute">
              <div className="normal-space-flightComponent">Duration:</div>
              {info.flightItineraries[0].durationHour} hour {info.flightItineraries[0].durationMinute} minutes
            </div>
          </div>

          <div className="FlightInformation-DateAndDuration">
            <div className="normal-space-flightComponent">Date:</div> {info.returnDate}
            <div className="normal-space-flightComponent" style={{ marginLeft: "10px" }}>
              Time:
            </div>{" "}
            {info.departureTime}
          </div>
          {info.flightItineraries.length >= 2 && (
            <>
              <div className="FlightInformation-line"></div>
              <div className="FlightInformation-subHeader" style={{ marginTop: "15px" }}>
                {" "}
                Return
              </div>

              <div className="FlightInformation-3letter">
                <div className="normal-space-flightComponent">From-To:</div>{" "}
                {info.flightItineraries[0].flightSegments[1].arrivalIataCode} -- &gt;{" "}
                {info.flightItineraries[0].flightSegments[0].departureIataCode}
              </div>

              <div className="FlightInformation-stopAndDuration">
                <div className="normal-space-flightComponent">Number of stop:</div>{" "}
                {info.flightItineraries[1].flightSegments.length === 2
                  ? "1 stop"
                  : `${info.flightItineraries[1].flightSegments.length - 1} stops`}
                <div className="FlightInformation-durationHourAndMinute">
                  <div className="normal-space-flightComponent">Duration:</div>
                  {info.flightItineraries[1].durationHour} hour {info.flightItineraries[1].durationMinute} minutes
                </div>
              </div>
              <div className="FlightInformation-DateAndDuration">
                <div className="normal-space-flightComponent">Date:</div> {info.returnDate}
                <div className="normal-space-flightComponent" style={{ marginLeft: "10px" }}>
                  Time:
                </div>{" "}
                {info.returnTime}
              </div>
            </>
          )}

          <div className="FlightInformation-pricePanel">
            Total
            <div className="FlightInformation-price">
              {" "}
              $ {info.totalPrice >= 1000 ? info.totalPrice.toLocaleString() : info.totalPrice} USD
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FlightInformationComponent;
