import React, { useEffect, useState } from "react";
import "./FilterFlight.css";
import { Radio, RadioChangeEvent, Slider } from "antd";
import TimeSlider from "../TimeSlider/TimeSlider";
import { SearchFlightOffer, SearchFlightResponse } from "../../types/flight";
import ModalLoading from "../ModalLoading/ModalLoading";
import airportOptions from "../../utils/airportOptions";

interface FilterFlightProps {
  dataFlight: SearchFlightOffer[];
  setFilter: (filteredData: SearchFlightOffer[]) => void;
}

const FilterFlight: React.FC<FilterFlightProps> = ({ dataFlight, setFilter }) => {
  // stop section
  const [stops, setStops] = useState(1);
  const onChangeStops = (e: RadioChangeEvent) => {
    setStops(e.target.value);
  };

  // price section
  const [priceLimit, setPriceLimit] = useState<number>(8000);
  const onChangePriceLimit = (price: number) => {
    setPriceLimit(price);
  };

  // airline section
  const [selectedAirlines, setSelectedAirlines] = useState<string[]>([]);
  const [displayAllAirlines, setDisplayAllAirlines] = useState(false);
  const initialDisplayCount = 4;
  const [airlineOptions, setAirlineOptions] = useState<string[]>([]);
  const getAllAirlinesFromSearch = () => {
    if (dataFlight.length > 0) {
      const uniqueAirlines = new Set(airlineOptions);

      dataFlight.map((flight) => {
        const airlines = flight.airlines;
        if (Array.isArray(airlines)) {
          airlines.forEach((airline) => {
            uniqueAirlines.add(airline);
          });
        }
      });
      const sortedAirlines = Array.from(uniqueAirlines).sort();

      setAirlineOptions(sortedAirlines);
      setSelectedAirlines(sortedAirlines);
    }
  };

  const handleCheckboxAirlinesChange = (airline: any) => {
    if (selectedAirlines.includes(airline)) {
      setSelectedAirlines(selectedAirlines.filter((a) => a !== airline));
    } else {
      setSelectedAirlines([...selectedAirlines, airline]);
    }
  };
  const handleSelectAllAirlines = () => {
    setSelectedAirlines(airlineOptions);
  };
  const handleClearAllAirlines = () => {
    setSelectedAirlines([]);
  };
  const handleShowAllAirlines = () => {
    setDisplayAllAirlines(true);
  };
  const handleShortenAirlines = () => {
    setDisplayAllAirlines(false);
  };
  const visibleAirlines = displayAllAirlines ? airlineOptions : airlineOptions.slice(0, initialDisplayCount);

  // airport layover section
  const [airportLayoverOptions, setAirportLayoverOptions] = useState<string[]>([]);
  const [selectedAirportLayover, setSelectedAirportLayover] = useState<string[]>([]);
  const [displayAllAirportLayover, setDisplayAllAirportLayover] = useState(false);
  const initialDisplayAirportLayover = 3;
  const handleShowAllAirportLayover = () => {
    setDisplayAllAirportLayover(true);
  };
  const handleShortenAirportLayover = () => {
    setDisplayAllAirportLayover(false);
  };
  const visibleAirportLayover = displayAllAirportLayover
    ? airportLayoverOptions
    : airportLayoverOptions.slice(0, initialDisplayAirportLayover);
  const handleCheckboxAirportLayoverChange = (airline: string) => {
    if (selectedAirportLayover.includes(airline)) {
      setSelectedAirportLayover(selectedAirportLayover.filter((a) => a !== airline));
    } else {
      setSelectedAirportLayover([...selectedAirportLayover, airline]);
    }
  };
  const getAllAirportLayoverFromSearch = () => {
    if (dataFlight.length > 0) {
      const uniqueAirport = new Set(airportLayoverOptions);

      dataFlight.map((flight) => {
        const airports = flight.layoverAirports;
        if (Array.isArray(airports)) {
          airports.forEach((airportCode) => {
            const airportName = mapAirportCodeToName(airportCode);
            uniqueAirport.add(airportName);
          });
        }
      });
      const sortedAirports = Array.from(uniqueAirport).sort();

      setAirportLayoverOptions(sortedAirports);
      setSelectedAirportLayover(sortedAirports);
    }
  };
  const mapAirportCodeToName = (code: string) => {
    const entry = airportOptions.find(([_, airportCode]) => airportCode === code);
    if (entry) {
      return entry[0];
    } else {
      return "Airport Not Found";
    }
  };
  const mapAirportNameToCode = (name: string) => {
    const entry = airportOptions.find(([airportName, _]) => airportName === name);
    if (entry) {
      return entry[1];
    } else {
      return "Airport Not Found";
    }
  };

  // duration section
  const [duration, setDuration] = useState(40);
  const onChangeDuration = (hour: number) => {
    setDuration(hour);
  };

  // flexible date section
  // const [flexibleDate, setFlexibleDate] = useState(0);
  // const onChangeFlexibleDate = (days: number) => {
  //   setFlexibleDate(days);
  // };

  // for Outbound Flight
  // ---------------------------Departure time----------------------------------------
  const [departureTimeStartDepart, setDepartureTimeStartDepart] = useState<any>("00:00");
  const onChangeDepartureTimeStartDepart = (time: string) => {
    setDepartureTimeStartDepart(time);
  };
  const [departureTimeEndDepart, setDepartureTimeEndDepart] = useState<any>("23:59");
  const onChangeDepartureTimeEndDepart = (time: string) => {
    setDepartureTimeEndDepart(time);
  };

  //--------------------------Arrival time-----------------------------------------
  const [arrivalTimeStartDepart, setArrivalTimeStartDepart] = useState<any>("00:00");
  const onChangeArrivalTimeStartDepart = (time: string) => {
    setArrivalTimeStartDepart(time);
  };
  const [arrivalTimeEndDepart, setArrivalTimeEndDepart] = useState<any>("23:59");
  const onChangeArrivalTimeEndDepart = (time: string) => {
    setArrivalTimeEndDepart(time);
  };
  //*************************** */

  // for Return Flight
  // ---------------------------Departure time----------------------------------------
  const [departureTimeStartReturn, setDepartureTimeStartReturn] = useState<any>("00:00");
  const onChangeDepartureTimeStartReturn = (time: string) => {
    setDepartureTimeStartReturn(time);
  };
  const [departureTimeEndReturn, setDepartureTimeEndReturn] = useState<any>("23:59");
  const onChangeDepartureTimeEndReturn = (time: string) => {
    setDepartureTimeEndReturn(time);
  };

  //--------------------------Arrival time-----------------------------------------
  const [arrivalTimeStartReturn, setArrivalTimeStartReturn] = useState<any>("00:00");
  const onChangeArrivalTimeStartReturn = (time: string) => {
    setArrivalTimeStartReturn(time);
  };
  const [arrivalTimeEndReturn, setArrivalTimeEndReturn] = useState<any>("23:59");
  const onChangeArrivalTimeEndReturn = (time: string) => {
    setArrivalTimeEndReturn(time);
  };

  // price option section
  const [priceOptions, setPriceOptions] = useState<PricingOption>({
    includedCheckedBagsOnly: true,
    refundableFare: false,
    noRestrictionFare: false,
    noPenaltyFare: false,
  });
  const handlePriceOptionChange = (optionName: keyof PricingOption) => {
    setPriceOptions({
      ...priceOptions,
      [optionName]: !priceOptions[optionName],
    });
  };
  type PricingOption = {
    includedCheckedBagsOnly: boolean | null;
    refundableFare: boolean | null;
    noRestrictionFare: boolean | null;
    noPenaltyFare: boolean | null;
  };

  // filter section
  const filterFlight = async () => {
    if (dataFlight) {
      openModal();
      let filtered = dataFlight;

      // Filter by number of stops
      if (stops == 2) {
        filtered = filtered.filter((flight) =>
          flight.flightItineraries.every((itinerary) => itinerary.flightSegments.length === 2)
        );
      } else if (stops == 3) {
        filtered = filtered.filter((flight) =>
          flight.flightItineraries.every((itinerary) => itinerary.flightSegments.length === 1)
        );
      }

      // Filter by price limit
      if (priceLimit >= 0) {
        filtered = filtered.filter((flight) => flight.totalPrice <= priceLimit);
      }

      // Filter by Airlines
      if (selectedAirlines.length >= 0) {
        filtered = filtered.filter((flight) => selectedAirlines.some((airline) => flight.airlines.includes(airline)));
      }

      // Filter by Airport layover
      if (selectedAirportLayover.length >= 0) {
        filtered = filtered.filter((flight) =>
          selectedAirportLayover.some((airport) => flight.layoverAirports.includes(mapAirportNameToCode(airport)))
        );
      }

      // outbound flight
      // Filter by Departure time
      if (departureTimeStartDepart || departureTimeEndDepart) {
        filtered = filtered.filter(
          (flight) =>
            flight.flightItineraries[0] &&
            flight.flightItineraries[0].departureTime >= departureTimeStartDepart &&
            flight.flightItineraries[0].departureTime <= departureTimeEndDepart
        );
      }
      // Filter by Arrival time
      if (arrivalTimeEndDepart || arrivalTimeEndDepart) {
        filtered = filtered.filter(
          (flight) =>
            flight.flightItineraries[0] &&
            flight.flightItineraries[0].arrivalTime >= arrivalTimeStartDepart &&
            flight.flightItineraries[0].arrivalTime <= arrivalTimeEndDepart
        );
      }

      // return flight
      // Filter by Departure time
      if (!isOneWay) {
        if (departureTimeStartReturn || departureTimeEndReturn) {
          filtered = filtered.filter(
            (flight) =>
              flight.flightItineraries[1] &&
              flight.flightItineraries[1].departureTime >= departureTimeStartReturn &&
              flight.flightItineraries[1].departureTime <= departureTimeEndReturn
          );
        }
        // Filter by Arrival time
        if (arrivalTimeEndReturn || arrivalTimeEndReturn) {
          filtered = filtered.filter(
            (flight) =>
              flight.flightItineraries[1] &&
              flight.flightItineraries[1].arrivalTime >= arrivalTimeStartReturn &&
              flight.flightItineraries[1].arrivalTime <= arrivalTimeEndReturn
          );
        }
      }

      // Filter by duration
      if (duration >= 0) {
        filtered = filtered.filter((flight) =>
          flight.flightItineraries.every(
            (itinerary) => itinerary.durationHour * 60 + itinerary.durationMinute <= duration * 60
          )
        );
      }
      // // Filter by price option
      if (priceOptions) {
        filtered = filtered.filter((flight) =>
          priceOptions.includedCheckedBagsOnly ? flight.pricingOptions?.includedCheckedBagsOnly : false
        );
        filtered = filtered.filter((flight) =>
          priceOptions.refundableFare ? flight.pricingOptions?.refundableFare : true
        );
        filtered = filtered.filter((flight) =>
          priceOptions.noRestrictionFare ? flight.pricingOptions?.noRestrictionFare : true
        );
        filtered = filtered.filter((flight) =>
          priceOptions.noPenaltyFare ? flight.pricingOptions?.noPenaltyFare : true
        );
      }

      // Filter by flexible day
      setFilter(filtered);
      closeModal();
    }
  };

  const [isOneWay, setIsOneWay] = useState<boolean | null>(null);
  const handleCheckOneWay = () => {
    if (dataFlight.length > 0) {
      setIsOneWay(dataFlight[0].flightItineraries.length === 1);
    }
  };
  const [isOpenModal, setIsOpenModal] = useState(false);
  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    if (dataFlight) {
      getAllAirlinesFromSearch();
      getAllAirportLayoverFromSearch();
      handleCheckOneWay();
    }
  }, [dataFlight]);

  useEffect(() => {
    filterFlight();
  }, [
    stops,
    priceLimit,
    selectedAirlines,
    selectedAirportLayover,
    departureTimeStartDepart,
    departureTimeEndDepart,
    arrivalTimeStartDepart,
    arrivalTimeEndDepart,
    departureTimeStartReturn,
    departureTimeEndReturn,
    arrivalTimeStartReturn,
    arrivalTimeEndReturn,
    duration,
    priceOptions,
  ]);

  return (
    <>
      <div className="FilterFlight-container">
        <ModalLoading isOpen={isOpenModal}></ModalLoading>
        <div className="FilterFlight-header">Filter</div>

        <div className="FilterFlight-stops">
          <div className="FilterFlight-stopsText">Stops</div>
          <div className="FilterFlight-radioButton">
            <Radio.Group
              onChange={(e) => {
                onChangeStops(e);
              }}
              value={stops}
              defaultValue={1}
              className="FilterFlight-radioButtonGroup"
            >
              <Radio value={1}>any</Radio>
              <Radio value={2}>1 stop</Radio>
              <Radio value={3}>non stop</Radio>
            </Radio.Group>
          </div>
        </div>

        <div className="FilterFlight-priceLimit">
          <div className="FilterFlight-priceLimitText">Price Limit</div>
          <div className="FilterFlight-sliderPriceLimit">
            <div className="FilterFlight-PriceLimitdescription">Less than ${priceLimit} per traveller</div>
            <Slider
              className="FilterFlight-antSilider"
              max={10000}
              min={0}
              defaultValue={8000}
              onChange={(e) => {
                onChangePriceLimit(e);
              }}
              tooltip={{ formatter: null }}
            />
          </div>
        </div>

        <div className="FilterFlight-airlines">
          <div className="FilterFlight-airlinesText">Airlines</div>
          <div className="FilterFlight-airlinesOptions">
            <div className="FilterFlight-Select-Clear-buttonPanel">
              <button
                className="transparent-button"
                onClick={() => {
                  handleSelectAllAirlines();
                }}
              >
                Select All
              </button>
              |
              <button
                className="transparent-button"
                onClick={() => {
                  handleClearAllAirlines();
                }}
              >
                Clear All
              </button>
            </div>
            <div className="FilterFlight-airlinesCheckboxAll">
              {visibleAirlines.map((airline) => (
                <label
                  key={airline}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <input
                    className="checkbox-airlines-filter"
                    type="checkbox"
                    value={airline}
                    checked={selectedAirlines.includes(airline)}
                    onChange={() => {
                      handleCheckboxAirlinesChange(airline);
                    }}
                  />
                  {airline}
                </label>
              ))}
            </div>

            {displayAllAirlines ? (
              <button className="transparent-button" onClick={handleShortenAirlines}>
                Shorten
              </button>
            ) : (
              <button className="transparent-button" onClick={handleShowAllAirlines}>
                Show All
              </button>
            )}
          </div>
        </div>

        <div className="FlightFilter-layoverAirport">
          <div className="FilterFlight-layoverAirportText">Layover Airports</div>
          <div className="FilterFlight-layoverAirportCheckboxAll">
            {visibleAirportLayover.map((airport) => (
              <label
                key={airport}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  className="checkbox-airlines-filter"
                  type="checkbox"
                  value={airport}
                  checked={selectedAirportLayover.includes(airport)}
                  onChange={() => handleCheckboxAirportLayoverChange(airport)}
                />
                {airport}
              </label>
            ))}
            {displayAllAirportLayover ? (
              <button className="transparent-button" onClick={handleShortenAirportLayover}>
                Shorten
              </button>
            ) : (
              <button className="transparent-button" onClick={handleShowAllAirportLayover}>
                Show All
              </button>
            )}
          </div>
        </div>

        {/* <div className="FiterFlight-flexibleDate">
          <div className="FiterFlight-flexibleDateText">Flexible Day</div>
          <div className="FilterFlight-flexibleDateSlider">
            <div className="FilterFlight-flexibleDatedescription">
              flexible date (+/-) :
              <span style={{ marginLeft: 2, marginRight: 2 }}>
                {flexibleDate}
              </span>{" "}
              day(s)
            </div>
            <Slider
              max={3}
              min={0}
              defaultValue={0}
              onChange={onChangeFlexibleDate}
              tooltip={{ formatter: null }}
            />
          </div>
        </div> */}

        <div className="FilterFlight-departureTime">
          <div className="FilterFlight-departureTimeText">Outbound flight</div>
          <div className="FilterFlight-departureTimesubText">Departure Time</div>
          <div className="FilterFlight-departureTimeSlider">
            <div className="FilterFlight-departureTimeSliderDescription">
              Available Time : {departureTimeStartDepart} - {departureTimeEndDepart}
            </div>
            <TimeSlider onStartChange={onChangeDepartureTimeStartDepart} onEndChange={onChangeDepartureTimeEndDepart} />
          </div>
        </div>

        <div className="FilterFlight-arrivalTime">
          <div className="FilterFlight-arrivalTimesubText">Arrival Time</div>
          <div className="FilterFlight-arrivalTimeSlider">
            <div className="FilterFlight-departureTimeSliderDescription">
              Available Time : {arrivalTimeStartDepart} - {arrivalTimeEndDepart}
            </div>
            <TimeSlider onStartChange={onChangeArrivalTimeStartDepart} onEndChange={onChangeArrivalTimeEndDepart} />
          </div>
        </div>
        {!isOneWay ? (
          <>
            <div className="FilterFlight-departureTime">
              <div className="FilterFlight-departureTimeText">Return flight</div>
              <div className="FilterFlight-departureTimesubText">Departure Time</div>
              <div className="FilterFlight-departureTimeSlider">
                <div className="FilterFlight-departureTimeSliderDescription">
                  Available Time : {departureTimeStartReturn} - {departureTimeEndReturn}
                </div>
                <TimeSlider
                  onStartChange={onChangeDepartureTimeStartReturn}
                  onEndChange={onChangeDepartureTimeEndReturn}
                />
              </div>
            </div>

            <div className="FilterFlight-arrivalTime">
              <div className="FilterFlight-arrivalTimesubText">Arrival Time</div>
              <div className="FilterFlight-arrivalTimeSlider">
                <div className="FilterFlight-departureTimeSliderDescription">
                  Available Time : {arrivalTimeStartReturn} - {arrivalTimeEndReturn}
                </div>
                <TimeSlider onStartChange={onChangeArrivalTimeStartReturn} onEndChange={onChangeArrivalTimeEndReturn} />
              </div>
            </div>
          </>
        ) : null}

        <div className="FilterFligt-duration">
          <div className="FilterFligt-durationText">Duration</div>
          <div className="FilterFlight-durationSlider">
            <div className="FilterFlight-durationdescription">maximum travel time : {duration} hours</div>
            <Slider
              className="FilterFlight-antSilider"
              max={40}
              min={0}
              defaultValue={40}
              onChange={onChangeDuration}
              tooltip={{ formatter: null }}
            />
          </div>
        </div>

        <div className="FilterFlight-priceOption">
          <div className="FilterFlight-priceOptionText">Price Option</div>
          <div className="FilterFilght-priceOptionCheckboxes">
            <label className="label-priceOptionText">
              <input
                className="checkbox-airlines-filter"
                type="checkbox"
                value="includedCheckedBagsOnly"
                checked={priceOptions.includedCheckedBagsOnly || false}
                onChange={() => handlePriceOptionChange("includedCheckedBagsOnly")}
              />
              included checked bags
            </label>
            <label className="label-priceOptionText">
              <input
                className="checkbox-airlines-filter"
                type="checkbox"
                value="refundableFare"
                checked={priceOptions.refundableFare || false}
                onChange={() => handlePriceOptionChange("refundableFare")}
              />
              refundable fare
            </label>
            <label className="label-priceOptionText">
              <input
                className="checkbox-airlines-filter"
                type="checkbox"
                value="noRestrictionFare"
                checked={priceOptions.noRestrictionFare || false}
                onChange={() => handlePriceOptionChange("noRestrictionFare")}
              />
              no restriction fare
            </label>
            <label className="label-priceOptionText">
              <input
                className="checkbox-airlines-filter"
                type="checkbox"
                value="noPenaltyFare"
                checked={priceOptions.noPenaltyFare || false}
                onChange={() => handlePriceOptionChange("noPenaltyFare")}
              />
              no penalty fare
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterFlight;
