import { Guest } from "../../types/hotel";
import "./ParticipantDetail.css";
import React from "react";

interface ParticipantDetailProps {
  participants: Guest[];
}

const ParticipantDetail: React.FC<ParticipantDetailProps> = ({
  participants,
}) => {
  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <div className="Participant-container">
      <div className="Participant-total">
        Number of participant : {participants.length}
      </div>

      <div className="Participant-participants">
        {participants.map((participant, index) => (
          <div key={index} className={`Participant-each`}>
            <span className="participant-number">{index + 1}. </span>
            {capitalize(participant.title)}. {participant.firstName}{" "}
            {participant.lastName} ({participant.email})
          </div>
        ))}
      </div>
    </div>
  );
};

export default ParticipantDetail;
