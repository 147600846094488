import * as React from "react";
import "./TripStep.css";
import { MdFlight } from "react-icons/md";
import { FaHotel } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import { StepStatus } from "../../types/enum/stepStatus";

interface TripStepProp {
  phase: string;
  status: StepStatus;
  origin?: string;
  destination?: string;
  location?: string;
  onClick?: () => void;
}

const TripStep: React.FC<TripStepProp> = (props) => {
  const renderStepIcon = () => {
    if (props.phase === "flight") {
      return <MdFlight className="tripstep-icon" />;
    } else if (props.phase === "hotel") {
      return <FaHotel className="tripstep-icon" />;
    } else {
      return <FaCheck className="tripstep-icon" />;
    }
  };

  const renderStepText = () => {
    if (props.phase === "flight") {
      return (
        <div className="tripstep-text">
          Select Flight from {props.origin} to {props.destination}
        </div>
      );
    } else if (props.phase === "hotel") {
      return <div className="tripstep-text">Select Hotel at {props.location}</div>;
    } else {
      return <div className="tripstep-text">Confirmation</div>;
    }
  };

  const getBackgroundColor = () => {
    if (props.status === StepStatus.COMPLETE) {
      return "#DAEBD9";
    } else if (props.status === StepStatus.INPROGRESS) {
      return "#F5EAFF";
    } else {
      return "#E3F2FF";
    }
  };

  return (
    <div className="tripstep-container">
      <div style={{ borderColor: getBackgroundColor() }} className="tripstep-tail-triangle"></div>
      <div style={{ backgroundColor: getBackgroundColor() }} className="tripstep-content" onClick={props.onClick}>
        {renderStepIcon()}
        {renderStepText()}
      </div>
      <div style={{ borderColor: getBackgroundColor() }} className="tripstep-head-triangle"></div>
    </div>
  );
};

export default TripStep;
