import { FC } from "react";
import { ContactPassenger, Passenger } from "../../types/flight";
import { CardInformation } from "../PaymentForm/PaymentForm";
import "./ConfirmInfo.css";
import { RequestableWallet } from "../../types/enum/wallet";

const mockData = [
  {
    firstname: "John",
    middlename: "Doe",
    lastname: "Smith",
    gender: "Male",
    birthdate: "1990-01-01",
    region: "Buddhist",
    passportid: "AB123456",
    expirydate: "2025-01-01",
  },
  {
    firstname: "Jane",
    middlename: "Doe",
    lastname: "Smith",
    gender: "Female",
    birthdate: "1992-03-15",
    region: "Buddhist",
    passportid: "CD789012",
    expirydate: "2024-08-30",
  },
  {
    firstname: "Jane",
    middlename: "Doe",
    lastname: "Smith",
    gender: "Female",
    birthdate: "1992-03-15",
    region: "Buddhist",
    passportid: "CD789012",
    expirydate: "2024-08-30",
  },
  {
    firstname: "Jane",
    middlename: "Doe",
    lastname: "Smith",
    gender: "Female",
    birthdate: "1992-03-15",
    region: "Buddhist",
    passportid: "CD789012",
    expirydate: "2024-08-30",
  },
  // Add more passengers as needed
];

const mockContactInfo = {
  firstName: "Jane",
  middleName: "Doe",
  lastName: "Smith",
  phoneNumber: "555-555-5555",
  email: "jane.doe@example.com",
};

const mockPaymentInfo = {
  cardNumber: "1234-1234-1234-1234",
  cardHolderName: "John Doe",
  expiryDate: "12/2027",
};

interface ConfirmInfoProp {
  passengers: Passenger[];
  contactInfo: ContactPassenger;
  cardInfo: CardInformation;
  paymentMethod: string;
  walletInfo: RequestableWallet;
  fitContent?: boolean;
}

const ConfirmInfo: FC<ConfirmInfoProp> = ({
  passengers,
  contactInfo,
  cardInfo,
  paymentMethod,
  walletInfo,
  fitContent,
}) => {
  const last4Digits = cardInfo.cardNumber.slice(-4);
  const containerStyle = {
    height: fitContent ? "fit-content" : "521px",
  };

  return (
    <div className="confirm-container" style={containerStyle}>
      {passengers.map((passenger, passengerIndex) => (
        <div key={passengerIndex}>
          <div className="passenger-info-header">Passenger {passengerIndex + 1}</div>
          <div className="passenger-info">
            <div className="confirm-item">Firstname: {passenger.firstName}</div>
            <div className="confirm-item">Middlename: {passenger.middleName}</div>
            <div className="confirm-item">Surname: {passenger.lastName}</div>
            <div className="confirm-item">Gender: {passenger.gender}</div>
            <div className="confirm-item">Birth date: {passenger.birthDate?.format("D MMM YYYY")}</div>
            <div className="confirm-item">Nationality: {passenger.nationality}</div>
            <div className="confirm-item">Passport Number: {passenger.passportID}</div>
            <div className="confirm-item">Expiry date: {passenger.expiryDate?.format("D MMM YYYY")}</div>
          </div>
        </div>
      ))}
      <div className="contact-info">
        <div className="passenger-info-header">Contact Info</div>
        <div className="passenger-info2">
          <div className="confirm-item">Firstname: {contactInfo.firstName}</div>
          <div className="confirm-item">Middlename: {contactInfo.middleName}</div>
          <div className="confirm-item">Surname: {contactInfo.surName}</div>
          <div className="confirm-item">
            Phone number: ({contactInfo.phoneNumber.countryCode}+) {contactInfo.phoneNumber.areaCode}-
            {contactInfo.phoneNumber.phoneNumber}
          </div>
          <div className="confirm-item">Email: {contactInfo.email}</div>
        </div>
      </div>
      {paymentMethod == "credit" ? (
        <div className="payment-method">
          <div className="passenger-info-header">Payment Method</div>
          <div className="passenger-info2">
            <div className="confirm-item">Card number: xxxx-xxxx-xxxx-{last4Digits}</div>
            <div className="confirm-item">Card holder name: {cardInfo.cardHolderName}</div>
            <div className="confirm-item">Expiry date: {cardInfo.expiryDate?.toString()}</div>
          </div>
        </div>
      ) : (
        <div className="payment-method">
          <div className="passenger-info-header">Payment Method</div>
          <div className="passenger-info2">
            <div className="confirm-item">Wallet: {walletInfo.name}</div>
            <div className="confirm-item">
              Credit Limit: {walletInfo.credit_limit} {walletInfo.currency}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmInfo;
