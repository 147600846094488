import React from "react";
import "./ModalLoadingDone.css";

interface ModalLoadingDoneProps {
  isOpen: boolean;
  text: string;
}

const ModalLoadingDone: React.FC<ModalLoadingDoneProps> = ({
  isOpen,
  text,
}) => {
  return (
    <>
      {isOpen && (
        <div className=" ModalLoadingDone-container">
          <div className=" ModalLoadingDone-subDisplay">
            <div className=" ModalLoadingDone-iconDisplay">
              <div className="success-checkmark">
                <div className="check-icon">
                  <span className="icon-line line-tip"></span>
                  <span className="icon-line line-long"></span>
                  <div className="icon-circle"></div>
                  <div className="icon-fix"></div>
                </div>
              </div>
              <div className="ModalLoadingDone-subText">{text}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalLoadingDone;
