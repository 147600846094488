import { BookingList } from "../../types/calendar";
import BookingListCalendarComponent from "./BookingListCalendarComponent";
import "./BookingListContainer.css";
import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";

interface BookingListContainerProps {
  bookingList: BookingList[] | undefined;
}
interface BookingListContainerProps {
  bookingList: BookingList[] | undefined;
}

const mockBookingList: BookingList = {
  bookingId: "65d9fc0512f10c183de72159",
  bookingType: "hotel",
  startDate: "2024-02-28",

  startAt: "14:00:00",
  endDate: "2024-02-29",
  endAt: "12:00:00",
  status: "Reject",

  paymentType: "wallet",
  hotelName: "NOVOTEL SUVARNABHUMI AIRPORT",
  travellerCount: 30,
};
const BookingListContainer: React.FC<BookingListContainerProps> = ({
  bookingList,
}) => {
  const [showApprove, setShowApprove] = useState(true);
  const [showReject, setShowReject] = useState(true);
  const [showPending, setShowPending] = useState(true);

  const filteredBookingList = bookingList?.filter((booking) => {
    return (
      (showApprove && booking.status === "Approve") ||
      (showReject && booking.status === "Reject") ||
      (showPending && booking.status === "Pending")
    );
  });

  return (
    <div className="CalendarPage-bookingListContainer">
      <div className="bookingList-title">Booking List</div>
      <div className="BookingList-filterPanel">
        <label>
          <Checkbox
            defaultChecked={showApprove}
            onChange={() => setShowApprove(!showApprove)}
            className="BookingList-checkbox"
          />{" "}
          Approved
        </label>
        <label>
          <Checkbox
            defaultChecked={showReject}
            onChange={() => setShowReject(!showReject)}
          />{" "}
          Rejected
        </label>
        <label>
          <Checkbox
            defaultChecked={showPending}
            onChange={() => setShowPending(!showPending)}
          />{" "}
          Pending
        </label>
      </div>
      {filteredBookingList && filteredBookingList.length > 0 ? (
        <div className="CalendarPage-bookingList">
          {filteredBookingList.map((booking) => (
            <BookingListCalendarComponent
              key={booking.bookingId}
              // mocking data now
              data={booking}
            />
          ))}
        </div>
      ) : (
        <div className="CalendarPage-bookingList">
          <div className="NoBookingListDisplay">No Booking Display</div>
        </div>
      )}
    </div>
  );
};

export default BookingListContainer;
