const airportOptions = [
  ["Aarhus", "AAR"],
  ["Abadan", "ABD"],
  ["Abeche", "AEH"],
  ["Aberdeen", "ABZ"],
  ["Aberdeen (SD)", "ABR"],
  ["Abidjan", "ABJ"],
  ["Abilene (TX)", "ABI"],
  ["Abu Dhabi - Abu Dhabi International", "AUH"],
  ["Abuja - Nnamdi Azikiwe International Airport", "ABV"],
  ["Abu Rudeis", "AUE"],
  ["Abu Simbel", "ABS"],
  ["Acapulco", "ACA"],
  ["Accra - Kotoka International Airport", "ACC"],
  ["Adana", "ADA"],
  ["Addis Ababa - Bole International Airport", "ADD"],
  ["Adelaide", "ADL"],
  ["Aden - Aden International Airport", "ADE"],
  ["Adiyaman", "ADF"],
  ["Adler/Sochi", "AER"],
  ["Agades", "AJY"],
  ["Agadir", "AGA"],
  ["Agana (Hagåtña)", "SUM"],
  ["Aggeneys", "AGZ"],
  ["Aguadilla", "BQN"],
  ["Aguascaliente", "AGU"],
  ["Ahmedabad", "AMD"],
  ["Aiyura", "AYU"],
  ["Ajaccio", "AJA"],
  ["Akita", "AXT"],
  ["Akron (OH)", "CAK"],
  ["Akrotiri - RAF", "AKT"],
  ["Al Ain", "AAN"],
  ["Al Arish", "AAC"],
  ["Albany", "ALH"],
  ["Albany (GA)", "ABY"],
  ["Albany (NY) - Albany International Airport", "ALB"],
  ["Albi", "LBI"],
  ["Alborg", "AAL"],
  ["Albuquerque (NM)", "ABQ"],
  ["Albury", "ABX"],
  ["Alderney", "ACI"],
  ["Aleppo", "ALP"],
  ["Alesund", "AES"],
  ["Alexander Bay - Kortdoorn", "ALJ"],
  ["Alexandria - Borg el Arab Airport", "HBH"],
  ["Alexandria - El Nhouza Airport", "ALY"],
  ["Alexandria - Esler Field", "ESF"],
  ["Alfujairah (Fujairah)", "FJR"],
  ["Alghero Sassari", "AHO"],
  ["Algiers, Houari Boumediene Airport", "ALG"],
  ["Al Hoceima", "AHU"],
  ["Alicante", "ALC"],
  ["Alice Springs", "ASP"],
  ["Alldays", "ADY"],
  ["Allentown (PA)", "ABE"],
  ["Almaty (Alma Ata) - Almaty International Airport", "ALA"],
  ["Almeria", "LEI"],
  ["Alta", "ALF"],
  ["Altay", "AAT"],
  ["Altenrhein", "ACH"],
  ["Altoona (PA)", "AOO"],
  ["Altus", "AXS"],
  ["Amami", "ASJ"],
  ["Amarillo (TX)", "AMA"],
  ["Amazon Bay", "AZB"],
  ["Amman - Queen Alia International Airport", "AMM"],
  ["Amman - Amman-Marka International Airport", "ADJ"],
  ["Amritsar", "ATQ"],
  ["Amsterdam - Amsterdam Airport Schiphol", "AMS"],
  ["Anand", "QNB"],
  ["Anchorage (AK) - Ted Stevens Anchorage International", "ANC"],
  ["Ancona - Ancona Falconara Airport", "AOI"],
  ["Andorra La Vella - Heliport", "ALV"],
  ["Anguilla", "AXA"],
  ["Anjouan - Anjouan Airport", "AJN"],
  ["Ankara", "ANK"],
  ["Ankara - Esenboğa International Airport", "ESB"],
  ["Annaba", "AAE"],
  ["Ann Arbor (MI)", "ARB"],
  ["Annecy", "NCY"],
  ["Anniston (AL)", "ANB"],
  ["Antalya", "AYT"],
  ["Antananarivo (Tanannarive) - Ivato International Airport", "TNR"],
  ["Antigua, V.C. Bird International", "ANU"],
  ["Antwerp", "ANR"],
  ["Aomori", "AOJ"],
  ["Apia - Faleolo International Airport", "APW"],
  ["Appelton/Neenah/Menasha (WI)", "ATW"],
  ["Aqaba", "AQJ"],
  ["Aracaju", "AJU"],
  ["Arkhangelsk", "ARH"],
  ["Arusha", "ARK"],
  ["Araxos", "GPA"],
  ["Arlit", "RLT"],
  ["Arrecife/Lanzarote", "ACE"],
  ["Aruba - Reina Beatrix International, Oranjestad", "AUA"],
  ["Asheville (NC)", "AVL"],
  ["Ashgabat - Saparmurat Turkmenbashy Int. Airport", "ASB"],
  ["Asmara - Asmara International", "ASM"],
  ["Aspen, (CO) - Aspen-Pitkin County Airport", "ASE"],
  ["Assiut", "ATZ"],
  ["Astana - Astana International Airport", "TSE"],
  ["Asuncion - Asunción International Airport", "ASU"],
  ["Aswan - Daraw Airport", "ASW"],
  ["Athens - Elefthérios Venizélos International Airport", "ATH"],
  ["Athens, Hellinikon Airport", "HEW"],
  ["Athens (GA)", "AHN"],
  ["Athens (OH)", "ATO"],
  ["Atlanta (GA) - Hartsfield Atlanta International Airport", "ATL"],
  ["Atlantic City (NJ) - Atlantic City International", "ACY"],
  ["Attawapiskat, NT", "YAT"],
  ["Auckland - Auckland International Airport", "AKL"],
  ["Augsburg - Augsbur gAirport", "AGB"],
  ["Augusta (GA)", "AGS"],
  ["Augusta (ME) - Augusta State Airport", "AUG"],
  ["Aurillac", "AUR"],
  ["Austin (TX) - Austin-Bergstrom Airport", "AUS"],
  ["Ayawasi", "AYW"],
  ["Ayers Rock - Connellan", "AYQ"],
  ["Ayr", "AYR"],
  ["Badajoz", "BJZ"],
  ["Bagdad - Baghdad International Airport", "BGW"],
  ["Bagdogra", "IXB"],
  ["Bahamas - Lynden Pindling International Airport", "NAS"],
  ["Bahawalpur", "BHV"],
  ["Bahrain - Bahrain International Airport", "BAH"],
  ["Bakersfield (CA)", "BFL"],
  ["Baku - Heydar Aliyev International Airport", "BAK"],
  ["Ballina", "BNK"],
  ["Baltimore (MD) - Washington International Airport", "BWI"],
  ["Bamaga", "ABM"],
  ["Bamako - Bamako-Sénou International Airport", "BKO"],
  ["Bambari", "BBY"],
  ["Bandar Seri Begawan - Brunei International Airport", "BWN"],
  ["Bandung - Husein Sastranegara International Airport", "BDO"],
  ["Bangalore", "BLR"],
  ["Bangassou", "BGU"],
  ["Bangkok, Don Muang", "DMK"],
  ["Bangkok, Suvarnabhumi International", "BKK"],
  ["Bangor (ME)", "BGR"],
  ['"Bangui - M\'Poko International Airport"', "BGF"],
  ["Banjul - Banjul International Airport (Yundum International)", "BJL"],
  ["Bannu", "BNP"],
  ["Barcelona", "BCN"],
  ["Barcelona", "BLA"],
  ["Bardufoss", "BDU"],
  ["Bari", "BRI"],
  ["Barisal", "BZL"],
  ["Baroda", "BDQ"],
  ["Barra (the famous tidal beach landing)", "BRR"],
  ["Barranquilla", "BAQ"],
  ["Basel", "BSL"],
  ["Basel/Mulhouse", "EAP"],
  ["Basra, Basrah", "BSR"],
  ["Basse-Terre - Pointe-à-Pitre International Airport", "PTP"],
  ["Basseterre - Robert L. Bradshaw International Airport", "SKB"],
  ["Bastia", "BIA"],
  ["Baton Rouge (LA) - Baton Rouge Metropolitan Airport", "BTR"],
  ["Bayreuth - Bindlacher-Berg", "BYU"],
  ["Beaumont/Pt. Arthur (TX)", "BPT"],
  ["Beckley (WV)", "BKW"],
  ["Beef Island - Terrance B. Lettsome", "EIS"],
  ["Beijing", "PEK"],
  ["Beijing - Nanyuan Airport", "NAY"],
  ["Beira", "BEW"],
  ["Beirut - Beirut Rafic Hariri International Airport", "BEY"],
  ["Belem - Val de Cans International Airport", "BEL"],
  ["Belfast - George Best Belfast City Airport", "BHD"],
  ["Belfast - Belfast International Airport", "BFS"],
  ["Belgaum", "IXG"],
  ["Belgrad (Beograd) - Belgrade Nikola Tesla International", "BEG"],
  ["Belize City - Philip S.W.Goldson International", "BZE"],
  ["Bellingham (WA)", "BLI"],
  ["Belo Horizonte - Tancredo Neves International Airport", "CNF"],
  ["Bemidji (MN)", "BJI"],
  ["Benbecula", "BEB"],
  ["Benghazi (Bengasi)", "BEN"],
  ["Benguela", "BUG"],
  ["Benton Harbour (MI)", "BEH"],
  ["Berberati", "BBT"],
  ["Bergamo/Milan - Orio Al Serio", "BGY"],
  ["Bergen", "BGO"],
  ["Bergerac - Roumanieres", "EGC"],
  ["Berlin", "BER"],
  ["Berlin, Schoenefeld", "SXF"],
  ["Berlin, Tegel", "TXL"],
  ["Berlin, Tempelhof (ceased operating in 2008)", "THF"],
  ["Bermuda - L.F. Wade International Airport", "BDA"],
  ["Berne, Bern-Belp", "BRN"],
  ["Berne, Railway Service", "ZDJ"],
  ["Bethel (AK)", "BET"],
  ["Bhopal", "BHO"],
  ["Bhubaneswar", "BBI"],
  ["Biarritz", "BIQ"],
  ["Bilbao", "BIO"],
  ["Billings (MT)", "BIL"],
  ["Billund", "BLL"],
  ["Bintulu", "BTU"],
  ["Biraro", "IRO"],
  ["Birmingham - Birmingham International Airport", "BHX"],
  ["Birmingham (AL)", "BHM"],
  ["Bishkek - Manas International Airport", "FRU"],
  ["Bismarck (ND) - Bismarck Municipal Airport", "BIS"],
  ["Bissau - Osvaldo Vieiro International Airport", "BXO"],
  ["Blackpool", "BLK"],
  ["Blackwater", "BLT"],
  ["Blantyre (Mandala) - Chileka International Airport", "BLZ"],
  ["Blenheim", "BHE"],
  ["Bloemfontein - Bloemfontein Airport", "BFN"],
  ["Bloomington (IL)", "BMI"],
  ["Bloomington (IN)", "BMG"],
  ["Bluefield (WV)", "BLF"],
  ["Boa Vista", "BVB"],
  ["Bobo/Dioulasso", "BOY"],
  ["Bodo", "BOO"],
  ["Bodrum - Milas Airport", "BJV"],
  ["Bogota - El Nuevo Dorado International Airport", "BOG"],
  ["Boise (ID) - Boise Air Terminal", "BOI"],
  ["Bologna", "BLQ"],
  ["Bombay (Mumbai) - Chhatrapati Shivaji International", "BOM"],
  ["Bonaire", "BON"],
  ["Bonaventure, PQ", "YVB"],
  ["Bora Bora", "BOB"],
  ["Bordeaux - Bordeaux Airport", "BOD"],
  ["Borrego Springs (CA)", "BXS"],
  ["Boston (MA) - General Edward Lawrence Logan", "BOS"],
  ["Bouake", "BYK"],
  ["Bourgas/Burgas", "BOJ"],
  ["Bournemouth", "BOH"],
  ["Bowen", "ZBO"],
  ["Bozeman (MT)", "BZN"],
  ["Bradford/Warren (PA) /Olean (NY)", "BFD"],
  ["Brainerd (MN)", "BRD"],
  ["Brampton Island", "BMP"],
  ["Brasilia - President Juscelino Kubitschek International", "BSB"],
  ["Bratislava - M. R. Štefánik Airport", "BTS"],
  ["Brazzaville - Maya-Maya Airport", "BZV"],
  ["Bremen - Bremen Airport (Flughafen Bremen)", "BRE"],
  ["Brescia, Montichiari", "VBS"],
  ["Brest", "BES"],
  ["Bria", "BIV"],
  ["Bridgeport (CT)", "BDR"],
  ["Bridgetown - Grantley Adams International", "BGI"],
  ["Brindisi", "BDS"],
  ["Brisbane", "BNE"],
  ["Bristol", "BRS"],
  ["Broennoeysund", "BNN"],
  ["Broken Hill", "BHQ"],
  ["Brookings (SD)", "BKX"],
  ["Broome", "BME"],
  ["Brunswick (GA)", "BQK"],
  ["Brussels - Brussels Airport", "BRU"],
  ["Bucaramanga", "BGA"],
  ["Bucharest", "BUH"],
  ["Bucharest - Henri Coandă International Airport", "OTP"],
  ["Budapest - Budapest Ferihegy International Airport", "BUD"],
  ["Buenos Aires", "BUE"],
  ["Buenos Aires, Ezeiza International Airport", "EZE"],
  ["Buenos Aires, Jorge Newbery", "AEP"],
  ["Buffalo Range", "BFO"],
  ["Buffalo/Niagara Falls (NY)", "BUF"],
  ["Bujumbura - Bujumbura International Airport", "BJM"],
  ["Bulawayo", "BUQ"],
  ["Bullhead City (NV)", "BHC"],
  ["Bundaberg", "BDB"],
  ["Burbank (CA)", "BUR"],
  ["Burlington IA", "BRL"],
  ["Burlington (VT)", "BTV"],
  ["Burnie (Wynyard)", "BWT"],
  ["Butte (MT)", "BTMC"],
  ["Cabinda", "CAB"],
  ["Cagliari", "CAG"],
  ["Cairns", "CNS"],
  ["Cairo - Cairo International Airport", "CAI"],
  ["Calama - El Loa", "CJC"],
  ["Calcutta (Kolkata) - Netaji Subhas Chandra", "CCU"],
  ["Calgary - Calgary International Airport", "YYC"],
  ["Cali", "CLO"],
  ["Calicut", "CCJ"],
  ["Calvi", "CLY"],
  ["Cambridge Bay", "YCB"],
  ["Cambrigde", "CBG"],
  ["Campbeltown", "CAL"],
  ["Campo Grande", "CGR"],
  ["Canberra - Canberra Airport", "CBR"],
  ["Cancun", "CUN"],
  ["Cannes – Mandelieu Airport", "CEQ"],
  ["Canouan (island) - Canouan Airport", "CIW"],
  ["Cape Town - Cape Town International Airport", "CPT"],
  ["Caracas - Simón Bolívar International Airport", "CCS"],
  ["Cardiff - Cardiff Airport", "CWL"],
  ["Carlsbad (CA)", "CLD"],
  ["Carnarvon", "CVQ"],
  ["Carnot", "CRF"],
  ["Carson City (NV)", "CSN"],
  ["Casablanca", "CAS"],
  ["Casablanca, Mohamed V", "CMN"],
  ["Casa de Campo - La Romana International Airport", "LRM"],
  ["Casino", "CSI"],
  ["Casper (WY)", "CPR"],
  ["Castaway", "CST"],
  ["Cartagena - Rafael Núñez International Airport", "CTG"],
  ["Castries - George F. L. Charles Airport", "SLU"],
  ["Catania", "CTA"],
  ["Cayenne - Cayenne-Rochambeau Airport", "CAY"],
  ["Cottbus - Cottbus-Drewitz Airport", "CBU"],
  ["Cebu City - Mactan-Cebu International", "CEB"],
  ["Cedar City (UT)", "CDC"],
  ["Cedar Rapids IA", "CID"],
  ["Ceduna", "CED"],
  ["Cessnock", "CES"],
  ["Chabarovsk (Khabarovsk)", "KHV"],
  ["Chambery", "CMF"],
  ["Champaign (IL)", "CMI"],
  ["Chandigarh - Chandigarh International Airport", "IXC"],
  ["Changchun", "CGQ"],
  ["Chania", "CHQ"],
  ["Chaoyang, Beijing - Chaoyang Airport", "CHG"],
  ["Charleston (SC)", "CHS"],
  ["Charleston (WV) - Yeager Airport", "CRW"],
  ["Charlotte (NC)", "CLT"],
  ["Charlottesville (VA)", "CHO"],
  ["Charters Towers", "CXT"],
  ["Chattanooga (TN)", "CHA"],
  ["Chengdu - Shuangliu", "CTU"],
  ["Chennai (Madras)", "MAA"],
  ["Cheyenne (WY) - Cheyenne Regional Airport", "CYS"],
  ["Chiang Mai - Chiang Mai International Airport", "CNX"],
  ["Chiba City", "QCB"],
  ["Chicago (IL), Midway", "MDW"],
  ["Chicago (IL), O'Hare International Airport", "ORD"],
  ["Chicago (IL)", "CHI"],
  ["Chichen Itza", "CZA"],
  ["Chico (CA)", "CIC"],
  ["Chihuahua - Gen Fierro Villalobos", "CUU"],
  ["Chios", "JKH"],
  ["Chipata", "CIP"],
  ["Chisinau - Chişinău International Airport", "KIV"],
  ["Chita (Tschita)", "HTA"],
  ["Sapporo - New Chitose Airport", "CTS"],
  ["Chitral", "CJL"],
  ["Chittagong", "CGP"],
  ["Chongqing - Jiangbei International Airport", "CKG"],
  ["Christchurch", "CHC"],
  ["Chub Cay", "CCZ"],
  ["Churchill", "YYQ"],
  ["Cienfuegos - Jaime González Airport", "CFG"],
  ["Cincinnati (OH) - Cincinnati/Northern Kentucky Int'l", "CVG"],
  ["Ciudad Del Carmen", "CME"],
  ["Ciudad Guayana", "CGU"],
  ["Ciudad Juarez", "CJS"],
  ["Ciudad Obregon", "CEN"],
  ["Ciudad Victoria", "CVM"],
  ["Clarksburg (WV)", "CKB"],
  ["Clermont", "CMQ"],
  ["Clermont Ferrand", "CFE"],
  ["Cleveland (OH) , Burke Lakefront", "BKL"],
  ["Cleveland (OH) - Cleveland Hopkins International", "CLE"],
  ["Cochabamba", "CBB"],
  ["Cochin", "COK"],
  ["Cody/Powell/Yellowstone (WY)", "COD"],
  ["Coffmann Cove (AK)", "KCC"],
  ["Coffs Harbour", "CFS"],
  ["Coimbatore", "CJB"],
  ["Colima", "CLQ"],
  ["College Station/Bryan (TX)", "CLL"],
  ["Collinsville", "KCE"],
  ["Cologne - Cologne Airport (Flughafen Köln/Bonn)", "CGN"],
  ["Colombo - Bandaranaike International Airport", "CMB"],
  ["Colorado Springs (CO)", "COS"],
  ["Columbia (SC) - Columbia Metropolitan Airport", "CAE"],
  ["Columbus (GA)", "CSG"],
  ["Columbus (OH) - Port Columbus International", "CMH"],
  ["Conakry - Conakry International Airport", "CKY"],
  ["Concord (CA) - Buchanan Field", "CCR"],
  ["Concord (NH) - Concord Municipal Airport", "CON"],
  ["Constantine", "CZL"],
  ["Constanta (Constanța) - Constanta Int'l Airport", "CND"],
  ["Coober Pedy", "CPD"],
  ["Cooktown", "CTN"],
  ["Cooma", "OOM"],
  ["Copenhagen - Copenhagen Airport", "CPH"],
  ["Cordoba", "COR"],
  ["Cordoba", "ODB"],
  ["Cordova (AK)", "CDV"],
  ["Corfu", "CFU"],
  ["Cork", "ORK"],
  ["Corpus Christi (TX)", "CRP"],
  ["Cotonou - Cotonou Cadjehoun Airport", "COO"],
  ["Coventry - Baginton", "CVT"],
  ["Cozmel", "CZM"],
  ["Craig (AK)", "CGA"],
  ["Crescent City (CA)", "CEC"],
  ["Cuiaba - Marechal Rondon International Airport", "CGB"],
  ["Culiacan", "CUL"],
  ["Curacao - Curaçao International Airport", "CUR"],
  ["Curitiba - Afonso Pena International Airport", "CWB"],
  ["Cuyo", "CYU"],
  ["Dakar - Léopold Sédar Senghor International Airport", "DKR"],
  ["Dalaman", "DLM"],
  ["Dalby", "DBY"],
  ["Dalian - Zhoushuizi International Airport", "DLC"],
  ["Dallas (TX) , Love Field", "DAL"],
  ["Dallas/Ft. Worth (TX) - Dallas/Fort Worth International", "DFW"],
  ["Daloa", "DJO"],
  ["Damascus, International", "DAM"],
  ["Dammam, King Fahad International", "DMM"],
  ["Danville (VA)", "DAN"],
  ["Dar es Salam (Daressalam) - Julius Nyerere Int'l", "DAR"],
  ["Darwin", "DRW"],
  ["Daydream Island", "DDI"],
  ["Dayton (OH)", "DAY"],
  ["Daytona Beach (FL)", "DAB"],
  ["Decatur (IL)", "DEC"],
  ["Deer Lake/Corner Brook", "YDF"],
  ["Delhi - Indira Gandhi International Airport", "DEL"],
  ["Den Haag (The Hague)", "HAG"],
  ["Denizli", "DNZ"],
  ["Denpasar/Bali", "DPS"],
  ["Denver (CO) - Denver International Airport", "DEN"],
  ["Dera Ismail Khan - Dera Ismail Khan Airport", "DSK"],
  ["Derby", "DRB"],
  ["Derry (Londonderry)", "LDY"],
  ["Des Moines (IA) - Des Moines International Airport", "DSM"],
  ["Detroit (MI) , Coleman A. Young Municipal", "DET"],
  ["Detroit (MI) , Wayne County Airport", "DTW"],
  ["Detroit (MI) , Metropolitan Area", "DTT"],
  ["Devils Lake (ND)", "DVL"],
  ["Devonport", "DPO"],
  ["Dhahran", "DHA"],
  ["Dhaka - Zia International Airport", "DAC"],
  ["Dili - Nicolau Lobato International Airport", "DIL"],
  ["Dillingham (AK)", "DLG"],
  ["Dinard", "DNR"],
  ["Disneyland Paris", "DLP"],
  ["Djerba", "DJE"],
  ["Djibouti (city) - Djibouti-Ambouli International Airport", "JIB"],
  ["Dodoma - Dodoma Airport", "DOD"],
  ["Doha - Doha International Airport", "DOH"],
  ["Doncaster/Sheffield, Robin Hood International Airport", "DSA"],
  ["Donegal (Carrickfin)", "CFN"],
  ["Dortmund", "DTM"],
  ["Dothan (AL)", "DHN"],
  ["Douala", "DLA"],
  ["Dresden - Dresden Airport", "DRS"],
  ["Dubai - Dubai International Airport", "DXB"],
  ["Dubbo", "DBO"],
  ["Dublin - Dublin International Airport", "DUB"],
  ["Dubois (PA)", "DUJ"],
  ["Dubrovnik", "DBV"],
  ["Dubuque IA", "DBQ"],
  ["Duesseldorf - Düsseldorf International Airport", "DUS"],
  ["Duluth (MN) /Superior (WI)", "DLH"],
  ["Dundee", "DND"],
  ["Dunedin", "DUD"],
  ["Dunk Island", "DKI"],
  ["Durango (CO)", "DRO"],
  ["Durban", "DUR"],
  ["Dushanbe (Duschanbe) - Dushanbe Airport", "DYU"],
  ["Dutch Harbor (AK)", "DUT"],
  ["Dysart", "DYA"],
  ["Dzaoudzi", "DZA"],
  ["East London", "ELS"],
  ["Easter Island", "IPC"],
  ["Eau Clarie (WI)", "EAU"],
  ["Edinburgh - Edinburgh Airport", "EDI"],
  ["Edmonton", "YEA"],
  ["Edmonton, International", "YEG"],
  ["Edmonton, Municipal", "YXD"],
  ["Egilsstadir", "EGS"],
  ["Eindhoven", "EIN"],
  ["Samana - Samaná El Catey International Airport", "AZS"],
  ["Elba Island, Marina Di Campo", "EBA"],
  ["Elat", "ETH"],
  ["Elat, Ovula", "VDA"],
  ["Elkhart (IN)", "EKI"],
  ["Elko (NV)", "EKO"],
  ["Ellisras", "ELL"],
  ["El Minya", "EMY"],
  ["Elmira (NY)", "ELM"],
  ["El Paso (TX) - El Paso International Airport", "ELP"],
  ["Ely (NV)", "ELY"],
  ["Emerald", "EDR"],
  ["Emerald", "EMD"],
  ["Enontekioe", "ENF"],
  ["Entebbe - Entebbe International Airport", "EBB"],
  ["Erfurt - Erfurt Airport (Flughafen Erfurt)", "ERF"],
  ["Erie (PA)", "ERI"],
  ["Eriwan (Yerevan, Jerevan)", "EVN"],
  ["Erzincan", "ERC"],
  ["Erzurum", "ERZ"],
  ["Esbjerg", "EBJ"],
  ["Escanaba (MI)", "ESC"],
  ["Esperance", "EPR"],
  ["Eugene (OR)", "EUG"],
  ["Eureka (CA)", "ACV"],
  ["Evansville (IN)", "EVV"],
  ["Evenes", "EVE"],
  ["Exeter", "EXT"],
  ["Fairbanks (AK)", "FAI"],
  ["Fair Isle (Shetland)", "FIE"],
  ["Faisalabad", "LYP"],
  ["Fargo (ND) (MN)", "FAR"],
  ["Farmington (NM)", "FMN"],
  ["Faro", "FAO"],
  ["Faroer - Vágar Airport", "FAE"],
  ["Fayetteville (AR)", "FYV"],
  ["Fayetteville/Ft. Bragg (NC)", "FAY"],
  ["Fes", "FEZ"],
  ["Figari", "FSC"],
  ["Flagstaff (AZ)", "FLG"],
  ["Flin Flon", "YFO"],
  ["Flint (MI)", "FNT"],
  ["Florence (Firenze) - Peretola Airport", "FLR"],
  ["Florence (SC)", "FLO"],
  ["Florianopolis", "FLN"],
  ["Floro", "FRO"],
  ["Fort Albert", "YFA"],
  ["Fortaleza - Pinto Martins Airport", "FOR"],
  ["Fort de France - Martinique Aimé Césaire International", "FDF"],
  ["Fort Dodge IA", "FOD"],
  ["Fort Huachuca/Sierra Vista (AZ)", "FHU"],
  ["Fort Lauderdale/Hollywood (FL)", "FLL"],
  ["Fort McMurray", "YMM"],
  ["Fort Myers, Metropolitan Area (FL)", "FMY"],
  ["Fort Myers, Southwest Florida Reg (FL)", "RSW"],
  ["Fort Riley (KS) - Marshall AAF", "FRI"],
  ["Fort Smith", "YSM"],
  ["Fort Smith (AR)", "FSM"],
  ["Fort St. John", "YXJ"],
  ["Fort Walton Beach (FL)", "VPS"],
  ["Fort Wayne (IN)", "FWA"],
  ["Fort Worth (TX) - Dallas/Fort Worth International Airport", "DFW"],
  ["Foula (Shetland)", "FOU"],
  ["Francistown", "FRW"],
  ["Frankfurt/Main - Frankfurt Airport (Rhein-Main-Flughafen)", "FRA"],
  ["Frankfurt/Hahn", "HHN"],
  ["Franklin/Oil City (PA)", "FKL"],
  ["Fredericton", "YFC"],
  ["Freeport - Grand Bahama International Airport", "FPO"],
  ["Freetown - Freetown-Lungi International Airport", "FNA"],
  ["Frejus", "FRJ"],
  ["Fresno (CA)", "FAT"],
  ["Friedrichshafen - Bodensee-Airport Friedrichshafen", "FDH"],
  ["Fuerteventura", "FUE"],
  ["Fujairah, International Airport", "FJR"],
  ["Fukuoka", "FUK"],
  ["Fukushima - Fukushima Airport", "FKS"],
  ["Funchal", "FNC"],
  ["Futuna", "FUT"],
  ["Gaborone - Sir Seretse Khama International Airport", "GBE"],
  ["Gadsden (AL)", "GAD"],
  ["Gainesville (FL)", "GNV"],
  ["Galway", "GWY"],
  ["Gander", "YQX"],
  ["Garoua", "GOU"],
  ["Gaza City - Gaza International Airport", "GZA"],
  ["Gaziantep", "GZT"],
  ["Gdansk", "GDN"],
  ["Geelong", "GEX"],
  ["Geneva - Geneva-Cointrin International Airport", "GVA"],
  ["Genoa", "GOA"],
  ["George", "GRJ"],
  ["Georgetown - Cheddi Jagan International Airport", "GEO"],
  ["Geraldton", "GET"],
  ["Gerona", "GRO"],
  ["Ghent (Gent)", "GNE"],
  ["Gibraltar", "GIB"],
  ["Gilette (WY)", "GCC"],
  ["Gilgit", "GIL"],
  ["Gillam", "YGX"],
  ["Gladstone", "GLT"],
  ["Glasgow, Prestwick", "PIK"],
  ["Glasgow", "GLA"],
  ["Glasgow (MT)", "GGW"],
  ["Glendive (MT)", "GDV"],
  ["Goa", "GOI"],
  ["Goiania, Santa Genoveva Airport", "GYN"],
  ["Gold Coast", "OOL"],
  ["Goondiwindi", "GOO"],
  ["Goose Bay", "YYR"],
  ["Gorna", "GOZ"],
  ["Gothenburg (Göteborg) - Landvetter", "GOT"],
  ["Gove (Nhulunbuy)", "GOV"],
  ["Govenors Harbour", "GHB"],
  ["Granada", "GRX"],
  ["Grand Bahama International", "FPO"],
  ["Grand Canyon (AZ)", "GCN"],
  ["Grand Cayman - Owen Roberts International", "GCM"],
  ["Grand Forks (ND)", "GFK"],
  ["Grand Junction (CO)", "GJT"],
  ["Grand Rapids (MI)", "GRR"],
  ["Grand Rapids (MN)", "GPZ"],
  ["Graz", "GRZ"],
  ["Great Falls (MT)", "GTF"],
  ["Great Keppel Island", "GKL"],
  ["Green Bay (WI)", "GRB"],
  ["Greenbrier/Lewisburg (WV)", "LWB"],
  ["Greensboro/Winston Salem (NC)", "GSO"],
  ["Greenville (MS)", "GLH"],
  ["Greenville (NC)", "PGV"],
  ["Greenville/Spartanburg (SC)", "GSP"],
  ["Grenada - Point Salines Airport also Maurice Bishop", "GND"],
  ["Grenoble", "GNB"],
  ["Griffith", "GFF"],
  ["Groningen - Eelde", "GRQ"],
  ["Groote Eylandt - Alyangula", "GTE"],
  ["Groton/New London (CT)", "GON"],
  ["Guadalajara", "GDL"],
  ["Guadalcanal", "GSI"],
  ["Guam", "GUM"],
  ["Guangzhou (Canton) - Baiyun International Airport", "CAN"],
  ["Sao Paulo - Guarulhos International", "GRU"],
  ["Guatemala City - La Aurora International Airport", "GUA"],
  ["Guayaquil - Simon Bolivar", "GYE"],
  ["Guernsey", "GCI"],
  ["Guettin", "GTI"],
  ["Gulfport/Biloxi (MS)", "GPT"],
  ["Guilin - Liangjiang", "KWL"],
  ["Gulu", "ULU"],
  ["Gunnison/Crested Butte (CO)", "GUC"],
  ["Guwahati", "GAU"],
  ["Gwadar", "GWD"],
  ["Gweru", "GWE"],
  ["Gympie", "GYP"],
  ["Hachijo Jima", "HAC"],
  ["Hagåtña - Guam International Airport", "GUM"],
  ["Haifa", "HFA"],
  ["Haines (AK)", "HNS"],
  ["Hakodate", "HKD"],
  ["Halifax International", "YHZ"],
  ["Hall Beach", "YUX"],
  ["Hamburg - Fuhlsbuettel", "HAM"],
  ["Hamilton", "HLT"],
  ["Hamilton", "YHM"],
  ["Hamilton", "HLZ"],
  ["Hamilton Island", "HTI"],
  ["Hammerfest", "HFT"],
  ["Hancock (MI)", "CMX"],
  ["Hangchow (Hangzhou)", "HGH"],
  ["Hannover", "HAJ"],
  ["Hanoi - Noi Bai International Airport", "HAN"],
  ["Harare - Harare International Airport", "HRE"],
  ["Harbin (Haerbin)", "HRB"],
  ["Harlingen/South Padre Island (TX)", "HRL"],
  ["Harrington Harbour, PQ", "YHR"],
  ["Harrisburg (PA) - Harrisburg Skyport", "HAR"],
  ["Harrisburg (PA) - Harrisburg International", "MDT"],
  ["Hartford (CT) /Springfield (MA)", "BDL"],
  ["Hatyai (Hat Yai)", "HDY"],
  ["Haugesund", "HAU"],
  ["Havana - José Martí International", "HAV"],
  ["Havre (MT)", "HVR"],
  ["Hayman Island", "HIS"],
  ["Helena (MT)", "HLN"],
  ["Helsingborg", "JHE"],
  ["Helsinki - Vantaa", "HEL"],
  ["Heraklion", "HER"],
  ["Hermosillo - Gen. Pesqueira Garcia", "HMO"],
  ["Hervey Bay", "HVB"],
  ["Hibbing (MN)", "HIB"],
  ["Hickory (NC)", "HKY"],
  ["Hilo (HI)", "ITO"],
  ["Hilton Head Island (SC)", "HHH"],
  ["Hinchinbrook Island", "HNK"],
  ["Hiroshima International", "HIJ"],
  ["Ho Chi Minh City (Saigon) - Tan Son Nhat International", "SGN"],
  ["Hobart", "HBA"],
  ["Hof", "HOQ"],
  ["Holguin", "HOG"],
  ["Home Hill", "HMH"],
  ["Homer (AK)", "HOM"],
  ["Hong Kong - International Airport (HKIA)", "HKG"],
  ["Hong Kong - Chek Lap Kok", "ZJK"],
  ["Honiara Henderson International", "HIR"],
  ["Honolulu (HI) - Honolulu International Airport", "HNL"],
  ["Hoonah (AK)", "HNH"],
  ["Horta", "HOR"],
  ["Houston (TX) , Hobby", "HOU"],
  ["Houston, TX - George Bush Intercontinental Airport", "IAH"],
  ["Huahine", "HUH"],
  ["Huatulco", "HUX"],
  ["Hue - Phu Bai", "HUI"],
  ["Humberside", "HUY"],
  ["Huntington (WV)", "HTS"],
  ["Huntsville (AL)", "HSV"],
  ["Hurghada International", "HRG"],
  ["Huron (SD)", "HON"],
  ["Hwange National Park", "HWN"],
  ["Hyannis (MA)", "HYA"],
  ["Hydaburg (AK)", "HYG"],
  ["Hyderabad - Rajiv Gandhi International Airport", "HYD"],
  ["Hyderabad", "HDD"],
  ["Ibiza", "IBZ"],
  ["Idaho Falls (ID)", "IDA"],
  ["Iguazu, Cataratas", "IGR"],
  ["Ile des Pins", "ILP"],
  ["Ile Ouen", "IOU"],
  ["Iliamna (AK)", "ILI"],
  ["Imperial (CA)", "IPL"],
  ["Incercargill", "IVC"],
  ["Incheon, Incheon International Airport", "ICN"],
  ["Indianapolis (IN) International", "IND"],
  ["Ingham", "IGH"],
  ["Innisfail", "IFL"],
  ["Innsbruck - Kranebitten", "INN"],
  ["International Falls (MN)", "INL"],
  ["Inuvik", "YEV"],
  ["Invercargill", "IVC"],
  ["Inverness", "INV"],
  ["Inykern (CA)", "IYK"],
  ["Iqaluit (Frobisher Bay)", "YFB"],
  ["Iquitos", "IQT"],
  ["Irkutsk", "IKT"],
  ["Ishigaki - New Ishigaki Airport", "ISG"],
  ["Islamabad - Benazir Bhutto International Airport", "ISB"],
  ["Islay", "ILY"],
  ["Isle of Man", "IOM"],
  ["Istanbul - Istanbul Atatürk Airport", "IST"],
  ["Istanbul - Sabiha Gokcen", "SAW"],
  ["Ithaca/Cortland (NY)", "ITH"],
  ["Ivalo", "IVL"],
  ["Ixtapa/Zihuatenejo", "ZIH"],
  ["Izmir", "IZM"],
  ["Izmir - Adnan Menderes Airport", "ADB"],
  ["Jackson Hole (WY)", "JAC"],
  ["Jackson (MI) - Reynolds Municipal", "JXN"],
  ["Jackson,  MN  ", "MJQ"],
  ["Jackson (MS) - Jackson Internationall", "JAN"],
  ["Jackson (MS) - Hawkins Field   ", "HKS"],
  ["Jackson (TN) - Mckellar", "MKL"],
  ["Jackson Hole (WY)", "JAC"],
  ["Jacksonville (AR)  Little Rock AFB   ", "LRF"],
  ["Jacksonville (FL) - Cecil Field NAS   ", "NZC"],
  ["Jacksonville (FL) Jacksonville NAS   ", "NIP"],
  ["Jacksonville (FL) - International", "JAX"],
  ["Jacksonville (FL) - Craig Municipal   ", "CRG"],
  ["Jacksonville (IL) - Municipal Airport", "IJX"],
  ["Jacksonville (NC)", "OAJ"],
  ["Jacksonville (TX)", "JKV"],
  ["Jacmel   ", "JAK"],
  ["Jacobabad", "JAG"],
  ["Jacobina   ", "JCM"],
  ["Jacquinot Bay", "JAQ"],
  ["Jaffna - Kankesanturai", "JAF"],
  ["Jagdalpur", "JGB"],
  ["Jaipur - Sanganeer", "JAI"],
  ["Jaisalmer   ", "JSA"],
  ["Jakarta - Halim Perdana Kusuma", "HLP"],
  ["Jakarta - Metropolitan Area", "JKT"],
  ["Jakarta - Soekarno-Hatta International", "CGK"],
  ["Jalalabad    ", "JAA"],
  ["Jalandhar", "JLR"],
  ["Jalapa", "JAL"],
  ["Jales", "JLS"],
  ["Jaluit Island  ", "UIT"],
  ["Jamba", "JMB"],
  ["Jambi - Sultan Taha Syarifudn", "DJB"],
  ["Jambol", "JAM"],
  ["Jamestown (ND)", "JMS"],
  ["Jamestown (NY)", "JHW"],
  ["Jammu - Satwari", "IXJ"],
  ["Jamnagar - Govardhanpur", "JGA"],
  ["Jamshedpur - Sonari Airport", "IXW"],
  ["Janakpur", "JKR"],
  ["Jandakot", "JAD"],
  ["Janesville (WI) - Rock County", "JVL"],
  ["Januaria", "JNA"],
  ["Jaque   ", "JQE"],
  ["Jatai", "JTI"],
  ["Jauja", "JAU"],
  ["Jayapura - Sentani", "DJJ"],
  ["Jeddah - King Abdulaziz International", "JED"],
  ["Jefferson City (MO) - Jefferson Memorial", "JEF"],
  ["Jeremie - Jeremie Airport", "JEE"],
  ["Jerez de la Frontera/Cadiz - La Parra", "XRY"],
  ["Jersey", "JER"],
  ["Jerusalem - Atarot Airport (closed)", "JRS"],
  ["Jessore - Jessore Airport", "JSR"],
  ["Jeypore - Jeypore Airport", "PYB"],
  ["Ji'an", "JGS"],
  ["Jiamusi - Jiamusi Airport", "JMU"],
  ["Jiayuguan - Jiayuguan Airport", "JGN"],
  ["Jijel", "GJL"],
  ["Jijiga", "JIJ"],
  ["Jilin", "JIL"],
  ["Jimma", "JIM"],
  ["Jinan", "TNA"],
  ["Jingdezhen", "JDZ"],
  ["Jinghong - Gasa Airport", "JHG"],
  ["Jining", "JNG"],
  ["Jinja", "JIN"],
  ["Jinjiang", "JJN"],
  ["Jinka - Baco/Jinka Airport", "BCO"],
  ["Jinzhou - Jinzhou Airport", "JNZ"],
  ["Jipijapa", "JIP"],
  ["Jiri", "JIR"],
  ["Jiujiang - Jiujiang Lushan Airport", "JIU"],
  ["Jiwani", "JIW"],
  ["Joacaba", "JCB"],
  ["Joao Pessoa - Castro Pinto Airport", "JPA"],
  ["Jodhpur", "JDH"],
  ["Jönköping (Jonkoping) - Axamo Airport", "JKG"],
  ["Joensuu", "JOE"],
  ["Johannesburg - OR Tambo International Airport", "JNB"],
  ["Johnson City (NY) - Binghamton/Endicott/Johnson", "BGM"],
  ["Johnston Island", "JON"],
  ["Johnstown (PA)", "JST"],
  ["Johor Bahru - Sultan Ismail International", "JHB"],
  ["Joinville - Cubatao Airport", "JOI"],
  ["Jolo", "JOL"],
  ["Jomsom", "JMO"],
  ["Jonesboro (AR)  Jonesboro Airport", "JBR"],
  ["Joplin (MO)", "JLN"],
  ["Jorhat - Rowriah Airport", "JRH"],
  ["Jos", "JOS"],
  ["Jose De San Martin", "JSM"],
  ["Jouf", "AJF"],
  ["Juanjui", "JJI"],
  ["Juba", "JUB"],
  ["Juist (island)", "JUI"],
  ["Juiz De Fora - Francisco De Assis Airport", "JDF"],
  ["Jujuy - El Cadillal Airport", "JUJ"],
  ["Julia Creek", "JCK"],
  ["Juliaca", "JUL"],
  ["Jumla", "JUM"],
  ["Jundah", "JUN"],
  ["Juneau (AK) - Juneau International Airport", "JNU"],
  ["Junin", "JNI"],
  ["Juticalpa", "JUT"],
  ["Jwaneng", "JWA"],
  ["Jyväskylä (Jyvaskyla)", "JYV"],
  ["Kabul - Khwaja Rawash Airport", "KBL"],
  ["Kagoshima", "KOJ"],
  ["Kahramanmaras", "KCM"],
  ["Kahului (HI)", "OGG"],
  ["Kajaani", "KAJ"],
  ["Kalamata", "KLX"],
  ["Kalamazoo/Battle Creek (MI)", "AZO"],
  ["Kalgoorlie", "KGI"],
  ["Kaliningrad", "KGD"],
  ["Kalispell (MT)", "FCA"],
  ["Kalmar", "KLR"],
  ["Kamloops, BC", "YKA"],
  ["Kamuela (HI)", "MUE"],
  ["Kano", "KAN"],
  ["Kanpur", "KNU"],
  ["Kansas City (MO) - Kansas City International Airport", "MCI"],
  ["Kaohsiung International", "KHH"],
  ["Kapalua West (HI)", "JHM"],
  ["Karachi - Jinnah International Airport", "KHI"],
  ["Karlsruhe-Baden - Soellingen", "FKB"],
  ["Karlstad", "KSD"],
  ["Karpathos", "AOK"],
  ["Karratha", "KTA"],
  ["Kars", "KYS"],
  ["Karumba", "KRB"],
  ["Karup", "KRP"],
  ["Kaschechawan, PQ", "ZKE"],
  ["Kassala", "KSL"],
  ["Katherine", "KTR"],
  ["Kathmandu - Tribhuvan International Airport", "KTM"],
  ["Katima Mulilo/Mpacha", "MPA"],
  ["Kauhajoki", "KHJ"],
  ["Kaunakakai (HI)", "MKK"],
  ["Kavalla", "KVA"],
  ["Kayseri", "ASR"],
  ["Kazan - Kazan International Airport", "KZN"],
  ["Keetmanshoop", "KMP"],
  ["Kelowna, BC", "YLW"],
  ["Kemi/Tornio", "KEM"],
  ["Kenai (AK)", "ENA"],
  ["Kent (Manston) Kent International", "MSE"],
  ["Kerry County", "KIR"],
  ["Ketchikan (AK)", "KTN"],
  ["Key West (FL)", "EYW"],
  ["Khamis Mushayat", "AHB"],
  ["Kharga - New Valley", "UVL"],
  ["Kharkov", "HRK"],
  ["Khartoum - Khartoum International Airport", "KRT"],
  ["Khuzdar", "KDD"],
  ["Kiel - Holtenau", "KEL"],
  ["Kiev - Borispol", "KBP"],
  ["Kiev - Zhulhany", "IEV"],
  ["Kigali - Gregoire Kayibanda", "KGL"],
  ["Kilimadjaro", "JRO"],
  ["Killeem (TX)", "ILE"],
  ["Kimberley", "KIM"],
  ["King Island", "KNS"],
  ["King Salomon (AK)", "AKN"],
  ["Kingscote", "KGC"],
  ["Kingston - Norman Manley", "KIN"],
  ["Kingston (NC)", "ISO"],
  ["Kingstown - E. T. Joshua Airport", "SVD"],
  ["Kinshasa - N'Djili", "FIH"],
  ["Kiritimati (island) - Cassidy International Airport", "CXI"],
  ["Kirkenes", "KKN"],
  ["Kirkuk", "KIK"],
  ["Kirkwall (Orkney)", "KOI"],
  ["Kiruna", "KRN"],
  ["Kisangani", "FKI"],
  ["Kittilä", "KTT"],
  ["Kitwe", "KIW"],
  ["Klagenfurt", "KLU"],
  ["Klamath Fall (OR)", "LMT"],
  ["Klawock (AK)", "KLW"],
  ["Kleinsee", "KLZ"],
  ["Knock", "NOC"],
  ["Knoxville (TN)", "TYS"],
  ["Kobe", "UKB"],
  ["Kochi", "KCZ"],
  ["Köln, Köln/Bonn", "CGN"],
  ["Kodiak (AK)", "ADQ"],
  ["Kohat", "OHT"],
  ["Kokkola/Pietarsaari", "KOK"],
  ["Kolkata (Calcutta) - Netaji Subhas Chandra", "CCU"],
  ["Komatsu", "KMQ"],
  ["Kona (HI)", "KOA"],
  ["Konya", "KYA"],
  ["Korhogo", "HGO"],
  ["Kos", "KGS"],
  ["Kota Kinabalu", "BKI"],
  ["Kotzbue (AK)", "OTZ"],
  ["Kowanyama", "KWM"],
  ["Krakow (Cracow) - John Paul II International Airport", "KRK"],
  ["Kristiansand", "KRS"],
  ["Kristianstad", "KID"],
  ["Kristiansund", "KSU"],
  ["Kuala Lumpur - International Airport", "KUL"],
  ["Kuala Lumpur - Sultan Abdul Aziz Shah", "SZB"],
  ["Kuantan", "KUA"],
  ["Kuching", "KCH"],
  ["Kumamoto", "KMJ"],
  ["Kununurra", "KNX"],
  ["Kuopio", "KUO"],
  ["Kushiro", "KUH"],
  ["Kuujjuaq (FortChimo)", "YVP"],
  ["Kuujjuarapik", "YGW"],
  ["Kuusamo", "KAO"],
  ["Kuwait - Kuwait International", "KWI"],
  ["Kyoto", "UKY"],
  ["Labe", "LEK"],
  ["Labouchere Bay (AK)", "WLB"],
  ["Labuan", "LBU"],
  ["Lac Brochet, MB", "XLB"],
  ["La Coruna", "LCG"],
  ["La Crosse (WI)", "LSE"],
  ["Lae - Lae Nadzab Airport", "LAE"],
  ["La Rochelle", "LRH"],
  ["Lafayette (IN)", "LAF"],
  ["Lafayette, La", "LFT"],
  ["Lagos - Murtala Muhammed Airport", "LOS"],
  ["La Grande", "YGL"],
  ["Lahore", "LHE"],
  ["Lake Charles (LA)", "LCH"],
  ["Lake Havasu City (AZ)", "HII"],
  ["Lake Tahoe (CA)", "TVL"],
  ["Lakselv", "LKL"],
  ["Lambarene", "LBQ"],
  ["Lamezia Terme", "SUF"],
  ["Lampedusa", "LMP"],
  ["Lanai City (HI)", "LNY"],
  ["Lancaster (PA)", "LNS"],
  ["Land's End", "LEQ"],
  ["Langkawi (islands)", "LGK"],
  ["Lannion", "LAI"],
  ["Lanseria", "HLA"],
  ["Lansing (MI)", "LAN"],
  ["La Paz - El Alto", "LPB"],
  ["La Paz - Leon", "LAP"],
  ["Lappeenranta", "LPP"],
  ["Laramie (WY)", "LAR"],
  ["Laredo (TX)", "LRD"],
  ["Larnaca", "LCA"],
  ["Las Palmas", "LPA"],
  ["Las Vegas (NV)", "LAS"],
  ["Latrobe (PA)", "LBE"],
  ["Launceston", "LST"],
  ["Laurel/Hattisburg (MS)", "PIB"],
  ["Laverton", "LVO"],
  ["Lawton (OK)", "LAW"],
  ["Lazaro Cardenas", "LZC"],
  ["Leaf Rapids", "YLR"],
  ["Learmouth (Exmouth)", "LEA"],
  ["Lebanon (NH)", "LEB"],
  ["Leeds/Bradford", "LBA"],
  ["Leinster", "LER"],
  ["Leipzig", "LEJ"],
  ["Lelystad", "LEY"],
  ["Leon", "BJX"],
  ["Leonora", "LNO"],
  ["Lerwick/Tingwall (Shetland Islands)", "LWK"],
  ["Lewiston (ID)", "LWS"],
  ["Lewistown (MT)", "LWT"],
  ["Lexington (KY)", "LEX"],
  ["Libreville", "LBV"],
  ["Lidkoeping", "LDK"],
  ["Liege", "LGG"],
  ["Lifou", "LIF"],
  ["Lihue (HI)", "LIH"],
  ["Lille - Lille Airport", "LIL"],
  ["Lilongwe - Lilongwe International", "LLW"],
  ["Lima - J Chavez International", "LIM"],
  ["Limassol", "QLI"],
  ["Limoges", "LIG"],
  ["Lincoln (NE)", "LNK"],
  ["Lindeman Island", "LDC"],
  ["Linz - Hoersching", "LNZ"],
  ["Lisala", "LIQ"],
  ["Lisbon - Lisboa", "LIS"],
  ["Lismore", "LSY"],
  ["Little Rock (AR)", "LIT"],
  ["Liverpool", "LPL"],
  ["Lizard Island", "LZR"],
  ["Ljubljana - Brnik", "LJU"],
  ["Lockhart River", "IRG"],
  ["Lome", "LFW"],
  ["London", "YXU"],
  ["London Metropolitan Area", "LON"],
  ["London - City Airport", "LCY"],
  ["London - Gatwick", "LGW"],
  ["London - Heathrow", "LHR"],
  ["London - Luton", "LTN"],
  ["London - Stansted", "STN"],
  ["Londonderry - Eglinton", "LDY"],
  ["Long Beach (CA)", "LGB"],
  ["Long Island (AK)", "LIJ"],
  ["Long Island, Islip (NY) - Mac Arthur", "ISP"],
  ["Longreach", "LRE"],
  ["Longview/Kilgore (TX)", "GGG"],
  ["Longyearbyen - Svalbard", "LYR"],
  ["Loreto", "LTO"],
  ["Lorient", "LRT"],
  ["Los Angeles (CA) - International", "LAX"],
  ["Los Cabos", "SJD"],
  ["Los Mochis", "LMM"],
  ["Los Rodeos", "TFN"],
  ["Losinj - Losinj Arpt", "LSZ"],
  ["Lourdes/Tarbes", "LDE"],
  ["Louisville (KY)", "SDF"],
  ["Luanda - 4 de Fevereiro", "LAD"],
  ["Lubbock (TX)", "LBB"],
  ["Lucknow", "LKO"],
  ["Luederitz", "LUD"],
  ["Luga", "MLA"],
  ["Lugano", "LUG"],
  ["Lulea", "LLA"],
  ["Lumbumbashi", "FBM"],
  ["Lusaka - Lusaka International Airport", "LUN"],
  ["Lusisiki", "LUJ"],
  ["Luxembourg", "LUX"],
  ["Luxi - Mangshi", "LUM"],
  ["Luxor", "LXR"],
  ["Lvov (Lwow, Lemberg)", "LWO"],
  ["Lydd - Lydd International Airport", "LYX"],
  ["Lynchburg (VA)", "LYH"],
  ["Lyon - Lyon-Saint Exupéry Airport official website", "LYS"],
  ["Lyons (KS) - Rice County Municipal", "LYO"],
  ["Maastricht/Aachen", "MST"],
  ["Macapa - Alberto Alcolumbre International Airport", "MCP"],
  ["Macau - Macau International Airport", "MFM"],
  ["Maceio - Zumbi dos Palmares International Airport", "MCZ"],
  ["Mackay", "MKY"],
  ["Macon (GA)", "MCN"],
  ["Mactan Island - Nab", "NOP"],
  ["Madinah (Medina) - Mohammad Bin Abdulaziz", "MED"],
  ["Madison (WI) - Dane County Regional Airport", "MSN"],
  ["Madras (Chennai) - Chennai International Airport", "MAA"],
  ["Madrid - Barajas Airport", "MAD"],
  ["Mahe - Seychelles International", "SEZ"],
  ["Mahon", "MAH"],
  ["Maitland", "MTL"],
  ["Majunga", "MJN"],
  ["Makung", "MZG"],
  ["Malabo - Malabo International Airport", "SSG"],
  ["Malaga", "AGP"],
  ["Malatya", "MLX"],
  ["Male - Velana International Airport", "MLE"],
  ["Malindi", "MYD"],
  ["Malmo (Malmoe)", "MMA"],
  ["Malmo (Malmö) - Malmö Airport", "MMX"],
  ["Man", "MJC"],
  ["Managua - Augusto C Sandino", "MGA"],
  ["Manaus - Eduardo Gomes International Airport", "MAO"],
  ["Manchester", "MAN"],
  ["Manchester (NH)", "MHT"],
  ["Mandalay - Mandalay-Annisaton Airport", "MDL"],
  ["Manguna", "MFO"],
  ["Manihi", "XMH"],
  ["Manila - Ninoy Aquino International", "MNL"],
  ["Manzanillo", "ZLO"],
  ["Manzini - Matsapha International", "MTS"],
  ["Maputo - Maputo International", "MPM"],
  ["Mar del Plata", "MDQ"],
  ["Maracaibo - La Chinita International", "MAR"],
  ["Maradi", "MFQ"],
  ["Maras", "KCM"],
  ["Marathon (FL)", "MTH"],
  ["Mardin", "MQM"],
  ["Mare", "MEE"],
  ["Margate", "MGH"],
  ["Margerita", "PMV"],
  ["Maribor", "MBX"],
  ["Mariehamn (Maarianhamina)", "MHQ"],
  ["Maroua", "MVR"],
  ["Marquette (MI)", "MQT"],
  ["Marrakesh - Menara Airport", "RAK"],
  ["Marsa Alam", "RMF"],
  ["Marsa Matrah (Marsa Matruh)", "MUH"],
  ["Marseille - Marseille Provence Airport", "MRS"],
  ["Marsh Harbour", "MHH"],
  ["Martha's Vineyard (MA)", "MVY"],
  ["Martinsburg (WV)", "MRB"],
  ["Maryborough", "MBH"],
  ["Maseru - Moshoeshoe International", "MSU"],
  ["Mason City IA", "MCW"],
  ["Masvingo", "MVZ"],
  ["Matsumoto, Nagano", "MMJ"],
  ["Matsuyama", "MYJ"],
  ["Mattoon (IL)", "MTO"],
  ["Maun", "MUB"],
  ["Maupiti", "MAU"],
  ["Mauritius - S.Seewoosagur Ram International", "MRU"],
  ["Mayaguez", "MAZ"],
  ["Mazatlan", "MZT"],
  ["McAllen (TX)", "MFE"],
  ["Medan - Polania Int'l (now Soewondo AFB)", "MES"],
  ["Medan - Kualanamu International", "KNO"],
  ["Medellin - José María Córdova International", "MDE"],
  ["Medford (OR)", "MFR"],
  ["Medina", "MED"],
  ["Meekatharra", "MKR"],
  ["Melbourne - Melbourne Airport (Tullamarine)", "MEL"],
  ["Melbourne (FL)", "MLB"],
  ["Melville Hall", "DOM"],
  ["Memphis (TN)", "MEM"],
  ["Mendoza", "MDZ"],
  ["Manado", "MDC"],
  ["Merced (CA)", "MCE"],
  ["Merida", "MID"],
  ["Meridian (MS)", "MEI"],
  ["Merimbula", "MIM"],
  ["Messina", "MEZ"],
  ["Metlakatla (AK)", "MTM"],
  ["Metz -  Frescaty", "MZM"],
  ["Metz/Nancy Metz-Nancy-Lorraine", "ETZ"],
  ["Mexicali", "MXL"],
  ["Mexico City - Mexico City International Airport", "MEX"],
  ["Mexico City - Atizapan", "AZP"],
  ["Mexico City - Juarez International", "MEX"],
  ["Mexico City - Santa Lucia", "NLU"],
  ["Mfuwe", "MFU"],
  ["Miami (FL)", "MIA"],
  ["Mianwali", "MWD"],
  ["Middlemount", "MMM"],
  ["Midland/Odessa (TX)", "MAF"],
  ["Midway Island - Sand Island Field", "MDY"],
  ["Mikkeli", "MIK"],
  ["Milan", "MIL"],
  ["Milan - Linate", "LIN"],
  ["Milan - Malpensa", "MXP"],
  ["Milan - Orio Al Serio", "BGY"],
  ["Mildura", "MQL"],
  ["Miles City (MT)", "MLS"],
  ["Milford Sound", "MFN"],
  ["Milwaukee (WI)", "MKE"],
  ["Minatitlan", "MTT"],
  ["Mineralnye Vody", "MRV"],
  ["Minneapolis - St. Paul International Airport (MN)", "MSP"],
  ["Minot (ND)", "MOT"],
  ["Minsk, International", "MSQ"],
  ["Miri", "MYY"],
  ["Mirpur", "QML"],
  ["Missula (MT)", "MSO"],
  ["Mitchell (SD)", "MHE"],
  ["Miyako Jima (Ryuku Islands) - Hirara", "MMY"],
  ["Miyazaki", "KMI"],
  ["Mkambati", "MBM"],
  ["Moanda", "MFF"],
  ["Mobile (AL) - Pascagoula (MS)", "MOB"],
  ["Modesto (CA)", "MOD"],
  ["Moenjodaro", "MJD"],
  ["Mogadishu", "MGQ"],
  ["Mokuti", "OKU"],
  ["Moline/Quad Cities (IL)", "MLI"],
  ["Mombasa - Moi International", "MBA"],
  ["Monastir", "MIR"],
  ["Moncton", "YQM"],
  ["Monroe (LA)", "MLU"],
  ["Monrovia - Metropolitan Area", "MLW"],
  ["Monrovia - Roberts International", "ROB"],
  ["Montego Bay - Sangster International", "MBJ"],
  ["Montenegro", "QGF"],
  ["Monterey (CA)", "MRY"],
  ["Monterrey - Gen. Mariano Escobedo", "MTY"],
  ["Monterrey - Aeropuerto Del Norte", "NTR"],
  ["Montevideo - Carrasco International Airport", "MVD"],
  ["Montgomery (AL) - Montgomery Regional Airport", "MGM"],
  ["Montpellier - Montpellier–Méditerranée Airport", "MPL"],
  ["Montreal", "YMQ"],
  ["Montreal - Dorval (Montréal-Trudeau)", "YUL"],
  ["Montreal - Mirabel", "YMX"],
  ["Montrose/Tellruide (CO)", "MTJ"],
  ["Moorea", "MOZ"],
  ["Moranbah", "MOV"],
  ["Moree", "MRZ"],
  ["Morelia", "MLM"],
  ["Morgantown (WV)", "MGW"],
  ["Morioka, Hanamaki", "HNA"],
  ["Moroni - Prince Said Ibrahim", "HAH"],
  ["Moruya", "MYA"],
  ["Moscow - Metropolitan Area", "MOW"],
  ["Moscow - Domodedovo", "DME"],
  ["Moscow - Sheremetyevo", "SVO"],
  ["Moscow - Vnukovo", "VKO"],
  ["Moses Lake (WA)", "MWH"],
  ["Mossel Bay", "MZY"],
  ["Mostar", "OMO"],
  ["Mosul", "OSM"],
  ["Mouila", "MJL"],
  ["Moundou", "MQQ"],
  ["Mount Cook", "GTN"],
  ["Mount Gambier", "MGB"],
  ["Mount Magnet", "MMG"],
  ["Mt. Isa", "ISA"],
  ["Mt. McKinley (AK)", "MCL"],
  ["Muenchen (Munich) - Franz Josef Strauss", "MUC"],
  ["Muenster/Osnabrueck", "FMO"],
  ["Mulhouse", "MLH"],
  ["Multan", "MUX"],
  ["Murcia", "MJV"],
  ["Murmansk", "MMK"],
  ["Mus", "MSR"],
  ["Muscat - Seeb", "MCT"],
  ["Muscle Shoals (AL)", "MSL"],
  ["Muskegon (MI)", "MKG"],
  ["Muzaffarabad", "MFG"],
  ["Mvengue", "MVB"],
  ["Mykonos", "JMK"],
  ["Myrtle Beach (SC) - Myrtle Beach AFB", "MYR"],
  ["Myrtle Beach (SC) - Grand Strand Airport", "CRE"],
  ["Mysore", "MYQ"],
  ["Mytilene (Lesbos)", "MJT"],
  ["Mzamba", "MZF"],
  ["Nadi", "NAN"],
  ["Nagasaki", "NGS"],
  ["Nagoya - Komaki AFB", "NGO"],
  ["Nagpur", "NAG"],
  ["Nairobi", "NBO"],
  ["Nakhichevan", "NAJ"],
  ["Nakhon Si Thammarat", "NST"],
  ["Nancy", "ENC"],
  ["Nanisivik", "YSR"],
  ["Nanning", "NNG"],
  ["Nantes - Nantes Atlantique Airport", "NTE"],
  ["Nantucket (MA)", "ACK"],
  ["Naples - Naples Capodichino Airport", "NAP"],
  ["Naples (FL)", "APF"],
  ["Narrabri", "NAA"],
  ["Narrandera", "NRA"],
  ["Narsarsuaq", "UAK"],
  ["Nashville (TN)", "BNA"],
  ["Nassau", "NAS"],
  ["Natal - Augusto Severo International Airport", "NAT"],
  ["Nausori", "SUV"],
  ["Nawab Shah", "WNS"],
  ["Naxos - Naxos Airport", "JNX"],
  ["N'Djamena", "NDJ"],
  ["N'Dola", "NLA"],
  ["Nelson", "NSN"],
  ["Nelspruit", "NLP"],
  ["Nelspruit - Kruger Mpumalanga International Airport", "MQP"],
  ["Nevis", "NEV"],
  ["New Bern (NC)", "EWN"],
  ["New Haven (CT)", "HVN"],
  ["New Orleans, La", "MSY"],
  ["Newquay", "NQY"],
  ["New Valley - Kharga", "UVL"],
  ["New York - John F. Kennedy (NY)", "JFK"],
  ["New York - LaGuardia (NY)", "LGA"],
  ["New York - Newark (NJ)", "EWR"],
  ["New York (NY)", "NYC"],
  ["Newburgh (NY)", "SWF"],
  ["Newcastle - Belmont", "BEO"],
  ["Newcastle - Williamtown", "NTL"],
  ["Newcastle", "NCL"],
  ["Newcastle", "NCS"],
  ["Newman", "ZNE"],
  ["Newport News/Williamsburg (VA)", "PHF"],
  ["N'Gaoundere", "NGE"],
  ["Niagara Falls International", "IAG"],
  ["Niamey", "NIM"],
  ["Nice - Cote D'Azur Airport", "NCE"],
  ["Nicosia", "NIC"],
  ["Nikolaev", "NLV"],
  ["Niigata", "KIJ"],
  ["Nimes", "FNI"],
  ["Nis", "INI"],
  ["Nizhny Novgorod - Strigino International Airport", "GOJ"],
  ["Nome (AK)", "OME"],
  ["Noosa", "NSA"],
  ["Norfolk Island", "NLK"],
  ["Norfolk/Virginia Beach (VA)", "ORF"],
  ["Norman Wells", "YVQ"],
  ["Norrkoeping", "NRK"],
  ["North Bend (OR)", "OTH"],
  ["North Eleuthera", "ELH"],
  ["Norwich", "NWI"],
  ["Nottingham - East Midlands", "EMA"],
  ["Nouadhibou", "NDB"],
  ["Nouakchott", "NKC"],
  ["Noumea", "NOU"],
  ["Novi Sad", "QND"],
  ["Novosibirsk - Tolmachevo Airport", "OVB"],
  ["Nürnberg (Nuremberg)", "NUE"],
  ["Nuevo Laredo", "NLD"],
  ["Nuku'alofa - Fua'Amotu International", "TBU"],
  ["Oakland (CA)", "OAK"],
  ["Oaxaca - Xoxocotlan", "OAX"],
  ["Odense", "ODE"],
  ["Odessa", "ODS"],
  ["Oerebro", "ORB"],
  ["Ohrid", "OHD"],
  ["Oita", "OIT"],
  ["Okayama", "OKJ"],
  ["Okinawa, Ryukyo Island - Naha", "OKA"],
  ["Oklahoma City (OK) - Will Rogers World", "OKC"],
  ["Olbia", "OLB"],
  ["Olympic Dam", "OLP"],
  ["Omaha (NE)", "OMA"],
  ["Ondangwa", "OND"],
  ["Ontario (CA)", "ONT"],
  ["Oran (Ouahran)", "ORN"],
  ["Orange", "OAG"],
  ["Orange County (Santa Ana) (CA)", "SNA"],
  ["Oranjemund", "OMD"],
  ["Oranjestad - Reina Beatrix International", "AUA"],
  ["Orkney - Kirkwall", "KOI"],
  ["Orlando Metropolitan Area (FL)", "ORL"],
  ["Orlando - International Airport (FL)", "MCO"],
  ["Orpheus Island", "ORS"],
  ["Osaka, Metropolitan Area", "OSA"],
  ["Osaka - Itami", "ITM"],
  ["Osaka - Kansai International Airport", "KIX"],
  ["Oshkosh (WI)", "OSH"],
  ["Osijek", "OSI"],
  ["Oslo - Oslo Airport, Gardermoen", "OSL"],
  ["Oslo - Fornebu", "FBU"],
  ["Oslo - Sandefjord", "TRF"],
  ["Ottawa - Hull", "YOW"],
  ["Ouadda", "ODA"],
  ["Ouarzazate", "OZZ"],
  ["Oudtshoorn", "OUH"],
  ["Ouagadougou", "OUA"],
  ["Oujda", "OUD"],
  ["Oulu", "OUL"],
  ["Out Skerries (Shetland)", "OUK"],
  ["Oviedo", "OVD"],
  ["Owensboro (KY)", "OWB"],
  ["Oxnard (CA)", "OXR"],
  ["Oyem", "UVE"],
  ["Paderborn/Lippstadt", "PAD"],
  ["Paducah (KY)", "PAH"],
  ["Page/Lake Powell (AZ)", "PGA"],
  ["Pago Pago", "PPG"],
  ["Pakersburg (WV) /Marietta (OH)", "PKB"],
  ["Palermo - Punta Raisi", "PMO"],
  ["Palma de Mallorca", "PMI"],
  ["Palmas", "PMW"],
  ["Palmdale/Lancaster (CA)", "PMD"],
  ["Palmerston North", "PMR"],
  ["Palm Springs (CA)", "PSP"],
  ["Panama City - Tocumen International", "PTY"],
  ["Panama City (FL)", "PFN"],
  ["Panjgur", "PJG"],
  ["Pantelleria", "PNL"],
  ["Papeete - Faaa", "PPT"],
  ["Paphos", "PFO"],
  ["Paraburdoo", "PBO"],
  ["Paramaribo - Zanderij International", "PBM"],
  ["Paris", "PAR"],
  ["Paris - Charles de Gaulle", "CDG"],
  ["Paris - Le Bourget", "LBG"],
  ["Paris - Orly", "ORY"],
  ["Paro", "PBH"],
  ["Pasco (WA)", "PSC"],
  ["Pasni", "PSI"],
  ["Patna", "PAT"],
  ["Pattaya", "PYX"],
  ["Pau", "PUF"],
  ["Pellston (MI)", "PLN"],
  ["Penang International", "PEN"],
  ["Pendelton (OR)", "PDT"],
  ["Pensacola (FL)", "PNS"],
  ["Peoria/Bloomington (IL)", "PIA"],
  ["Pereira", "PEI"],
  ["Perpignan", "PGF"],
  ["Perth International", "PER"],
  ["Perugia", "PEG"],
  ["Pescara", "PSR"],
  ["Peshawar", "PEW"],
  ["Petersburg (AK)", "PSG"],
  ["Phalaborwa", "PHW"],
  ["Philadelphia (PA) - International", "PHL"],
  ["Phnom Penh - Pochentong", "PNH"],
  ["Phoenix (AZ) - Sky Harbor International", "PHX"],
  ["Phuket", "HKT"],
  ["Pierre (SD)", "PIR"],
  ["Pietermaritzburg", "PZB"],
  ["Pietersburg", "PTG"],
  ["Pilanesberg/Sun City", "NTY"],
  ["Pisa - Galileo Galilei", "PSA"],
  ["Pittsburgh International Airport (PA)", "PIT"],
  ["Plattsburgh (NY)", "PLB"],
  ["Plettenberg Bay", "PBZ"],
  ["Pocatello (ID)", "PIH"],
  ["Podgorica", "TGD"],
  ["Pohnpei", "PNI"],
  ["Pointe a Pitre", "PTP"],
  ["Pointe Noire", "PNR"],
  ["Poitiers - Biard", "PIS"],
  ["Ponce", "PSE"],
  ["Ponta Delgada", "PDL"],
  ["Pori", "POR"],
  ["Port Angeles (WA)", "CLM"],
  ["Port au Prince", "PAP"],
  ["Port Augusta", "PUG"],
  ["Port Elizabeth", "PLZ"],
  ["Port Gentil", "POG"],
  ["Port Harcourt", "PHC"],
  ["Port Hedland", "PHE"],
  ["Portland", "PTJ"],
  ["Portland (ME)", "PWM"],
  ["Portland International (OR)", "PDX"],
  ["Port Lincoln", "PLO"],
  ["Port Macquarie", "PQQ"],
  ["Port Menier, PQ", "YPN"],
  ["Port Moresby - Jackson Field", "POM"],
  ["Porto", "OPO"],
  ["Porto Alegre - Salgado Filho International Airport", "POA"],
  ["Port of Spain - Piarco International", "POS"],
  ["Port Said", "PSD"],
  ["Porto Santo", "PXO"],
  ["Porto Velho", "PVH"],
  ["Port Vila", "VLI"],
  ["Poughkeepsie (NY)", "POU"],
  ["Poznan, Lawica", "POZ"],
  ["Prague - Václav Havel Airport (formerly Ruzyne)", "PRG"],
  ["Praia - Nelson Mandela International Airport", "RAI"],
  ["Presque Island (ME)", "PQI"],
  ["Pretoria - Wonderboom Apt.", "PRY"],
  ["Preveza/Lefkas", "PVK"],
  ["Prince George", "YXS"],
  ["Prince Rupert/Digby Island", "YPR"],
  ["Pristina", "PRN"],
  ["Prosperpine", "PPP"],
  ["Providence (RI)", "PVD"],
  ["Prudhoe Bay (AK)", "SCC"],
  ["Puebla", "PBC"],
  ["Pueblo (CO)", "PUB"],
  ["Puerto Escondido", "PXM"],
  ["Puerto Ordaz", "PZO"],
  ["Puerto Plata", "POP"],
  ["Puerto Vallarta", "PVR"],
  ["Pukatawagan", "XPK"],
  ["Pula", "PUY"],
  ["Pullman (WA)", "PUW"],
  ["Pune", "PNQ"],
  ["Punta Arenas - Presidente Carlos Ibáñez del Campo", "PUQ"],
  ["Punta Cana - Punta Cana International", "PUJ"],
  ["Pu San (Busan) - Gimhae International Airport", "PUS"],
  ["Pyongyang - Sunan International Airport", "FNJ"],
  ["Quebec - Quebec International", "YQB"],
  ["Queenstown", "UEE"],
  ["Queenstown", "ZQN"],
  ["Quetta", "UET"],
  ["Qingdao", "TAO"],
  ["Quimper", "UIP"],
  ["Quincy (IL)", "UIN"],
  ["Quito - Mariscal Sucre International Airport", "UIO"],
  ["Rabat - Rabat-Salé Airport", "RBA"],
  ["Rahim Yar Khan - Shaikh Zayed International Airport", "RYK"],
  ["Raiatea", "RFP"],
  ["Rainbow Lake, AB", "YOP"],
  ["Rajkot", "RAJ"],
  ["Raleigh/Durham (NC)", "RDU"],
  ["Ranchi", "IXR"],
  ["Rangiroa", "RGI"],
  ["Rangoon (Yangon) - Mingaladon", "RGN"],
  ["Rapid City (SD)", "RAP"],
  ["Rarotonga", "RAR"],
  ["Ras al Khaymah (Ras al Khaimah)", "RKT"],
  ["Rawala Kot", "RAZ"],
  ["Rawalpindi", "RWP"],
  ["Reading (PA)", "RDG"],
  ["Recife - Guararapes-Gilberto Freyre International", "REC"],
  ["Redding (CA)", "RDD"],
  ["Redmond (OR)", "RDM"],
  ["Reggio Calabria", "REG"],
  ["Regina", "YQR"],
  ["Reina Sofia", "TFS"],
  ["Rennes", "RNS"],
  ["Reno (NV)", "RNO"],
  ["Resolute Bay", "YRB"],
  ["Reus", "REU"],
  ["Reykjavik - Metropolitan Area", "REK"],
  ["Reykjavik - Keflavik International", "KEF"],
  ["Rhinelander (WI)", "RHI"],
  ["Rhodos", "RHO"],
  ["Richards Bay", "RCB"],
  ["Richmond (VA)", "RIC"],
  ["Riga", "RIX"],
  ["Rijeka", "RJK"],
  ["Rimini", "RMI"],
  ["Rio Branco - Plácido de Castro International Airport", "RBR"],
  ["Rio de Janeiro - Galeao", "GIG"],
  ["Rio de Janeiro - Santos Dumont", "SDU"],
  ["Rio de Janeiro", "RIO"],
  ["Riyadh - King Khaled International", "RUH"],
  ["Roanne", "RNE"],
  ["Roanoke (VA)", "ROA"],
  ["Roatan", "RTB"],
  ["Rochester (MN)", "RST"],
  ["Rochester (NY)", "ROC"],
  ["Rock Sound", "RSD"],
  ["Rock Springs (WY)", "RKS"],
  ["Rockford (IL)", "RFD"],
  ["Rockhampton", "ROK"],
  ["Rockland (ME)", "RKD"],
  ["Rocky Mount - Wilson (NC)", "RWI"],
  ["Rodez", "RDZ"],
  ["Rodrigues Island", "RRG"],
  ["Roenne", "RNN"],
  ["Rome", "ROM"],
  ["Rome - Ciampino", "CIA"],
  ["Rome - Fuimicino", "FCO"],
  ["Ronneby", "RNB"],
  ["Rosario", "ROS"],
  ["Rostov-on-Don - Rostov-on-Don Airport", "RVI"],
  ["Rostov-on-Don - Platov International Airport", "ROV"],
  ["Rotorua", "ROT"],
  ["Rotterdam", "RTM"],
  ["Rovaniemi", "RVN"],
  ["Rundu", "NDU"],
  ["Ruse", "ROU"],
  ["Saarbruecken", "SCN"],
  ["Sacramento (CA)", "SMF"],
  ["Sado Shima", "SDS"],
  ["Saginaw/Bay City/Midland (MI)", "MBS"],
  ["Saidu Sharif", "SDT"],
  ["Saigon (Ho Chi Minh City) - Tan Son Nhat International", "SGN"],
  ["Saint Brieuc", "SBK"],
  ["Saint Denis - Roland Garros Airport", "RUN"],
  ["Saint John", "YSJ"],
  ["Saipan", "SPN"],
  ["Sal", "SID"],
  ["Salalah", "SLL"],
  ["Salem (OR)", "SLE"],
  ["Salinas (CA)", "SNS"],
  ["Salinas", "SNC"],
  ["Salisbury", "SAY"],
  ["Salisbury (MD)", "SBY"],
  ["Saloniki", "SKG"],
  ["Salta, Gen Belgrano", "SLA"],
  ["Salt Lake City (UT)", "SLC"],
  ["Salvador - Salvador International Airport", "SSA"],
  ["Salzburg - W.A. Mozart", "SZG"],
  ["Samara - Kurumoch International Airport", "KUF"],
  ["Samarkand - Samarkand International Airport", "SKD"],
  ["Samos", "SMI"],
  ["Samsun", "SZF"],
  ["San Andres", "ADZ"],
  ["San Angelo (TX)", "SJT"],
  ["San Antonio (TX)", "SAT"],
  ["San Carlos de Bariloche", "BRC"],
  ["San Diego - Lindberg Field International (CA)", "SAN"],
  ["San Francisco - International Airport, SA", "SFO"],
  ["San Jose Cabo", "SJD"],
  ["San Jose", "SJO"],
  ["San Jose (CA)", "SJC"],
  ["San Juan - Luis Munoz Marin International Airport", "SJU"],
  ["San Luis Obisco (CA)", "SBP"],
  ["San Luis Potosi", "SLP"],
  ["San Pedro", "SPY"],
  ["San Pedro Sula", "SAP"],
  ["San Salvador", "ZSA"],
  ["San Salvador", "SAL"],
  ["San Sebastian", "EAS"],
  ["Sanaa (Sana'a) - Sana'a International", "SAH"],
  ["Sandspit", "YZP"],
  ["Santa Ana - John Wayne Airport (CA)", "SNA"],
  ["Santa Barbara (CA)", "SBA"],
  ["Santa Cruz de la Palma", "SPC"],
  ["Santa Cruz de la Sierra", "SRZ"],
  ["Santa Katarina - Mount Sinai", "SKV"],
  ["Santa Maria", "SMA"],
  ["Santa Maria (CA)", "SMX"],
  ["Santander", "SDR"],
  ["Santa Rosa (CA)", "STS"],
  ["Santa Rosa", "SRB"],
  ["Santa Rosa", "SRA"],
  ["Santa Rosa", "RSA"],
  ["Santa Rosa, Copan", "SDH"],
  ["Santa Rosalia", "SSL"],
  ["Santa Rosalia", "SRL"],
  ["Santiago - Antonio Maceo Airport", "SCU"],
  ["Santiago de Chile - Arturo Merino Benitez", "SCL"],
  ["Santiago de Compostela", "SCQ"],
  ["Santo", "SON"],
  ["Santo Domingo", "SDQ"],
  ["Sao Luis - Marechal Cunha Machado International", "SLZ"],
  ["Sao Paulo", "SAO"],
  ["Sao Paulo - Congonhas", "CGH"],
  ["Sao Paulo - Guarulhos", "GRU"],
  ["Sao Paulo - Viracopos", "VCP"],
  ["Sao Tome", "TMS"],
  ["Sapporo", "SPK"],
  ["Sapporo - Okadama", "OKD"],
  ["Sapporo - New Chitose Airport", "CTS"],
  ["Sarajevo", "SJJ"],
  ["Saransk - Saransk Airport", "SKX"],
  ["Sarasota/Bradenton (FL)", "SRQ"],
  ["Saskatoon", "YXE"],
  ["Sassandra", "ZSS"],
  ["Savannah (GA)", "SAV"],
  ["Savonlinna", "SVL"],
  ["Scarborough - Crown Point International", "TAB"],
  ["Scone", "NSO"],
  ["Scottsdale (AZ)", "SCF"],
  ["Seattle/Tacoma (WA)", "SEA"],
  ["Sehba", "SEB"],
  ["Seinaejoki", "SJY"],
  ["Selibi Phikwe", "PKW"],
  ["Sendai", "SDJ"],
  ["Seoul - Incheon International Airport", "ICN"],
  ["Seoul - Kimpo", "SEL"],
  ["Sevilla", "SVQ"],
  ["Sfax", "SFA"],
  ["Shamattawa, MB", "ZTM"],
  ["Shanghai - Hongqiao", "SHA"],
  ["Shanghai - Pu Dong", "PVG"],
  ["Shannon (Limerick)", "SNN"],
  ["Sharjah", "SHJ"],
  ["Sharm El Sheikh", "SSH"],
  ["Sheffield, City Airport", "SZD"],
  ["Sheffield, Doncaster, Robin Hood International Airport", "DSA"],
  ["Shenandoah Valley/Stauton (VA)", "SHD"],
  ["Shenyang", "SHE"],
  ["Shenzhen - Shenzhen Bao'an International", "SZX"],
  ["Sheridan (WY)", "SHR"],
  ["Shreveport, La", "SHV"],
  ["Shute Harbour", "JHQ"],
  ["Sibu", "SBW"],
  ["Sidney (MT)", "SDY"],
  ["Silistra", "SLS"],
  ["Simferopol", "SIP"],
  ["Sindhri", "MPD"],
  ["Singapore - Changi", "SIN"],
  ["Singapore - Paya Lebar", "QPG"],
  ["Singapore - Seletar", "XSP"],
  ["Singleton", "SIX"],
  ["Sioux City IA", "SUX"],
  ["Sioux Falls (SD)", "FSD"],
  ["Sishen", "SIS"],
  ["Sitka (AK)", "SIT"],
  ["Sivas", "VAS"],
  ["Siwa", "SEW"],
  ["Skagway (AK)", "SGY"],
  ["Skardu", "KDU"],
  ["Skiathos", "JSI"],
  ["Skopje", "SKP"],
  ["Skrydstrup", "SKS"],
  ["Skukuza", "SZK"],
  ["Sligo", "SXL"],
  ["Smithers", "YYD"],
  ["Sodankylae", "SOT"],
  ["Soenderborg", "SGD"],
  ["Soendre Stroemfjord", "SFJ"],
  ["Sofia - Vrazhdebna", "SOF"],
  ["Sogndal", "SOG"],
  ["Southampton - Eastleigh", "SOU"],
  ["South Bend (IN)", "SBN"],
  ["South Indian Lake, MB", "XSI"],
  ["South Molle Island", "SOI"],
  ["Southend (London)", "SEN"],
  ["Split", "SPU"],
  ["Spokane (WA)", "GEG"],
  ["Springbok", "SBU"],
  ["Springfield (IL)", "SPI"],
  ["Springfield (MO)", "SGF"],
  ["Srinagar", "SXR"],
  ["St. Augustin, PQ", "YIF"],
  ["St. Croix", "STX"],
  ["St. Etienne", "EBU"],
  ["St. George (UT)", "SGU"],
  ["St. John's", "YYT"],
  ["St. Kitts", "SKB"],
  ["St. Louis (MO) Lambert–St. Louis International Airport", "STL"],
  ["St. Lucia Hewanorra", "UVF"],
  ["St. Lucia Vigle", "SLU"],
  ["St. Marteen", "SXM"],
  ["St. Martin", "SFG"],
  ["St. Petersburg (Leningrad) - Pulkovo", "LED"],
  ["St. Pierre, NF", "FSP"],
  ["St. Thomas", "STT"],
  ["St. Vincent", "SVD"],
  ["Stansted (London)", "STN"],
  ["State College/Belefonte (PA)", "SCE"],
  ["Stavanger", "SVG"],
  ["Steamboat Springs (CO)", "HDN"],
  ["Stettin", "SZZ"],
  ["Stockholm Metropolitan Area", "STO"],
  ["Stockholm - Arlanda", "ARN"],
  ["Stockholm - Bromma", "BMA"],
  ["Stockton (CA)", "SCK"],
  ["Stornway", "SYY"],
  ["Strasbourg - Strasbourg Airport", "SXB"],
  ["Streaky Bay", "KBY"],
  ["Stuttgart - Echterdingen", "STR"],
  ["Sui", "SUL"],
  ["Sukkur", "SKZ"],
  ["Sumburgh (Shetland)", "LSI"],
  ["Sun Valley (ID)", "SUN"],
  ["Sundsvall", "SDL"],
  ["Sunshine Coast", "MCY"],
  ["Surabaya - Juanda", "SUB"],
  ["Surat", "STV"],
  ["Suva - Nausori Airport (Luvuluvu)", "SUV"],
  ["Swakopmund", "SWP"],
  ["Sydney - Sydney Airport", "SYD"],
  ["Sylhet", "ZYL"],
  ["Syracuse (NY)", "SYR"],
  ["Tabuk", "TUU"],
  ["Taif", "TIF"],
  ["Taipei - Chiang Kai Shek", "TPE"],
  ["Taipei - Sung Shan", "TAY"],
  ["Taiyuan", "TYN"],
  ["Takamatsu", "TAK"],
  ["Talkeetna (AK)", "TKA"],
  ["Tallahassee (FL)", "TLH"],
  ["Tallinn - Pirita Harbour", "QUF"],
  ["Tallinn - Ulemiste", "TLL"],
  ["Tampa - International (FL)", "TPA"],
  ["Tampere", "TMP"],
  ["Tampico - Gen. F. Javier Mina", "TAM"],
  ["Tamworth", "TMW"],
  ["Tangier - Boukhalef", "TNG"],
  ["Taree", "TRO"],
  ["Targovishte", "TGV"],
  ["Tashkent - International", "TAS"],
  ["Tawau", "TWU"],
  ["Tbilisi - Novo Alexeyevka", "TBS"],
  ["Te Anau", "TEU"],
  ["Teesside, Durham Tees Valley", "MME"],
  ["Tegucigalpa", "TGU"],
  ["Tehran (Teheran) - Mehrabad", "THR"],
  ["Tekirdag - Corlu", "TEQ"],
  ["Tel Aviv - Ben Gurion International", "TLV"],
  ["Telluride (CO)", "TEX"],
  ["Temora", "TEM"],
  ["Tenerife", "TCI"],
  ["Tenerife - Sur Reina Sofia", "TFS"],
  ["Tenerife - Norte Los Rodeos", "TFN"],
  ["Tennant Creek", "TCA"],
  ["Terceira", "TER"],
  ["Teresina", "THE"],
  ["Termez (Termes)", "TMZ"],
  ["Terrace", "YXT"],
  ["Terre Haute (IN)", "HUF"],
  ["Texarkana (AR)", "TXK"],
  ["Thaba'Nchu", "TCU"],
  ["The Pas", "YQD"],
  ["Thessaloniki - Makedonia Apt.", "SKG"],
  ["Thief River Falls (MN)", "TVF"],
  ["Thira", "JTR"],
  ["Thiruvananthapuram", "TRV"],
  ["Thisted", "TED"],
  ["Thompson", "YTH"],
  ["Thorne Bay (AK)", "KTB"],
  ["Thunder Bay", "YQT"],
  ["Thursday Island", "TIS"],
  ["Tianjin", "TSN"],
  ["Tijuana - Rodriguez", "TIJ"],
  ["Tioman", "TOD"],
  ["Tirana - Rinas", "TIA"],
  ["Tiruchirapally", "TRZ"],
  ["Tivat", "TIV"],
  ["Tobago", "TAB"],
  ["Tokushima", "TKS"],
  ["Tokyo", "TYO"],
  ["Tokyo - Haneda", "HND"],
  ["Tokyo - Narita", "NRT"],
  ["Toledo (OH)", "TOL"],
  ["Tom Price", "TPR"],
  ["Toowoomba", "TWB"],
  ["Toronto - Billy Bishop Toronto City Airport", "YTZ"],
  ["Toronto - Toronto Pearson International Airport", "YYZ"],
  ["Toronto", "YTO"],
  ["Tortola", "TOV"],
  ["Touho", "TOU"],
  ["Toulouse - Blagnac Airport", "TLS"],
  ["Townsville", "TSV"],
  ["Toyama", "TOY"],
  ["Trabzon", "TZX"],
  ["Trapani", "TPS"],
  ["Traverse City (MI)", "TVC"],
  ["Treasure Cay", "TCB"],
  ["Trenton/Princeton (NJ)", "TTN"],
  ["Treviso", "TSF"],
  ["Tri-Cities Regional (TN) /VA", "TRI"],
  ["Trieste", "TRS"],
  ["Tripoli - Tripoli International", "TIP"],
  ["Tromsoe", "TOS"],
  ["Trondheim", "TRD"],
  ["Tsumeb", "TSB"],
  ["Tucson (AZ)", "TUS"],
  ["Tulepo (MS)", "TUP"],
  ["Tulsa (OK)", "TUL"],
  ["Tunis - Carthage", "TUN"],
  ["Turbat", "TUK"],
  ["Turin", "TRN"],
  ["Turku", "TKU"],
  ["Tuscaloosa (AL)", "TCL"],
  ["Tuxtla Gutierrez", "TGZ"],
  ["Twin Falls (ID)", "TWF"],
  ["Tyler (TX)", "TYR"],
  ["Ua Huka", "UAH"],
  ["Ua Pou", "UAP"],
  ["Ube", "UBJ"],
  ["Uberaba", "UBA"],
  ["Uberlandia - Eduardo Gomes Airport", "UDI"],
  ["Ubon Ratchathani - Muang Ubon Airport", "UBP"],
  ["Udaipur - Dabok Airport", "UDR"],
  ["Uden - Volkel Airport", "UDE"],
  ["Udon Thani", "UTH"],
  ["Ufa", "UFA"],
  ["Uherske Hradiste", "UHE"],
  ["Uige", "UGO"],
  ["Ujung Pandang - Hasanudin Airport", "UPG"],
  ["Ukhta", "UCT"],
  ["Ukiah (CA)", "UKI"],
  ["Ulaanbaatar - Buyant Uhaa Airport", "ULN"],
  ["Ulan-Ude", "UUD"],
  ["Ulanhot", "HLH"],
  ["Ulei", "ULB"],
  ["Ulsan", "USN"],
  ["Ulundi", "ULD"],
  ["Umea", "UME"],
  ["Umiujaq", "YUD"],
  ["Umtata", "UTT"],
  ["Unalakleet (AK)", "UNK"],
  ["Union Island", "UNI"],
  ["Unst (Shetland Island) - Baltasound Airport", "UNT"],
  ["Upala", "UPL"],
  ["Upernavik - Upernavik Heliport", "JUV"],
  ["Upington", "UTN"],
  ["Upolu Point (HI)", "UPP"],
  ["Uranium City", "YBE"],
  ["Urgench", "UGC"],
  ["Uriman", "URM"],
  ["Urmiehm (Orumieh)", "OMH"],
  ["Uruapan", "UPN"],
  ["Urubupunga - Ernesto Pochler Airport", "URB"],
  ["Uruguaiana - Ruben Berta Airport", "URG"],
  ["Urumqi", "URC"],
  ["Uruzgan", "URZ"],
  ["Ushuaia - Islas Malvinas Airport", "USH"],
  ["Utapao (Pattaya)", "UTP"],
  ["Utica (NY) - Oneida County Airport", "UCA"],
  ["Utila", "UII"],
  ["Uummannaq", "UMD"],
  ["Uzhgorod", "UDJ"],
  ["Vaasa", "VAA"],
  ["Vaexjoe", "VXO"],
  ["Vail (CO)", "EGE"],
  ["Val d'Or", "YVO"],
  ["Valdez (AK)", "VDZ"],
  ["Valdosta (GA)", "VLD"],
  ["Valencia", "VLC"],
  ["Valencia", "VLN"],
  ["Valladolid", "VLL"],
  ["Valparaiso", "VAP"],
  ["Valverde", "VDE"],
  ["Van - Ferit Melen", "VAN"],
  ["Vancouver - Vancouver International", "YVR"],
  ["Varadero", "VRA"],
  ["Varanasi", "VNS"],
  ["Varkaus", "VRK"],
  ["Varna", "VAR"],
  ["Vasteras", "VST"],
  ["Velikiye Luki (Welikije Luki)", "VLU"],
  ["Venice - Marco Polo", "VCE"],
  ["Veracruz", "VER"],
  ["Vernal (UT)", "VEL"],
  ["Vero Beach/Ft. Pierce (FL)", "VRB"],
  ["Verona (Brescia) Montichiari", "VBS"],
  ["Verona", "VRN"],
  ["Victoria", "YYJ"],
  ["Victoria Falls", "VFA"],
  ["Vidin", "VID"],
  ["Vientiane - Wattay", "VTE"],
  ["Vigo", "VGO"],
  ["Villahermosa", "VSA"],
  ["Vilnius", "VNO"],
  ["Virgin Gorda", "VIJ"],
  ["Visalia (CA)", "VIS"],
  ["Visby", "VBY"],
  ["Vitoria", "VIT"],
  ["Vitoria - Eurico de Aguiar Salles Airport", "VIX"],
  ["Vryheid", "VYD"],
  ["Wabush", "YWK"],
  ["Waco (TX)", "ACT"],
  ["Wagga", "WGA"],
  ["Walla Walla (WA)", "ALW"],
  ["Wallis", "WLS"],
  ["Walvis Bay", "WVB"],
  ["Warrnambool", "WMB"],
  ["Warsaw - Frédéric Chopin", "WAW"],
  ["Washington DC - Baltimore Washington International", "BWI"],
  ["Washington DC - Dulles International", "IAD"],
  ["Washington DC - Ronald Reagan National", "DCA"],
  ["Washington DC", "WAS"],
  ["Waterloo IA", "ALO"],
  ["Watertown (SD)", "ATY"],
  ["Wausau/Stevens Point (WI)", "CWA"],
  ["Weipa", "WEI"],
  ["Welkom", "WEL"],
  ["Wellington", "WLG"],
  ["Wenatchee (WA)", "EAT"],
  ["West Palm Beach (FL)", "PBI"],
  ["West Yellowstone (MT)", "WYS"],
  ["Westerland, Sylt Airport", "GWT"],
  ["Whakatane", "WHK"],
  ["Whale Cove, NT", "YXN"],
  ["Whangarei", "WRE"],
  ["White Plains (NY)", "HPN"],
  ["Whitehorse", "YXY"],
  ["Whitsunday Resort", "HAP"],
  ["Whyalla", "WYA"],
  ["Wichita Falls (TX)", "SPS"],
  ["Wichita (KS)", "ICT"],
  ["Wick", "WIC"],
  ["Wickham", "WHM"],
  ["Wien (Vienna) - Vienna International", "VIE"],
  ["Wiesbaden, Air Base", "WIE"],
  ["Wilkes Barre/Scranton (PA)", "AVP"],
  ["Williamsport (PA)", "IPT"],
  ["Williston (ND)", "ISL"],
  ["Wilmington (NC)", "ILM"],
  ["Wilna (Vilnius)", "VNO"],
  ["Wiluna", "WUN"],
  ["Windhoek - Eros", "ERS"],
  ["Windhoek - Hosea Kutako International", "WDH"],
  ["Windsor Ontario", "YQG"],
  ["Winnipeg International", "YWG"],
  ["Wolf Point (MT)", "OLF"],
  ["Wollongong", "WOL"],
  ["Woomera", "UMR"],
  ["Worcester (MA)", "ORH"],
  ["Worland (WY)", "WRL"],
  ["Wrangell (AK)", "WRG"],
  ["Wuhan", "WUH"],
  ["Wyndham", "WYN"],
  ["Xiamen", "XMN"],
  ["Xi'an - Xianyang", "XIY"],
  ["Yakima (WA)", "YKM"],
  ["Yakutat (AK)", "YAK"],
  ["Yakutsk", "YKS"],
  ["Yamagata, Junmachi", "GAJ"],
  ["Yamoussoukro", "ASK"],
  ["Yanbu", "YNB"],
  ["Yangon (Rangoon) - Mingaladon", "RGN"],
  ["Yaounde", "YAO"],
  ["Yellowknife", "YZF"],
  ["Yekaterinburg - Koltsovo Airport", "SVX"],
  ["Yichang", "YIH"],
  ["Yokohama", "YOK"],
  ["Yuma (AZ)", "YUM"],
  ["Zacatecas", "ZCL"],
  ["Zadar", "ZAD"],
  ["Zagreb - Zagreb Airport Pleso", "ZAG"],
  ["Zakynthos", "ZTH"],
  ["Zaragoza", "ZAZ"],
  ["Zhob", "PZH"],
  ["Zinder", "ZND"],
  ["Zouerate", "OUZ"],
  ["Zurich (Zürich) - Kloten", "ZRH"],
  // Add more options in the same format
];

export default airportOptions;
