import { ContactBookingPerson, FlightItineraryOffer, Passenger, TravelerPricing } from "./flight";
import { Guest } from "./hotel";

export enum PaymentMethod {
    CREDIT_CARD = "creditCard",
    WALLET = "wallet",
}

export enum VendorCode {
    VISA = "VI",
    MASTERCARD = "CA",
}

export interface BookTripgenRequest {
    name: string;
    flights: TripgenFlight[];
    hotels: TripgenHotel[];
    payment: TripgenPayment;
}
export interface TripgenFlight {
    itineraries: FlightItineraryOffer[];
    travelerPricings: TravelerPricing[];
    validatingAirlineCodes: string[];
    passengers: Passenger[];
    contactPerson: ContactBookingPerson;
}
export interface TripgenHotel {
    offerID: string;
    guests: Guest[];
}

export interface TripgenPayment {
    method: PaymentMethod;
    creditCard?: TripgenCreditCard;
    walletId?: string;
}


export interface TripgenCreditCard {
    vendorCode: VendorCode;
    cardNumber: string;
    cardHolder: string;
    expiryDate: string;
}