import Calendar from "../../components/Calendar/Calendar";
import { Button, Modal, Select, message } from "antd";
import { apiClient } from "../../utils/client";
// import "./TripGenCalendar.css";
import React, { useEffect, useState } from "react";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import BookingListCalendarComponent from "../../components/ฺBookingListCalendarComponent/BookingListCalendarComponent";
import BookingListContainer from "../../components/ฺBookingListCalendarComponent/BookingListContainer";
import { BookingList, GetBookingListResponse } from "../../types/calendar";
import { Checkbox } from "antd";
import { TripData } from "../../types/calendar";
import { useNavigate } from "react-router-dom";

const TripgenCalendarPage = () => {
  const navigate = useNavigate();
  const [trips, setTrips] = useState<TripData[]>([]);
  const [selectedTrip, setSelectedTrip] = useState("");
  const currentDate = new Date();
  const [calendarView, setCalendarView] = useState("timeGridWeek"); // Initial calendar view
  const [isOpenBookingModal, setIsOpenBookingModal] = useState(false);

  const handleSetCalendarView = (view: string) => {
    setCalendarView(view);
  };

  currentDate.setDate(1); // Set the date component to 1 for the first day of the month

  // Format the date manually
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because month index starts from 0
  const day = "01"; // Since we're setting the date to 1, day will always be 01
  const firstDayOfMonth = `${year}-${month}-${day}`;

  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  );

  const [bookingList, setBookingList] = useState<BookingList[]>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isPageRefresh, setIsPageRefresh] = useState(true);

  const [events, setEvents] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<{
    fromDate: string;
    toDate: string;
  }>({
    fromDate: firstDayOfMonth,
    toDate: lastDayOfMonth.toISOString().substring(0, 10),
  });
  const [filters, setFilters] = useState<{
    flight: boolean;
    hotel: boolean;
    creditCard: boolean;
    wallet: boolean;
  }>({
    flight: true,
    hotel: true,
    creditCard: true,
    wallet: true,
  });
  const [originalEvents, setOriginalEvents] = useState<any[]>([]);

  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };
  const handleCheckboxChange = (name: string, checked: boolean) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: checked,
    }));
  };

  const filteredBookingList = bookingList?.filter((booking) => {
    return (
      (filters.flight || booking.paymentType !== "flight") &&
      (filters.hotel || booking.bookingType !== "hotel") &&
      (filters.creditCard || booking.paymentType !== "creditCard") &&
      (filters.wallet || booking.paymentType !== "wallet")
    );
  });

  const fetchCalendarBookings = async (tripId: string) => {
    try {
      console.log(firstDayOfMonth);
      setIsPageRefresh(false);
      console.log(isPageRefresh);
      openModal();
      const response = await apiClient.getTripById(tripId);
      console.log(response);

      //1. real data
      const bookings = response.data.hotels || [];

      //2. mock data
      // const bookings = [
      //   {
      //     bookingId: "6635f869f6493702d94328f8",
      //     bookingType: "hotel",
      //     startDate: "2024-05-30",
      //     startAt: "14:00:00",
      //     endDate: "2024-05-31",
      //     endAt: "12:00:00",
      //     travellerCount: 1,
      //     status: "Pending",
      //     paymentType: "wallet",
      //     hotelName: "GRAND MERCURE ASOKE RESIDENCE",
      //   },
      //   {
      //     bookingId: "6635f869f6493702d94328f7",
      //     bookingType: "hotel",
      //     startDate: "2024-06-10",
      //     startAt: "15:00:00",
      //     endDate: "2024-06-12",
      //     endAt: "11:00:00",
      //     travellerCount: 2,
      //     status: "Pending",
      //     paymentType: "creditCard",
      //     hotelName: "Siam Kempinski Hotel Bangkok",
      //   },
      //   {
      //     bookingId: "6635f869f6493702d94328f6",
      //     bookingType: "hotel",
      //     startDate: "2024-06-20",
      //     startAt: "12:00:00",
      //     endDate: "2024-06-22",
      //     endAt: "10:00:00",
      //     travellerCount: 1,
      //     status: "Pending",
      //     paymentType: "wallet",
      //     hotelName: "The Peninsula Bangkok",
      //   },
      // ];
      console.log(bookings);
      if (bookings.length > 0) {
        const calendarEvents = bookings.map((booking: any) => ({
          title: booking.hotelName,
          start:
            booking.startDate +
            (booking.startAt ? "T" + booking.startAt : "T14:00:00"),
          end:
            booking.endDate +
            (booking.endAt ? "T" + booking.endAt : "T11:00:00"),
          color:
            booking.bookingType === "hotel"
              ? "rgb(84, 88, 68)"
              : "rgb(70, 147, 222)",
          extendedProps: {
            bookingId: booking.bookingId,
            bookingType: booking.bookingType,
            status: booking.status,
            paymentType: booking.paymentType,
          },
        }));
        setEvents(calendarEvents);
        setOriginalEvents(calendarEvents); // Store original unfiltered events
        setBookingList(bookings);
        console.log(bookingList);
      } else {
        console.log("No calendar bookings found.");
        setEvents([]);
        setOriginalEvents([]);
        setBookingList([]);
      }
    } catch (error) {
      console.error("Error fetching calendar bookings:", error);
    } finally {
      closeModal();
    }
  };

  const handleTripChange = async (tripId: any) => {
    setSelectedTrip(tripId);
    // Fetch calendar bookings for the selected trip ID
    await fetchCalendarBookings(tripId);
  };

  const handleDateRangeChange = async (dates: {
    startStr: string;
    endStr: string;
  }) => {
    console.log(calendarView);
  };

  const openBookingModal = () => {
    setIsOpenBookingModal(true);
  };

  const closeBookingModal = () => {
    setIsOpenBookingModal(false);
  };

  useEffect(() => {
    // Fetch all trip IDs when the component mounts
    const fetchTripIds = async () => {
      try {
        const response = await apiClient.getAllTrips(); // Adjust this based on your API endpoint for fetching all trip IDs

        setTrips(response.data.data);
        console.log(trips[0].tripId);
      } catch (error) {
        console.error("Error fetching trip IDs:", error);
      }
    };

    fetchTripIds();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Perform actions here that you want to trigger on page refresh
      setIsPageRefresh(true);
      // You can also perform any other actions or call any functions here
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  //filter
  useEffect(() => {
    const filteredEvents = originalEvents.filter((event: any) => {
      if (!filters.hotel && event.extendedProps.bookingType === "hotel") {
        return false;
      }

      if (!filters.flight && event.extendedProps.paymentType === "flight") {
        return false;
      }

      if (
        !filters.creditCard &&
        event.extendedProps.paymentType === "creditCard"
      ) {
        return false;
      }

      if (!filters.wallet && event.extendedProps.paymentType === "wallet") {
        return false;
      }
      return true; // Include the event if it passes all filter checks
    });
    setEvents(filteredEvents);
  }, [filters, originalEvents]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 style={{ paddingLeft: "20px" }}>Trip Calendar</h1>
      </div>

      <ModalLoading isOpen={isOpenModal} />
      <div className="BookingListPage-grid">
        <div className="BookingListPage-Calendar">
          <div className="filter-wrapper">
            <p className="filter-type">Select your trip</p>
            <Select
              style={{ width: 200, marginRight: 20 }}
              size="large"
              placeholder="Select Trip ID"
              onChange={handleTripChange}
              value={selectedTrip}
              options={trips.map((trip) => ({
                value: trip.tripId,
                label: trip.name,
                disabled: false,
              }))}
            />
            <p className="filter-type">Booking Type</p>
            <Checkbox
              style={{ paddingRight: "20px" }}
              checked={filters.flight}
              onChange={(e) => handleCheckboxChange("flight", e.target.checked)}
            >
              Flight
            </Checkbox>
            <Checkbox
              style={{ paddingRight: "50px" }}
              checked={filters.hotel}
              onChange={(e) => handleCheckboxChange("hotel", e.target.checked)}
            >
              Hotel
            </Checkbox>
            <p className="filter-type">Payment Type</p>
            <Checkbox
              style={{ paddingRight: "20px" }}
              checked={filters.creditCard}
              onChange={(e) =>
                handleCheckboxChange("creditCard", e.target.checked)
              }
            >
              Credit Card
            </Checkbox>
            <Checkbox
              style={{ paddingRight: "20px" }}
              checked={filters.wallet}
              onChange={(e) => handleCheckboxChange("wallet", e.target.checked)}
            >
              Wallet
            </Checkbox>
          </div>
          <Calendar
            events={events}
            onDateRangeChange={handleDateRangeChange}
            setCalendarView={handleSetCalendarView}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                if (!selectedTrip) {
                  alert("Please select your trip.");
                } else {
                  openBookingModal();
                }
              }}
            >
              Add Bookings
            </Button>
          </div>
          <Modal
            style={{
              textAlign: "center",
              top: "50%",
              transform: "translateY(-50%)",
              fontSize: "44px",
            }}
            title="Select Booking Type"
            visible={isOpenBookingModal}
            onCancel={closeBookingModal}
            footer={null}
            width={250}
          >
            <div style={{ textAlign: "center" }}>
              <Button
                type="primary"
                onClick={() =>
                  navigate(`/tripgen/flight-booking/${selectedTrip}`)
                }
                style={{ marginRight: "10px" }}
              >
                Flight
              </Button>
              <Button
                type="primary"
                onClick={() =>
                  navigate(`/tripgen/hotel-booking/${selectedTrip}`)
                }
              >
                Hotel
              </Button>
            </div>
          </Modal>
        </div>
        <div>
          <BookingListContainer bookingList={bookingList} />
        </div>
      </div>
    </div>
  );
};

export default TripgenCalendarPage;
