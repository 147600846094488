export const generateCarrentalLink = (pickupLocation: string, dropoffLocation: string, pickupDate: string, dropoffDate: string) => {
    const pickupDateParts = pickupDate.split('-');
    let doYear: string;
    let doMonth: string;
    let doDay: string;

    if (dropoffDate != "No Return Date") {
        const dropoffDateParts = dropoffDate.split('-');
        doYear = dropoffDateParts[0];
        doMonth = dropoffDateParts[1];
        doDay = dropoffDateParts[2];
    } else {
        const pickupDateTime = new Date(pickupDate);
        // add 3 days if we don't have a dropoff date
        pickupDateTime.setDate(pickupDateTime.getDate() + 3);
        doYear = pickupDateTime.getFullYear().toString();
        doMonth = (pickupDateTime.getMonth() + 1).toString().padStart(2, '0');
        doDay = pickupDateTime.getDate().toString().padStart(2, '0');
    }

    const baseURL = "https://www.rentalcars.com/search-results";

    const params = `?locationIata=${pickupLocation}&dropLocationIata=${dropoffLocation}&puDay=${pickupDateParts[2]}&puMonth=${pickupDateParts[1]}&puYear=${pickupDateParts[0]}&puMinute=0&puHour=10&doDay=${doDay}&doMonth=${doMonth}&doYear=${doYear}&doMinute=0&doHour=10`;
    const fullURL = baseURL + params;

    return fullURL;
}