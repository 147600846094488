import { Dayjs } from "dayjs";
import { SearchFlightRequest } from "../../../types/flight";
import MyDatePicker from "../../DatePickerComponent/MyDatePicker";
import LocationInput from "../../LocationInput/LocationInput";
import MyCheckBox from "../../MyCheckBox/MyCheckBox";
import Passenger from "../../Passenger/Passenger";
import "./TripgenFlightSearch.css";
import { useEffect, useState } from "react";
import { TravelClass } from "../../../types/enum/travelClass";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { TravellerType } from "../../../types/enum/travellerType";
import { RiDeleteBinLine } from "react-icons/ri";
import dayjs from "dayjs";
import airportOptions from "../../../utils/airportOptions";
import { TripGenPhase } from "../../../types/enum/tripgenPhase";
import { useTripgenContext } from "../../../context/TripgenContext";
interface TripgenFlightSearchProp {
  flightSearchRequest: SearchFlightRequest;
  id_idx: number;
}

const TripgenFlightSearch: React.FC<TripgenFlightSearchProp> = ({ flightSearchRequest, id_idx }) => {
  const {
    removeFlightSearchRequest,
    updateFlightSearchRequest,
    isDifferentCity,
    setIsDifferentCity,
    tripgenPhase,
    tripgenFlightStep,
    isDifferentCityHandler,
    selectedBookingFlight,
    totalFlightStep,
  } = useTripgenContext();

  const airportMap = Object.fromEntries(airportOptions);
  const getAirportName = (code: string) => {
    for (const [name, airportCode] of airportOptions) {
      if (airportCode === code) {
        return name;
      }
    }
  };

  const [originLocationCode, setOriginLocationCode] = useState<string>(flightSearchRequest.originLocationCode);
  const [originLocation, setOriginLocation] = useState<string>(
    getAirportName(flightSearchRequest.originLocationCode) || ""
  );
  const [destinationLocationCode, setDestinationLocationCode] = useState<string>(
    flightSearchRequest.destinationLocationCode
  );
  const [destinationLocation, setDestinationLocation] = useState<string>(
    getAirportName(flightSearchRequest.destinationLocationCode) || ""
  );

  const [departDate, setDepartDate] = useState<Dayjs | null>(dayjs(flightSearchRequest.departureDate || null));
  const [formattedDepartDate, setFormattedDepartDate] = useState<string>(flightSearchRequest.departureDate);
  const [returnDate, setReturnDate] = useState<Dayjs | null>(dayjs(flightSearchRequest.returnDate || null));
  const [formattedReturnDate, setFormattedReturnDate] = useState<string>(flightSearchRequest.returnDate);
  const [flightClass, setFlightClass] = useState<TravelClass>(flightSearchRequest.cabinType);
  const [adultNumberFlight, setAdultNumberFlight] = useState(flightSearchRequest.travellers[0].travellerAmount);
  const [childernNumberFlight, setChildernNumberFlight] = useState(
    flightSearchRequest.travellers[1]?.travellerAmount || 0
  );
  const [isOnewayChecked, setIsOnewayChecked] = useState(flightSearchRequest.isOneway);

  const handleOriginLocation = (value: string) => {
    const code = value.match(/\(([^)]+)\)$/)?.[1] || "";
    setOriginLocationCode(code);
    setOriginLocation(value);
  };

  const handleDestinationLocation = (value: string) => {
    const code = value.match(/\(([^)]+)\)$/)?.[1] || "";
    setDestinationLocationCode(code);
    setDestinationLocation(value);
  };

  const handleDepartDate = (date: Dayjs | null) => {
    setDepartDate(date);

    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");
      setFormattedDepartDate(formattedDate);
    } else {
      setFormattedDepartDate("");
    }
  };

  const handleReturnDate = (date: Dayjs | null) => {
    setReturnDate(date);

    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");
      setFormattedReturnDate(formattedDate);
    } else {
      setFormattedReturnDate("");
    }
  };

  const handleOnewayCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsOnewayChecked(e.target.checked);
    setReturnDate(null);
  };

  const updateFlightInfo = () => {
    const flight: SearchFlightRequest = {
      originLocationCode: originLocationCode,
      destinationLocationCode: destinationLocationCode,
      departureDate: formattedDepartDate,
      returnDate: formattedReturnDate,
      cabinType: flightClass,
      isOneway: isOnewayChecked,
      isFlexible: false,
      travellers: [
        {
          travellerType: "ADULT",
          travellerAmount: adultNumberFlight,
        },
      ],
    };
    if (childernNumberFlight) {
      flight.travellers.push({
        travellerType: "CHILD",
        travellerAmount: childernNumberFlight,
      });
    }
    updateFlightSearchRequest(id_idx, flight);
  };

  const isThisFormDisabled = () => {
    if (
      tripgenPhase !== TripGenPhase.FIRST &&
      ((tripgenPhase !== TripGenPhase.FLIGHT_PHASE && selectedBookingFlight.length - 1 >= id_idx) ||
        (tripgenPhase === TripGenPhase.FLIGHT_PHASE && selectedBookingFlight.length - 1 >= id_idx))
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    updateFlightInfo();
  }, [
    originLocationCode,
    originLocation,
    destinationLocationCode,
    destinationLocation,
    departDate,
    returnDate,
    flightClass,
    adultNumberFlight,
    childernNumberFlight,
    isOnewayChecked,
  ]);

  return (
    <>
      <div className="TripgenFlightSearch-flightSearch">
        <div className="TripgenFlightSearch-fromComponent">
          From
          <LocationInput
            placeholder="Input airport code or city name"
            originLocation={originLocation}
            onInputChange={handleOriginLocation}
            disabled={isThisFormDisabled()}
          />
        </div>

        <div className="TripgenFlightSearch-toComponent">
          To
          <LocationInput
            placeholder="Input airport code or city name"
            originLocation={destinationLocation}
            onInputChange={handleDestinationLocation}
            disabled={isThisFormDisabled()}
          />
        </div>

        <div className="TripgenFlightSearch-departComponent">
          Depart
          <MyDatePicker selectedDate={departDate} onDateChange={handleDepartDate} disable={isThisFormDisabled()} />
        </div>

        <div className="TripgenFlightSearch-returnComponent">
          Return
          <MyDatePicker
            selectedDate={returnDate}
            onDateChange={handleReturnDate}
            disable={isOnewayChecked || isThisFormDisabled()}
          ></MyDatePicker>
        </div>

        <div className="TripgenFlightSearch-passengersComponent">
          Passengers
          <Passenger
            flightClass={flightClass}
            setFlightClass={setFlightClass}
            adultNumber={adultNumberFlight}
            setAdultNumber={setAdultNumberFlight}
            childernNumber={childernNumberFlight}
            setChildernNumber={setChildernNumberFlight}
            disabled={isThisFormDisabled()}
          />
        </div>

        <div>
          {id_idx !== 0 &&
            selectedBookingFlight.length - 1 !== id_idx &&
            (tripgenPhase === TripGenPhase.FLIGHT_PHASE || tripgenPhase === TripGenPhase.HOTEL_PHASE) && (
              <div onClick={() => removeFlightSearchRequest(id_idx)} className="TripgenFlightSearch-removeButton">
                <RiDeleteBinLine style={{ width: "40px", height: "40px", color: "#ffffff" }} />
              </div>
            )}
        </div>
      </div>

      <div className="TripgenFlightSearch-CheckboxPanel">
        <div className="TripgenFlightSearch-myCheckbox">
          <MyCheckBox isChecked={isOnewayChecked} onCheckboxChange={handleOnewayCheckboxChange} />
          one-way
        </div>

        {tripgenPhase === TripGenPhase.FIRST ? (
          <div className="TripgenFlightSearch-myCheckbox">
            <MyCheckBox isChecked={isDifferentCity} onCheckboxChange={isDifferentCityHandler} />
            search Hotel in different cities or dates
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TripgenFlightSearch;
