import React, { FC } from "react";
import { Table } from "antd";
import "./RequestTable.css";

interface RequestTableProps {
  data: any[];
  columns: any[];
  onRowClick: (id: string) => void;
}

const RequestTable: FC<RequestTableProps> = ({ data, columns, onRowClick }) => {
  const handleRowClick = (record: { id: string }) => {
    if (onRowClick) {
      onRowClick(record.id);
    }
  };
  return (
    <div className="table-size">
      <Table
        dataSource={data}
        columns={columns}
        rowKey="id" // Assuming the unique identifier for each row is 'id'
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
    </div>
  );
};

export default RequestTable;
