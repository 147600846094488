import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import "./Calendar.css";
import { apiClient } from "../../utils/client";
import { Checkbox, message } from "antd";
import { GetBookingDetailResponse } from "../../types/calendar";
import ModalLoading from "../ModalLoading/ModalLoading";
import PopupCalendarComponent from "../PopupCalendarComponent/PopupCalendarComponent";

interface CalendarProps {
  events: any[];
  onDateRangeChange: (dates: { startStr: string; endStr: string }) => void;
  setCalendarView: (view: string) => void;
}

const Calendar: React.FC<CalendarProps> = ({
  events,
  onDateRangeChange,
  setCalendarView,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const [bookingDetail, setBookingDetail] =
    useState<GetBookingDetailResponse>();
  const handleDateRangeChange = (dates: {
    startStr: string;
    endStr: string;
  }) => {
    onDateRangeChange(dates);
  };

  const handleViewChange = (view: any) => {
    setCalendarView(view.view.type); // Update calendar view in parent component (CalendarPage)
  };

  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };
  const getBookingDetail = async (
    selectBooking: string,
    selectType: string
  ) => {
    try {
      openModal();
      setIsOpenPopup(false);
      if (events) {
        const response = await apiClient.getCalendarBookingDetail({
          bookingId: selectBooking,
          bookingType: selectType,
        });
        setBookingDetail(response.data);
      }
    } catch {
      message.error("Cannot get this booking detail, Please try again later");
    } finally {
      closeModal();
      setIsOpenPopup(true);
    }
  };

  const handleEventClick = (info: any) => {
    getBookingDetail(
      info.event.extendedProps.bookingId,
      info.event.extendedProps.bookingType
    );
    // You can perform additional actions here based on the clicked event
  };

  return (
    <div
      className="calendar-wrapper"
      style={{ minWidth: "1000px", width: "100%", padding: "0px 50px" }}
    >
      <div>
        <ModalLoading isOpen={isOpenModal} />
        {bookingDetail && isOpenPopup ? (
          <PopupCalendarComponent
            data={bookingDetail}
            setIsOpenPopup={setIsOpenPopup}
          />
        ) : null}
      </div>
      <FullCalendar
        height={750}
        plugins={[dayGridPlugin, timeGridPlugin]}
        initialView="dayGridMonth"
        events={events}
        datesSet={handleDateRangeChange}
        eventClick={handleEventClick}
        viewDidMount={handleViewChange}
        eventClassNames="event-pointer"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        views={{
          timeGridDay: {
            buttonText: "Day",
          },
          timeGridWeek: {
            buttonText: "Week",
          },
          dayGridMonth: {
            buttonText: "Month",
          },
        }}
      />
    </div>
  );
};

export default Calendar;
function openModal() {
  throw new Error("Function not implemented.");
}

function closeModal() {
  throw new Error("Function not implemented.");
}

function setIsOpenPopup(arg0: boolean) {
  throw new Error("Function not implemented.");
}
