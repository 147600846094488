import "./HotelOfferItem.css";
import { FC } from "react";
import { HotelOffer } from "../../types/hotel";
import { FaLocationDot } from "react-icons/fa6";
import HotelImage from "../../assets/hotel/novotel.png";
import { BiBed } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";

interface HotelOfferItemProp {
  hotelOffer: HotelOffer;
  onClick: () => void;
}

const HotelOfferItem: FC<HotelOfferItemProp> = ({ hotelOffer, onClick }) => {
  return (
    <div className="hotel-offer-item-container" onClick={onClick}>
      <div className="hotel-offer-item-picture">
        <img src={HotelImage} style={{ height: "100%", maxWidth: "100%" }} />
      </div>
      <div className="hotel-offer-item-content">
        <h4>{hotelOffer.hotelName}</h4>
        <div style={{ display: "flex", gap: "5px" }}>
          <FaLocationDot style={{ width: "40px" }} />
          <p>{hotelOffer.location}</p>
        </div>
        <div></div>
        <h5>Amenities: </h5>
        <div className="hotel-offer-item-amenities">
          {hotelOffer.amenities &&
            hotelOffer.amenities.length > 0 &&
            hotelOffer.amenities.map((amenity: string) => (
              <div className="hotel-offer-item-amenity">{amenity}</div>
            ))}
        </div>
      </div>
      <div className="hotel-offer-item-room-price">
        <div className="hotel-offer-item-room">
          <div style={{ fontWeight: "bold" }}>
            <p>
              {hotelOffer.offers[0].roomQuantity}{" "}
              {hotelOffer.offers[0].room.category}
            </p>
          </div>
          <div className="hotel-offer-item-room-detail">
            <BiBed />
            <p>
              {hotelOffer.offers[0].room.beds}{" "}
              {hotelOffer.offers[0].room.bedType} BED
            </p>
          </div>
          <div className="hotel-offer-item-room-detail">
            <BsFillPersonFill />
            <p>{hotelOffer.offers[0].room.guestPerRoom} GUESTS</p>
          </div>
          <div className="hotel-offer-item-amenity">
            {hotelOffer.offers[0].boardType}
          </div>
        </div>
        <div className="hotel-offer-item-price">
          <h4 style={{ margin: "5px 0px" }}>
            {hotelOffer.offers[0].price.currency}{" "}
            {hotelOffer.offers[0].price.total}
          </h4>
          <p style={{ fontSize: "10px" }}>Include taxed and charges</p>
        </div>
      </div>
    </div>
  );
};

export default HotelOfferItem;
