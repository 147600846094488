import { useTripgenContext } from "../../../context/TripgenContext";
import { HotelOffer } from "../../../types/hotel";
import "./TripgenHotelInfo.css";
import React from "react";

interface TripgenHotelInfoProps {
  hotel: HotelOffer;
  index: number;
}

const TripgenHotelInfo: React.FC<TripgenHotelInfoProps> = ({ hotel, index }) => {
  const { hotelSearchRequests } = useTripgenContext();

  return (
    <div className="confirm-container" style={{ height: "fit-content", paddingTop: "10px" }}>
      <div className="hotelInfo-container">
        <div className="hotelInfo-first-grid">
          <div>
            {" "}
            <strong>Hotel Name : </strong>
            {hotel.hotelName}
          </div>
          <div>
            {" "}
            <strong>Check in Date : </strong>
            {hotel.offers[0].checkInDate}
          </div>
          <div>
            {" "}
            <strong>Check out Date :</strong> {hotel.offers[0].checkOutDate}
          </div>
        </div>
        <div className="hotelInfo-second-grid">
          <div>
            <strong>Number of Room :</strong> {hotel.offers[0].roomQuantity}{" "}
            {parseInt(hotel.offers[0].roomQuantity) > 1 ? "rooms" : "room"}
          </div>

          <div>
            {" "}
            <strong>Guests : </strong>
            {hotelSearchRequests[index].adult}
          </div>
          <div>
            <strong>Price :</strong> {hotel.offers[0].price.total} {hotel.offers[0].price.currency}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripgenHotelInfo;
