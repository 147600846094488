import { Route, Routes } from "react-router-dom";
import "./App.css";
import TabBar from "./components/TabBar/TabBar";
import FlightBookingPage from "./page/FlightBookingPage/FlightBookingPage";
import SearchCar from "./page/SearchCar/SearchCar";
import SearchHotel from "./page/SearchHotel/SearchHotel";
import SearchPage from "./page/SearchPage/SearchPage";
import HotelBookingPage from "./page/HotelBookingPage/HotelBookingPage";
import BookingManagementPage from "./page/AdminPage/BookingManagementPage";
import FetchRequest from "./page/AdminPage/FetchRequest";
import CalendarPage from "./page/CalendarPage/CalendarPage";
import UserProvider from "./context/UserContext";
import TripgenPage from "./page/TripgenPage/TripgenPage";
import TripgenProvider from "./context/TripgenContext";
import TripgenCalendarPage from "./page/TripgenPage/TripGenCalendar";
import FlightProvider from "./context/FlightContext";

function App() {
  return (
    <div className="App">
      <UserProvider>
        <TripgenProvider>
          <FlightProvider>
            <header className="App-header">
              <TabBar />
            </header>
            <Routes>
              <Route path="/" element={<SearchPage />} />
              <Route path="/car" element={<SearchCar />} />
              <Route path="/hotel" element={<SearchHotel />} />

              <Route path="/flight/booking" element={<FlightBookingPage />} />
              <Route
                path="/hotel/booking/:offerId"
                element={<HotelBookingPage />}
              />

              <Route path="/admin" element={<FetchRequest />} />
              <Route
                path="/admin/:bookingId"
                element={<BookingManagementPage />}
              />
              <Route path="/calendar" element={<CalendarPage />} />
              <Route path="/tripgen" element={<TripgenPage />} />
              <Route
                path="/tripgen-calendar"
                element={<TripgenCalendarPage />}
              />
              <Route
                path="/tripgen/hotel-booking/:tripId"
                element={<SearchHotel />}
              />
              <Route
                path="/hotel/booking/:offerId/:tripId"
                element={<HotelBookingPage />}
              />
              <Route
                path="/tripgen/add-bookings/:tripId"
                element={<TripgenPage />}
              />
            </Routes>
          </FlightProvider>
        </TripgenProvider>
      </UserProvider>
    </div>
  );
}

export default App;
