// In a context file
import React, { createContext, useContext, useState } from "react";
import { SearchFlightOffer } from "../types/flight";

interface FlightContextValue {
  selectedFlightForBooking: SearchFlightOffer;
  setSelectedFlightForBooking: React.Dispatch<React.SetStateAction<SearchFlightOffer>>;
  numberOfPassenger: number;
  setNumberOfPassenger: React.Dispatch<React.SetStateAction<number>>;
}

const FlightContext = createContext<FlightContextValue>({} as FlightContextValue);

export function useFlightContext() {
  return useContext(FlightContext);
}

export const FlightProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedFlightForBooking, setSelectedFlightForBooking] = useState<SearchFlightOffer>({} as SearchFlightOffer);
  const [numberOfPassenger, setNumberOfPassenger] = useState<number>(1);

  const value: FlightContextValue = {
    selectedFlightForBooking,
    setSelectedFlightForBooking,
    numberOfPassenger,
    setNumberOfPassenger,
  };

  return <FlightContext.Provider value={value}>{children}</FlightContext.Provider>;
};

export default FlightProvider;
