import { IoTrashBinSharp } from "react-icons/io5";
import { Guest } from "../../types/hotel";
import { FC } from "react";
import { Form, Input, Select } from "antd";
import "./IndivGuestInfo.css";
import PhoneInput from "antd-phone-input";
import { PhoneNumber } from "antd-phone-input/types";

interface IndivGuestInfoProp {
  guest: Guest;
  id: number;
  removeGuest: (target: number) => void;
  updateGuest: (target: number, guestKey: keyof Guest, guestValue: any) => void;
}
const { Option } = Select;

const selectBefore = (
  <Select defaultValue="MR">
    <Option value="MR">MR</Option>
    <Option value="MRS">MRS</Option>
    <Option value="MS">MS</Option>
  </Select>
);

const IndivGuestInfo: FC<IndivGuestInfoProp> = ({ guest, id, removeGuest, updateGuest }) => {
  const updateFirstname = (e: any, id: number) => {
    const titleValue = e.target.parentNode.querySelector(".ant-select-selection-item").title;
    updateGuest(id, "firstName", e.target.value);
    updateGuest(id, "title", titleValue);
  };

  function updateContact(e: PhoneNumber, id: number): void {
    if (e.areaCode !== null && e.phoneNumber !== null && e.countryCode !== null) {
      const formattedPhoneNumber = `+${e.countryCode}${e.areaCode}${e.phoneNumber}`;
      updateGuest(id, "phone", formattedPhoneNumber);
    }
  }

  return (
    <div className="guest-info-container">
      <div className="guest-info-header">
        <div className="guest-info-header-left">
          <h4 style={{ margin: "0px" }}>Guest {id + 1}</h4>
        </div>
        <button
          className={`guest-info-remove-guest-btn ${id == 0 ? "disables-btn" : ""}`}
          onClick={() => removeGuest(id)}
          disabled={id == 0}
        >
          Remove
          <IoTrashBinSharp />
        </button>
      </div>
      <div className="guest-info-data">
        <Form.Item
          label="Firstname"
          name={`guest-${id}-firstname`}
          rules={[{ required: true, message: "Please input name" }]}
          initialValue={guest.firstName}
        >
          <div className="input-with-select">
            <Input
              className="guest-info-data-item-title"
              addonBefore={selectBefore}
              defaultValue={guest.firstName}
              onChange={(e) => updateFirstname(e, id)}
            />
          </div>
        </Form.Item>

        <Form.Item
          label="Lastname"
          name={`guest-${id}-lastname`}
          rules={[{ required: true, message: "Please input lastname" }]}
          initialValue={guest.lastName}
        >
          <Input className="guest-info-data-item" onChange={(e) => updateGuest(id, "lastName", e.target.value)} />
        </Form.Item>
        <Form.Item
          label="PhoneNumber"
          name={`guest-${id}-phone-number`}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, { valid }) {
                if (valid()) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The input is invalid phone number!"));
              },
            }),
          ]}
          initialValue={guest.phone}
        >
          <PhoneInput enableSearch onChange={(e) => updateContact(e, id)} />
        </Form.Item>
        <Form.Item
          label="Email"
          name={`guest-${id}-email`}
          rules={[
            { required: true, message: "Please input email" },
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
          initialValue={guest.email}
        >
          <Input className="guest-info-data-item" onChange={(e) => updateGuest(id, "email", e.target.value)} />
        </Form.Item>
      </div>
    </div>
  );
};

export default IndivGuestInfo;
