import React from "react";
import { Modal } from "antd";
import "./ModalLoading.css";
import { ImAirplane } from "react-icons/im";

interface ModalProps {
  isOpen: boolean;
}

const ModalLoading: React.FC<ModalProps> = ({ isOpen }) => {
  return (
    <Modal
      open={isOpen}
      footer={null}
      maskClosable={false}
      closable={false}
      className="custom-modal"
      width={800}
    >
      <div className="loading-text">
        {" "}
        <ImAirplane
          style={{ width: "30px", height: "30px", marginRight: "20px" }}
        />
        LOADING
      </div>
      <div className="blank-space-modal-loading">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalLoading;
