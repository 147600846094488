import { BookingList, GetBookingDetailResponse } from "../../types/calendar";
import "./BookingListCalendarComponent.css";
import React, { useState, useEffect } from "react";
import { MdHotel } from "react-icons/md";
import { FaPlane, FaCheckCircle } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import ModalLoading from "../ModalLoading/ModalLoading";
import { apiClient } from "../../utils/client";
import { message } from "antd";
import { RxCrossCircled } from "react-icons/rx";
import { MdOutlineTimelapse } from "react-icons/md";
import PopupCalendarComponent from "../PopupCalendarComponent/PopupCalendarComponent";
interface BookingListProps {
  data: BookingList;
}

const BookingListCalendarComponent: React.FC<BookingListProps> = ({ data }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [bookingDetail, setBookingDetail] =
    useState<GetBookingDetailResponse>();

  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };

  const getBookingDetail = async (
    selectBooking: string,
    selectType: string
  ) => {
    try {
      openModal();
      setIsOpenPopup(false);
      if (data) {
        const response = await apiClient.getCalendarBookingDetail({
          bookingId: selectBooking,
          bookingType: selectType,
        });
        setBookingDetail(response.data);
      }
    } catch {
      message.error("Cannot get this booking detail, Please try again later");
    } finally {
      closeModal();
      setIsOpenPopup(true);
    }
  };
  return (
    <div
      className={`BookingList-container ${
        data.bookingType === "flight"
          ? "flight-class"
          : data.bookingType === "hotel"
          ? "hotel-class"
          : ""
      }`}
    >
      <ModalLoading isOpen={isOpenModal} />
      {bookingDetail && isOpenPopup ? (
        <PopupCalendarComponent
          data={bookingDetail}
          setIsOpenPopup={setIsOpenPopup}
        />
      ) : null}

      {data.bookingType === "flight" ? (
        <div
          className="BookingList-flight"
          // onClick={() => {
          //   setSelectedBooking(data.bookingId);
          //   setSelectedBookingType(data.bookingType);
          //   getBookingDetail();
          //    setIsOpenPopup(true);
          // }}
        >
          {/* <div className="BookingList-nameAndIcon">  {" "}
            <FaPlane />
            Type : {data.bookingType} </div>
          <div>
            {" "}
            From-To : {data.from} --{">"} {data.to}
          </div>
          <div>
            Depart At : {data.departAt} @{data.departTime}
          </div>
          <div>
            Arrive At : {data.arriveAt} @{data.arriveTimes}
          </div> */}
        </div>
      ) : (
        <div
          className="BookingList-hotel"
          onClick={() => {
            getBookingDetail(data.bookingId, data.bookingType);
          }}
        >
          <div className="BookingList-nameAndIcon">
            {" "}
            <MdHotel />
            Type : {data.bookingType}{" "}
          </div>
          <div className="BookingList-hotelName">
            {" "}
            Hotel Name : {data.hotelName}
          </div>
          <div>
            Checkin At : {data.startDate} @{data.startAt}
          </div>
          <div>
            Checkout At : {data.endDate} @{data.endAt}
          </div>

          <div className="BookingList-passenger">
            <div>
              Guest : {data.travellerCount}{" "}
              {data.travellerCount > 1 ? "people" : "person"}{" "}
            </div>
          </div>

          {data.status === "Approve" ? (
            <div className="BookingList-status-approve">
              <FaRegCircleCheck style={{ width: "17px", height: "17px" }} />
              Approved
            </div>
          ) : data.status === "Pending" ? (
            <div className="BookingList-status-pending">
              <MdOutlineTimelapse style={{ width: "23px", height: "23px" }} />
              Pending
            </div>
          ) : (
            <div className="BookingList-status-reject">
              <RxCrossCircled />
              Rejected
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BookingListCalendarComponent;
