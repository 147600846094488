import React, { useState } from "react";
import "./RoomAndGuest.css";
import { InputNumber } from "antd";

interface RoomAndGuestProp {
  adultNumber: number;
  setAdultNumber: React.Dispatch<React.SetStateAction<number>>;
  roomAmount: number;
  setRoomAmount: React.Dispatch<React.SetStateAction<number>>;
  disabled?: boolean;
}

const RoomAndGuest: React.FC<RoomAndGuestProp> = ({
  adultNumber,
  setAdultNumber,
  roomAmount,
  setRoomAmount,
  disabled,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div
        className="RoomAndGuest-container"
        onClick={() => {
          if (!disabled) toggleExpansion();
        }}
        style={{
          cursor: disabled ? "not-allowed" : "pointer",
          opacity: disabled ? 0.8 : 1,
        }}
      >
        {" "}
        <div className="RoomAndGuest-PlaceHolderText">
          {roomAmount > 1 ? `${roomAmount} Rooms` : `${roomAmount} Room`},
          {adultNumber > 1 ? ` ${adultNumber} Adults` : ` ${adultNumber} Adult`},
        </div>
      </div>
      {isExpanded && (
        <div className="RoomAndGuest-box">
          <div className="RoomAndGuest-RoomAdultChildren">
            <div className="RoomAndGuest-Panel" style={{ marginTop: 20 }}>
              <div className="RoomAndGuest-Text">Rooms</div>
              <div className="RoomAndGuest-NumberInput">
                <InputNumber
                  min={1}
                  max={8}
                  defaultValue={roomAmount}
                  style={{ height: 38, fontSize: 16 }}
                  onChange={(e) => {
                    if (e != null) setRoomAmount(e);
                  }}
                />
              </div>
            </div>
            <div className="RoomAndGuest-Panel" style={{ marginTop: 20 }}>
              <div className="RoomAndGuest-Text">Adults</div>
              <div className="RoomAndGuest-NumberInput">
                <InputNumber
                  min={1}
                  max={8}
                  defaultValue={adultNumber}
                  style={{ height: 38, fontSize: 16 }}
                  onChange={(e) => {
                    if (e != null) setAdultNumber(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RoomAndGuest;
