import React, { useState } from "react";
import "./PopupConfirmComponent.css";
import Button from "../Buttons/Buttons";
import { FaCheckCircle } from "react-icons/fa";
import { Input } from "antd";
import ModalLoadingDone from "../ModalLoading/ModalLoadingDone";

interface PopupConfirmProps {
  onConfirm: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  // true is Accept, false is Reject
  acceptOrReject: boolean;
  // have message when acceptOrReject is false
  handleReasonChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rejectReason: string;
}

const { TextArea } = Input;

const PopupConfirmComponent: React.FC<PopupConfirmProps> = ({
  handleReasonChange,
  onConfirm,
  onCancel,
  acceptOrReject,
  rejectReason,
}) => {
  const [showIcon, setShowIcon] = useState(false);

  return (
    <div className="PopupConfirm-container">
      {acceptOrReject ? (
        // accept case
        <div className="PopupConfirm-subDisplay">
          {showIcon ? (
            <ModalLoadingDone
              isOpen={true}
              text={"Email will be sent to the participants"}
            ></ModalLoadingDone>
          ) : (
            <>
              <div className="PopupConfirm-headerText">Are you sure ?</div>
              <div className="PopupConfirm-subText">
                Do you want to approve this booking?
              </div>
              <div className="Button-close-popup">
                <Button
                  message="Cancel"
                  bgColor="#D9D9D9"
                  bheight="50px"
                  bwidth="100px"
                  fontSize="16px"
                  borderRadius="6px"
                  onClick={(e) => {
                    onCancel(e);
                  }}
                />

                <Button
                  message="Approve and send email"
                  bgColor="#4CAF50"
                  bheight="50px"
                  bwidth="200px"
                  fontSize="16px"
                  borderRadius="6px"
                  onClick={(e) => {
                    setShowIcon(true);
                    setTimeout(() => {
                      setShowIcon(false);
                      onConfirm(e);
                    }, 3000);
                  }}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        // reject case
        <div className="PopupConfirm-subDisplay-reject">
          {showIcon && rejectReason ? (
            <ModalLoadingDone
              isOpen={true}
              text={"Email will be sent to the participants"}
            ></ModalLoadingDone>
          ) : (
            <>
              <div className="PopupConfirm-headerText">Are you sure ?</div>
              <div className="PopupConfirm-subText">
                Do you want to reject this booking?
              </div>
              <div>
                <TextArea
                  onChange={handleReasonChange}
                  placeholder="Reason for rejection"
                  autoSize={{ minRows: 6, maxRows: 9 }}
                  className="PopupConfirm-reason-textarea"
                ></TextArea>
              </div>
              <div className="Button-close-popup">
                <Button
                  message="Cancel"
                  bgColor="#D9D9D9"
                  bheight="50px"
                  bwidth="100px"
                  fontSize="16px"
                  borderRadius="6px"
                  onClick={(e) => {
                    onCancel(e);
                  }}
                />

                <Button
                  message="Reject and send email"
                  bgColor="#FF2E2E"
                  bheight="50px"
                  bwidth="200px"
                  fontSize="16px"
                  borderRadius="6px"
                  onClick={(e) => {
                    setShowIcon(true);
                    setTimeout(() => {
                      setShowIcon(false);
                      onConfirm(e);
                    }, 2000);
                  }}
                  disabled={!rejectReason}
                  disabledBgColor="#696969"
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PopupConfirmComponent;
