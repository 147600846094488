export enum TripGenPhase {
    FIRST = "FIRST",
    // first time landing (did not search) --> no extra button for search bar,
    // display only "search Hotel in different cities or dates" button

    FLIGHT_PHASE = "FLIGHT_PHASE",
    // did search for flight(or both flight and hotel) --> have arrow up-down button and can add more flight or hotel button
    // show flight search results + display search detail component

    HOTEL_PHASE = "HOTEL_PHASE",
    // may edit flight and wait to search for hotel --> display search detail component and hotel search results

    SUMMARY_PHASE = "SUMMARY_PHASE",
    // summary displayed and can confirm to create trip --> display summary component and "create trip" button

    INFO_PHASE = "INFO_PHASE",

    PAYMENT_PHASE = "PAYMENT_PHASE",

    CONFIRM_PHASE = "CONFIRM_PHASE"
}