import "./PassengerInfo.css";
import { FC } from "react";
import { Passenger } from "../../types/flight";
import { IoTrashBinSharp } from "react-icons/io5";
import { DatePicker, Form, Input, Select } from "antd";
import { Option } from "antd/es/mentions";
import Validator from "validator";
import { COUNTRIES } from "../../utils/countries";

interface PassengerInfoProp {
  passenger: Passenger;
  id: number;
  removePassenger: (target: number) => void;
  updatePassenger: (target: number, passengerKey: keyof Passenger, passengerValue: any) => void;
}

const PassengerInfo: FC<PassengerInfoProp> = ({ passenger, id, removePassenger, updatePassenger }) => {
  const filterCountryOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="passenger-info-container">
      <div className="passenger-info-header">
        <div className="passenger-info-header-left">
          <h4 style={{ margin: "0px" }}>Passenger {id + 1}</h4>
          <select className="passenger-info-traveller-type" disabled={id == 0}>
            <option value="Adult">Adult</option>
            <option value="Children">Children</option>
          </select>
        </div>
        <button
          className={`passenger-info-remove-passenger-btn ${id == 0 ? "disables-btn" : ""}`}
          onClick={() => removePassenger(id)}
          disabled={id == 0}
        >
          Remove
          <IoTrashBinSharp />
        </button>
      </div>
      <div className="passenger-info-data">
        <Form.Item
          label="Firstname"
          name={`passenger-${id}-firstname`}
          rules={[{ required: true, message: "Please input firstname" }]}
          initialValue={passenger.firstName}
        >
          <Input
            className="passenger-info-data-item"
            onChange={(e) => updatePassenger(id, "firstName", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Surname"
          name={`passenger-${id}-surname`}
          rules={[{ required: true, message: "Please input surname" }]}
          initialValue={passenger.lastName}
        >
          <Input
            className="passenger-info-data-item"
            onChange={(e) => updatePassenger(id, "lastName", e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Middlename" name={`passenger-${id}-middlename`} initialValue={passenger.middleName}>
          <Input
            className="passenger-info-data-item"
            onChange={(e) => updatePassenger(id, "middleName", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Gender"
          name={`passenger-${id}-gender`}
          rules={[{ required: true, message: "Please select gender" }]}
          initialValue={passenger.gender || null}
        >
          <Select
            placeholder="select your gender"
            style={{ height: "30px" }}
            onChange={(e) => updatePassenger(id, "gender", e)}
          >
            <Option value="male">Male</Option>
            <Option value="female">Female</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="BirthDate"
          name={`passenger-${id}-birthdate`}
          rules={[{ required: true, message: "Please select birth date" }]}
          initialValue={passenger.birthDate}
        >
          <DatePicker className="passenger-info-data-item" onChange={(e) => updatePassenger(id, "birthDate", e)} />
        </Form.Item>
        <Form.Item
          label="Nationality"
          name={`passenger-${id}-nationality`}
          rules={[{ required: true, message: "Please input nationality" }]}
          initialValue={passenger.nationality}
        >
          <Select
            showSearch
            placeholder="Select nationality"
            filterOption={filterCountryOption}
            options={COUNTRIES.map((country) => {
              return { label: country.label, value: country.label };
            })}
            onChange={(e) => updatePassenger(id, "nationality", e)}
          ></Select>
        </Form.Item>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Form.Item
            label="Passport Country"
            name={`passenger-${id}-passport-country`}
            rules={[{ required: true, message: "Please input passport country" }]}
            initialValue={passenger.passportCountry}
          >
            <Select
              showSearch
              placeholder="Select country"
              filterOption={filterCountryOption}
              options={COUNTRIES.map((country) => {
                return { label: country.label, value: country.value };
              })}
              onChange={(e) => updatePassenger(id, "passportCountry", e)}
            ></Select>
          </Form.Item>
          <Form.Item
            label="Passport Number"
            name={`passenger-${id}-passport-number`}
            rules={[
              { required: true, message: "Please input passport number" },
              () => ({
                validator(_, value) {
                  if (Validator.isPassportNumber(value, passenger.passportCountry)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The input is invalid passport number!"));
                },
              }),
            ]}
            initialValue={passenger.passportID}
          >
            <Input
              className="passenger-info-data-item"
              onChange={(e) => updatePassenger(id, "passportID", e.target.value)}
            />
          </Form.Item>
        </div>
        <Form.Item
          label="ExpiryDate"
          name={`passenger-${id}-expiry-date`}
          rules={[{ required: true, message: "Please select expiry date" }]}
          initialValue={passenger.expiryDate}
        >
          <DatePicker className="passenger-info-data-item" onChange={(e) => updatePassenger(id, "expiryDate", e)} />
        </Form.Item>
      </div>
    </div>
  );
};

export default PassengerInfo;
