import "./PaymentForm.css";
import { BsCreditCardFill, BsCheckCircleFill } from "react-icons/bs";
import { FaWallet } from "react-icons/fa";
import { Col, DatePicker, Form, Input, Row } from "antd";
import { Dayjs } from "dayjs";
import Validator from "validator";
import MasterCard from "../../assets/creditcard/mastercard.png";
import Visa from "../../assets/creditcard/visa.png";
import { RequestableWallet } from "../../types/enum/wallet";
import WalletOption from "../Wallet/WalletOption";
export interface CardInformation {
  cardNumber: string;
  cardHolderName: string;
  expiryDate: Dayjs;
  securityCode: string;
  vendorCode: string;
}

export interface PaymentFormProps {
  cardInfo: CardInformation;
  availableWallet: RequestableWallet[];
  PaymentMethod: string;
  updatePaymentMethod: (paymentMethod: string) => void;
  updateCardInfoHandler: (cardInfoKey: keyof CardInformation, cardInfoValue: any) => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  cardInfo,
  updateCardInfoHandler,
  availableWallet,
  PaymentMethod,
  updatePaymentMethod,
}) => {
  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cardNumber = e.target.value;
    if (/^4/.test(cardNumber)) {
      updateCardInfoHandler("vendorCode", "VI");
    } else if (/^5/.test(cardNumber)) {
      updateCardInfoHandler("vendorCode", "CA");
    }
    updateCardInfoHandler("cardNumber", cardNumber);
  };

  return (
    <div>
      <div className="AvailablePaymentMethod">Available Payment Method :</div>
      <div className="payment-card" onClick={() => updatePaymentMethod("credit")}>
        <div className="payment-header">
          {PaymentMethod == "credit" ? <BsCheckCircleFill size={20} color="rgba(0, 173, 181, 1)" /> : null}
          <BsCreditCardFill size={20} />
          <div className="CreditOrDebitCard">Credit or Debit Card</div> <img src={MasterCard} height={30} />
          <img src={Visa} height={20} />
        </div>
        <Col xs={24} md={12} className="payment-form-info">
          <Form.Item
            validateFirst
            name="card number"
            rules={[
              {
                required: PaymentMethod == "credit",
                message: "card number is required",
              },
              () => ({
                validator(_, value) {
                  if (PaymentMethod != "credit") {
                    return Promise.resolve();
                  }
                  if (!Validator.isCreditCard(value) && PaymentMethod == "credit") {
                    return Promise.reject(new Error("The input is invalid card number!"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            initialValue={cardInfo.cardNumber}
          >
            <Input disabled={PaymentMethod != "credit"} placeholder="card number" onChange={handleCardNumberChange} />
          </Form.Item>
          <Form.Item
            name="card holder name"
            rules={[
              {
                required: PaymentMethod == "credit",
                message: "card holder name is required",
              },
            ]}
            initialValue={cardInfo.cardHolderName}
          >
            <Input
              disabled={PaymentMethod != "credit"}
              placeholder="card holder name"
              onChange={(e) => updateCardInfoHandler("cardHolderName", e.target.value)}
            />
          </Form.Item>

          <Row gutter={20}>
            <Col xs={12}>
              <Form.Item
                name="expiry date"
                rules={[
                  {
                    required: PaymentMethod == "credit",
                    message: "expiry date is required",
                  },
                ]}
                initialValue={cardInfo.expiryDate}
              >
                <DatePicker
                  disabled={PaymentMethod != "credit"}
                  format="YYYY/MM"
                  picker="month"
                  onChange={(date) => {
                    const formattedDate = date?.format("YYYY-MM").toString();
                    updateCardInfoHandler("expiryDate", formattedDate);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="security code"
                rules={[
                  {
                    required: PaymentMethod == "credit",
                    message: "security code is required",
                  },
                  {
                    pattern: /^\d{3}$/,
                    message: "The input is invalid security",
                  },
                ]}
                initialValue={cardInfo.securityCode}
              >
                <Input
                  disabled={PaymentMethod != "credit"}
                  placeholder="security code"
                  onChange={(e) => updateCardInfoHandler("securityCode", e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </div>

      <div className="avialable-wallet">
        {availableWallet.length > 0 && (
          <div>
            {availableWallet.map((wallet) => (
              <WalletOption
                key={wallet.id}
                name={wallet.name}
                credit={wallet.credit_limit}
                currency={wallet.currency}
                onSelect={() => {
                  updatePaymentMethod(wallet.id);
                }}
                isSelected={wallet.id === PaymentMethod}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default PaymentForm;
