import dayjs, { Dayjs } from "dayjs";
import "./TripgenHotelSearch.css";
import { useEffect, useState } from "react";
import LocationInput from "../../LocationInput/LocationInput";
import MyDatePicker from "../../DatePickerComponent/MyDatePicker";
import RoomAndGuest from "../../RoomAndGuest/RoomAndGuest";
import { SearchHotelRequest } from "../../../types/hotel";
import { RiDeleteBinLine } from "react-icons/ri";
import airportOptions from "../../../utils/airportOptions";
import { useTripgenContext } from "../../../context/TripgenContext";
import { TripGenPhase } from "../../../types/enum/tripgenPhase";

interface TripgenHotelSearchProp {
  hotelSearchRequest: SearchHotelRequest;
  id_idx: number;
}

const airportMap = Object.fromEntries(airportOptions);
const getAirportName = (code: string) => {
  for (const [name, airportCode] of airportOptions) {
    if (airportCode === code) {
      return name;
    }
  }
};

const TripgenHotelSearch: React.FC<TripgenHotelSearchProp> = ({ hotelSearchRequest, id_idx }) => {
  const { removeHotelSearchRequest, updateHotelSearchRequest, tripgenPhase, selectedBookingHotel, totalHotelStep } =
    useTripgenContext();

  const [checkInDate, setCheckInDate] = useState<Dayjs | null>(dayjs(hotelSearchRequest.checkInDate || null));
  const [formattedCheckIn, setformattedCheckIn] = useState<string>(hotelSearchRequest.checkInDate);
  const [checkOutDate, setCheckOutDate] = useState<Dayjs | null>(dayjs(hotelSearchRequest.checkOutDate || null));
  const [formattedcheckOutDate, setFormattedcheckOutDate] = useState<string>(hotelSearchRequest.checkOutDate);
  const [hotelSearchLocation, setHotelSearchLocation] = useState<string>(
    getAirportName(hotelSearchRequest.cityCode) || ""
  );
  const [hotelSearchLocationCode, setHotelSearchLocationCode] = useState<string>(hotelSearchRequest.cityCode);
  const [adultNumberHotel, setAdultNumberHotel] = useState<number>(hotelSearchRequest.adult);
  const [roomAmount, setRoomAmount] = useState<number>(hotelSearchRequest.roomQuantity);

  const handleCheckInDate = (date: Dayjs | null) => {
    setCheckInDate(date);
    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");
      setformattedCheckIn(formattedDate);
    } else {
      setformattedCheckIn("");
    }
  };

  const handleCheckOutDate = (date: Dayjs | null) => {
    setCheckOutDate(date);
    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");
      setFormattedcheckOutDate(formattedDate);
    } else {
      setFormattedcheckOutDate("");
    }
  };

  const handleHotelLocationChange = (value: string) => {
    const code = value.match(/\(([^)]+)\)$/)?.[1] || "";
    setHotelSearchLocation(value);
    setHotelSearchLocationCode(code);
  };

  const updateHotelInfo = () => {
    const hotel: SearchHotelRequest = {
      cityCode: hotelSearchLocationCode,
      adult: adultNumberHotel,
      checkInDate: formattedCheckIn,
      checkOutDate: formattedcheckOutDate,
      roomQuantity: roomAmount,
    };
    updateHotelSearchRequest(id_idx, hotel);
  };

  const isThisFormDisabled = () => {
    if (
      tripgenPhase !== TripGenPhase.FIRST &&
      ((tripgenPhase !== TripGenPhase.HOTEL_PHASE && selectedBookingHotel.length - 1 >= id_idx) ||
        (tripgenPhase === TripGenPhase.HOTEL_PHASE && selectedBookingHotel.length - 1 >= id_idx))
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    updateHotelInfo();
  }, [formattedCheckIn, formattedcheckOutDate, hotelSearchLocationCode, adultNumberHotel, roomAmount]);
  return (
    <>
      <div className="TripgenHotelSearch-differentHotelContainer">
        <div className="TripgenHotelSearch-differentHotelChecked">
          <div className="TripgenHotelSearch-fromComponentHotel">
            Staying at
            <LocationInput
              placeholder="Input airport code or city name"
              originLocation={hotelSearchLocation}
              onInputChange={handleHotelLocationChange}
              disabled={isThisFormDisabled()}
            />
          </div>
          <div className="TripgenHotelSearch-departComponent">
            Check in
            <MyDatePicker selectedDate={checkInDate} onDateChange={handleCheckInDate} disable={isThisFormDisabled()} />
          </div>

          <div className="TripgenHotelSearch-returnComponent">
            Check out
            <MyDatePicker
              selectedDate={checkOutDate}
              onDateChange={handleCheckOutDate}
              disable={isThisFormDisabled()}
            ></MyDatePicker>
          </div>

          <div className="TripgenHotelSearch-passengersComponent">
            Room and Guests
            <RoomAndGuest
              adultNumber={adultNumberHotel}
              setAdultNumber={setAdultNumberHotel}
              roomAmount={roomAmount}
              setRoomAmount={setRoomAmount}
              disabled={isThisFormDisabled()}
            />
          </div>

          <div>
            {id_idx !== 0 &&
              selectedBookingHotel.length - 1 !== id_idx &&
              (tripgenPhase === TripGenPhase.FLIGHT_PHASE || tripgenPhase === TripGenPhase.HOTEL_PHASE) && (
                <div onClick={() => removeHotelSearchRequest(id_idx)} className="TripgenFlightSearch-removeButton">
                  <RiDeleteBinLine style={{ width: "40px", height: "40px", color: "#ffffff" }} />
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TripgenHotelSearch;
