import React, { useState } from "react";
import "./HotelSearchBar.css";
import MyDatePicker from "../DatePickerComponent/MyDatePicker";
import Button from "../Buttons/Buttons";
import { Dayjs } from "dayjs";
import LocationInput from "../LocationInput/LocationInput";
import RoomAndGuest from "../RoomAndGuest/RoomAndGuest";
import { SearchHotelResponse } from "../../types/hotel";
import { apiClient } from "../../utils/client";
import ModalLoading from "../ModalLoading/ModalLoading";
import { message } from "antd";

// change later
interface HotelSearchBarProp {
  hotelResultHandler: (result: SearchHotelResponse) => void;
  loadingHandler: (loading: boolean) => void;
  setHotelFilterHandler: (result: any) => void;
}

const HotelSearchBar: React.FC<HotelSearchBarProp> = ({
  hotelResultHandler,
  loadingHandler,
  setHotelFilterHandler,
}) => {
  const [checkInDate, setCheckInDate] = useState<Dayjs | null>(null);
  const [formattedCheckIn, setformattedCheckIn] = useState<string>("");
  const [checkOutDate, setCheckOutDate] = useState<Dayjs | null>(null);
  const [formattedcheckOutDate, setFormattedcheckOutDate] =
    useState<string>("");
  const [locationInput, setLocationInput] = useState<string>("");
  const [locationInputCode, setLocationInputCode] = useState<string>("");
  const [adultNumber, setAdultNumber] = useState<number>(1);
  const [childernNumber, setChildernNumber] = useState<number>(0);
  const [roomAmount, setRoomAmount] = useState<number>(1);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleCheckInDate = (date: Dayjs | null) => {
    setCheckInDate(date);
    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");
      setformattedCheckIn(formattedDate);
    } else {
      setformattedCheckIn("");
    }
  };

  const handleCheckOutDate = (date: Dayjs | null) => {
    setCheckOutDate(date);
    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");
      setFormattedcheckOutDate(formattedDate);
    } else {
      setFormattedcheckOutDate("");
    }
  };

  const handleLocationChange = (value: string) => {
    const code = value.match(/\(([^)]+)\)$/)?.[1] || "";
    setLocationInput(value);
    setLocationInputCode(code);
  };

  //connect backend
  const handleSearchClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    loadingHandler(true);
    openModal();

    try {
      const response = await apiClient.searchHotels({
        cityCode: locationInputCode,
        adult: adultNumber + childernNumber,
        checkInDate: formattedCheckIn,
        checkOutDate: formattedcheckOutDate,
        roomQuantity: roomAmount,
      });

      const hotelResult = await response.data;
      hotelResultHandler(hotelResult);
      setHotelFilterHandler(hotelResult.data);
      closeModal();
    } catch (error) {
      // Display a notification to the user indicating the request failure
      message.error("Fail to find hotels. Please try again.");

      // Optionally, you can log the error for debugging purposes
      console.error("Error fetching hotel data:", error);
      closeModal();
    } finally {
      closeModal();
    }
  };

  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <div className="hotoel-search-bar-container">
      <ModalLoading isOpen={isOpenModal}></ModalLoading>
      <div className="searchBox">
        <div className="fromComponent-hotel">
          Location
          <LocationInput
            placeholder="Input your location"
            originLocation={locationInput}
            onInputChange={handleLocationChange}
          />
        </div>

        <div className="departComponent">
          Check in
          <MyDatePicker
            selectedDate={checkInDate}
            onDateChange={handleCheckInDate}
          />
        </div>

        <div className="returnComponent">
          Check out
          <MyDatePicker
            selectedDate={checkOutDate}
            onDateChange={handleCheckOutDate}
          ></MyDatePicker>
        </div>

        <div className="passengersComponent">
          Room and Guests
          <RoomAndGuest
            adultNumber={adultNumber}
            setAdultNumber={setAdultNumber}
            roomAmount={roomAmount}
            setRoomAmount={setRoomAmount}
          />
        </div>

        <div className="searchComponent">
          <Button
            message={"Search"}
            bwidth="149px"
            bheight="50px"
            borderRadius="20px"
            fontSize="20px"
            onClick={handleSearchClick}
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default HotelSearchBar;
